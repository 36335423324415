import styled from 'styled-components'
import React, { useState } from 'react'
import { InfoTooltip } from './tooltips/InfoTooltip'

export enum IconType {
  VECTOR,
  FLAT
}

export interface CustomIconProps {
  id?: string
  iconType?: IconType
  path: string
  alt?: string
  styles?: string
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onHover?: (hover: boolean) => void
  tooltipText?: string
  tooltipStyles?: string
}

export const CustomIcon = ({
  id,
  iconType = IconType.VECTOR,
  path,
  alt,
  styles,
  onClick,
  onHover = () => void 0,
  tooltipText,
  tooltipStyles
}: CustomIconProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const Icon = () =>
    iconType === IconType.VECTOR ? (
      <VectorIcon id={id} className={`${styles ?? 'h-6 w-6 bg-gray-200'}`} path={path} title={alt} />
    ) : (
      <FlatIcon id={id} className={`${styles ?? 'h-6 w-6'}`} path={path} title={alt} />
    )

  return (
    <div
      className={`w-max inline relative group ${onClick && 'cursor-pointer'}`}
      onClick={onClick}
      onMouseMove={() => {
        onHover(true)
        tooltipText && setShowTooltip(true)
      }}
      onMouseLeave={() => {
        onHover(false)
        tooltipText && setShowTooltip(false)
      }}
    >
      <Icon />
      {tooltipText && showTooltip && (
        <InfoTooltip styles={`mt-2 min-w-fit ${tooltipStyles}`} mousePosition={true} sticky={true}>
          {tooltipText}
        </InfoTooltip>
      )}
    </div>
  )
}

interface IconProps {
  path?: string
}

const VectorIcon = styled.div<IconProps>`
  -webkit-mask: url(${({ path }) => path}) no-repeat center;
  mask: url(${({ path }) => path}) no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  pointer-events: none;
`

const FlatIcon = styled.div<IconProps>`
  background: url(${({ path }) => path}) no-repeat center;
  background-size: contain;
`
