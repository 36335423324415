import React, { useContext, useEffect, useState } from 'react'
import {
  getSustainabilityConsumptionTabData,
  getSustainabilityVolumeTabData,
  SustainabilityConsumptionTabColumn,
  SustainabilityConsumptionTabData,
  SustainabilityVolumeTabColumn,
  SustainabilityVolumeTabData,
  UsageTypeCategory
} from '../../api/sustainability'
import { DataBlock } from '../shared/containers/DataBlock'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import clouds from '../../assets/svg/flat-colored/clouds.svg'
import footprint from '../../assets/svg/flat-colored/footprint.svg'
import earth from '../../assets/svg/flat-colored/earth.svg'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import { DataBlocksWrapper } from '../shared/layout/DataBlocksWrapper'
import { TabContent, TabOptionsWrapper } from '../shared/tabs/TabSharedComponents'
import { Tab } from '../shared/tabs/Tab'
import { Heading, Heading3 } from '../shared/TextComponents'
import { Vendor } from '../../utils/vendors'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { TimeframeOption } from '../../utils/classes'
import { TabsContainer } from '../shared/containers/DataContainer'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { ClockTimestamp } from '../shared/ClockTimestamp'
import { formatDate, getMaxDate } from '../../utils/formats'

export enum SustainabilityTab {
  CONSUMPTION = 'consumption',
  VOLUMES = 'volumes'
}

enum InsightsGroup {
  PROJECT = 'project',
  SERVICE = 'service',
  REGION = 'region'
}

interface SustainabilityTabsProps {
  selectedTimeframe: TimeframeOption
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedRegions: string[]
}

export const SustainabilityTabs = ({
  selectedTimeframe,
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedRegions
}: SustainabilityTabsProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [consumptionLoading, setConsumptionLoading] = useState(false)
  const [volumesLoading, setVolumesLoading] = useState(false)
  const [consumptionData, setConsumptionData] = useState<SustainabilityConsumptionTabData | null>(null)
  const [volumeData, setVolumeData] = useState<SustainabilityVolumeTabData | null>(null)
  const [selectedTab, setSelectedTab] = useState(SustainabilityTab.CONSUMPTION)

  useEffect(() => {
    setConsumptionLoading(true)
    setVolumesLoading(true)
    const cancelToken = createCancelToken()
    getSustainabilityConsumptionTabData(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedRegions,
      selectedTimeframe,
      cancelToken.token
    )
      .then(setConsumptionData)
      .catch(handleError)
      .finally(() => setConsumptionLoading(false))
    getSustainabilityVolumeTabData(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedRegions,
      selectedTimeframe,
      cancelToken.token
    )
      .then(setVolumeData)
      .catch(handleError)
      .finally(() => {
        setVolumesLoading(false)
        setConsumptionLoading(false)
      })

    return () => {
      setConsumptionLoading(false)
      setVolumesLoading(false)
    }
  }, [
    createCancelToken,
    handleError,
    selectedProjects,
    selectedRegions,
    selectedResourceGroups,
    selectedServices,
    selectedVendors,
    selectedTimeframe
  ])

  const latestIntegrations = (integrationStates.aws?.map(i => i.usage.lastIntegrationAt).filter(i => i) ?? [])
    .concat(integrationStates.azure?.map(i => i.usage.lastIntegrationAt).filter(i => i) ?? [])
    .concat(
      integrationStates.gcp?.flatMap(i => i.billingAccounts.map(b => b.usage.lastIntegrationAt)?.filter(i => i)) ?? []
    )

  return (
    <TabsContainer>
      <TabOptionsWrapper>
        <Tab
          selectedTab={selectedTab}
          tabValue={SustainabilityTab.CONSUMPTION}
          handleSelection={() => setSelectedTab(SustainabilityTab.CONSUMPTION)}
        />
        <Tab
          selectedTab={selectedTab}
          tabValue={SustainabilityTab.VOLUMES}
          handleSelection={() => setSelectedTab(SustainabilityTab.VOLUMES)}
        />
      </TabOptionsWrapper>
      <TabContent>
        <ClockTimestamp dateString={formatDate(getMaxDate(latestIntegrations))} />
        <div className={'pt-5 pb-8'}>
          <Heading className={'mb-2'}>
            {selectedTimeframe === TimeframeOption.LAST_MONTH
              ? t('sustainability.heading.lastMonths')
              : t('sustainability.heading.fromYear', {
                  year: new Date().getFullYear() - (selectedTimeframe === TimeframeOption.LAST_YEAR ? 1 : 0)
                })}
          </Heading>
          <DataBlocksWrapper>
            {selectedTab === SustainabilityTab.CONSUMPTION ? (
              <>
                <ConsumptionDataColumn
                  id={InsightsGroup.PROJECT}
                  columnData={consumptionData?.projects}
                  loading={consumptionLoading}
                />
                <ConsumptionDataColumn
                  id={InsightsGroup.SERVICE}
                  columnData={consumptionData?.services}
                  loading={consumptionLoading}
                />
                <ConsumptionDataColumn
                  id={InsightsGroup.REGION}
                  columnData={consumptionData?.regions}
                  loading={consumptionLoading}
                />
              </>
            ) : (
              <>
                <VolumesDataColumn
                  id={InsightsGroup.PROJECT}
                  loading={volumesLoading}
                  columnData={volumeData?.projects}
                />
                <VolumesDataColumn
                  id={InsightsGroup.SERVICE}
                  loading={volumesLoading}
                  columnData={volumeData?.services}
                />
                <VolumesDataColumn
                  id={InsightsGroup.REGION}
                  loading={volumesLoading}
                  columnData={volumeData?.regions}
                />
              </>
            )}
          </DataBlocksWrapper>
        </div>
      </TabContent>
    </TabsContainer>
  )
}

interface ConsumptionColumnProps {
  id: InsightsGroup
  columnData: SustainabilityConsumptionTabColumn | undefined
  loading: boolean
}

const ConsumptionDataColumn = ({ id, columnData, loading }: ConsumptionColumnProps) => {
  const { t } = useTranslation()
  const emissionData = columnData?.emissions.map(entry => ({
    ...entry,
    unit: t('common.units.kgCO2')
  }))
  const kwhData = columnData?.kwhSpends.map(entry => ({
    ...entry,
    unit: t('common.units.kWh')
  }))

  const icon =
    id === InsightsGroup.PROJECT
      ? { path: footprint, styles: 'w-8 h-8' }
      : id === InsightsGroup.SERVICE
        ? { path: clouds, styles: 'w-10 h-8' }
        : { path: earth, styles: 'w-8 h-8' }

  return (
    <div>
      <Heading3 className={'pb-4'}>{t(`sustainability.insights.${id}`)}</Heading3>
      <DataBlockColumn>
        <DataBlock
          id={`qa-emissions-by-${id}`}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={icon.path} styles={icon.styles} />}
          heading={t('sustainability.insights.consumption.cloudEmissions')}
          data={emissionData ?? []}
          loading={loading}
        />
        <DataBlock
          id={`qa-electricity-by-${id}`}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={icon.path} styles={icon.styles} />}
          heading={t(`sustainability.insights.consumption.kwhSpend`)}
          data={kwhData ?? []}
          loading={loading}
        />
      </DataBlockColumn>
    </div>
  )
}

interface VolumeColumnProps {
  id: InsightsGroup
  columnData: SustainabilityVolumeTabColumn | undefined
  loading: boolean
}

const VolumesDataColumn = ({ id, loading, columnData }: VolumeColumnProps) => {
  const { t } = useTranslation()

  const dataBlocksContent = [
    {
      heading: t('sustainability.insights.volumes.compute'),
      category: UsageTypeCategory.COMPUTE,
      rows: columnData?.compute ?? []
    },
    {
      heading: t('sustainability.insights.volumes.storage'),
      category: UsageTypeCategory.STORAGE,
      rows: columnData?.storage ?? []
    },
    {
      heading: t('sustainability.insights.volumes.networking'),
      category: UsageTypeCategory.NETWORKING,
      rows: columnData?.networking ?? []
    }
  ]
  const icon =
    id === InsightsGroup.PROJECT
      ? { path: footprint, styles: 'w-8 h-8' }
      : id === InsightsGroup.SERVICE
        ? { path: clouds, styles: 'w-10 h-8' }
        : { path: earth, styles: 'w-8 h-8' }

  return (
    <div>
      <Heading3 className={'pb-4'}>{t(`sustainability.insights.${id}`)}</Heading3>
      <DataBlockColumn>
        {dataBlocksContent.map(dataBlock => (
          <DataBlock
            key={dataBlock.heading}
            id={`qa-${dataBlock.category}-by-${id}`}
            headerIcon={<CustomIcon iconType={IconType.FLAT} path={icon.path} styles={icon.styles} />}
            heading={dataBlock.heading}
            data={dataBlock.rows}
            loading={loading}
          />
        ))}
      </DataBlockColumn>
    </div>
  )
}

const DataBlockColumn = styled.div`
  ${tw`flex flex-col gap-8`}
`
