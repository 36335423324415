import React from 'react'
import { useTranslation } from 'react-i18next'
import { FreshTicket, TicketPriority, TicketsByStatus, TicketStatus } from '../../api/tickets'
import { InfoTooltip } from '../shared/tooltips/InfoTooltip'
import { useMousePosition } from '../../hooks/useMousePosition'
import { DataContainer } from '../shared/containers/DataContainer'
import { GradientText, GrayText, Heading2, WhiteText } from '../shared/TextComponents'
import { CountUp } from '../shared/CountUp'
import { calculatePercent, formatMonthLong, getLastMonth } from '../../utils/formats'
import { ticketStatusColor } from './TicketsStatusContainer'
import { CustomIcon } from '../shared/CustomIcon'
import checkIcon from '../../assets/svg/symbols/check-heavy.svg'
import arrowIcon from '../../assets/svg/directional/arrow-heavy.svg'
import { BarChart, BarChartTheme } from '../shared/charts/BarChart'
import { ChangeIndicator } from '../shared/indicators/ChangeIndicator'
import styled from 'styled-components'
import tw from 'twin.macro'

interface TicketsHeaderProps {
  tickets: TicketsByStatus[]
}

export const TicketsHeader = ({ tickets }: TicketsHeaderProps) => {
  const openTickets = tickets.find(byStatus => byStatus.status === TicketStatus.OPEN)?.tickets || []
  const pendingTickets = tickets.find(byStatus => byStatus.status === TicketStatus.PENDING)?.tickets || []
  const resolvedTickets = tickets.find(byStatus => byStatus.status === TicketStatus.RESOLVED)?.tickets || []
  const closedTickets = tickets.find(byStatus => byStatus.status === TicketStatus.CLOSED)?.tickets || []

  return (
    <div className={'grid lg:grid-cols-2 gap-5'}>
      <TicketsSummary
        openTickets={openTickets}
        pendingTickets={pendingTickets}
        resolvedTickets={resolvedTickets}
        closedTickets={closedTickets}
      />
      <TicketsStats
        openTickets={openTickets}
        pendingTickets={pendingTickets}
        resolvedTickets={resolvedTickets}
        closedTickets={closedTickets}
      />
    </div>
  )
}

interface TicketSummaryProps {
  openTickets: FreshTicket[]
  pendingTickets: FreshTicket[]
  resolvedTickets: FreshTicket[]
  closedTickets: FreshTicket[]
}

const TicketsSummary = ({ openTickets, pendingTickets, resolvedTickets, closedTickets }: TicketSummaryProps) => {
  const mousePos = useMousePosition()
  const { t } = useTranslation()

  return (
    <DataContainer looseSpacing={true}>
      <ContentWrapper>
        <div className={'flex gap-2 items-center'}>
          <Heading2 className={'max-w-max'}>{t('tickets.header.summary')}</Heading2>
          <InfoTooltip useIcon={true} styles={'max-w-80'} x={mousePos.x} y={mousePos.y}>
            {t('tickets.header.info', { lastMonth: formatMonthLong(getLastMonth(), true) })}
          </InfoTooltip>
        </div>
        <FlexWrapper>
          <OpenAndPendingSummary openTickets={openTickets} pendingTickets={pendingTickets} />
          <ClosedAndResolvedSummary resolvedTickets={resolvedTickets} closedTickets={closedTickets} />
        </FlexWrapper>
      </ContentWrapper>
    </DataContainer>
  )
}

interface OpenAndPendingSummaryProps {
  openTickets: FreshTicket[]
  pendingTickets: FreshTicket[]
}

const OpenAndPendingSummary = ({ openTickets, pendingTickets }: OpenAndPendingSummaryProps) => {
  const { t } = useTranslation()
  const dueExpired = openTickets.concat(pendingTickets).filter(ticket => new Date(ticket.dueBy) < new Date())
  const dueIn7Days = getNext7DaysDue(openTickets.concat(pendingTickets))
  const importantTickets = dueExpired
    .concat(dueIn7Days)
    .filter(ticket => ticket.priority === TicketPriority.URGENT || ticket.priority === TicketPriority.HIGH)
  const importantPriorities = [
    {
      priority: TicketPriority.URGENT,
      label: TicketPriority.URGENT.toLowerCase(),
      count: importantTickets.filter(t => t.priority === TicketPriority.URGENT).length
    },
    {
      priority: TicketPriority.HIGH,
      label: TicketPriority.HIGH.toLowerCase(),
      count: importantTickets.filter(t => t.priority === TicketPriority.HIGH).length
    }
  ].filter(p => p.count > 0)

  return (
    <div className={'inner-col gap-4 sm:min-w-[260px]'}>
      <div className={'flex items-center justify-evenly gap-12'}>
        <TicketCounter id={'qa-OPEN-tickets'} count={openTickets.length} status={TicketStatus.OPEN} />
        <TicketCounter id={'qa-PENDING-tickets'} count={pendingTickets.length} status={TicketStatus.PENDING} />
      </div>
      <div className={'flex flex-col w-full max-w-max justify-center'}>
        <div className={'flex gap-2 items-center'}>
          <CountUp countTo={dueExpired.length} />
          <GrayText lowercase={true} className={'text-90'}>
            {t('tickets.header.dueExpired')}
          </GrayText>
        </div>
        <div className={'flex gap-2 items-center'}>
          <CountUp countTo={dueIn7Days.length} />
          <GrayText lowercase={true} className={'text-90'}>
            {t('tickets.header.dueInNext7Days')}
          </GrayText>
        </div>
        {importantPriorities.length > 0 && (
          <div className={'flex gap-2 items-center'}>
            <CustomIcon
              path={arrowIcon}
              styles={`w-3 h-3 rotate-90 bg-gradient-to-r ${ticketStatusColor[TicketStatus.OPEN].gradient}`}
            />
            {importantPriorities.map((p, index) => (
              <div key={p.priority} className={'flex gap-2 items-center'}>
                <CountUp countTo={p.count} />
                <GrayText lowercase className={'text-90'}>
                  {p.label}
                </GrayText>
                {index < importantPriorities.length - 1 && (
                  <GrayText lowercase className={'text-90'}>
                    /
                  </GrayText>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

interface ClosedAndResolvedSummaryProps {
  resolvedTickets: FreshTicket[]
  closedTickets: FreshTicket[]
}

const ClosedAndResolvedSummary = ({ resolvedTickets, closedTickets }: ClosedAndResolvedSummaryProps) => {
  const { t } = useTranslation()
  const unattendedTickets = closedTickets.filter(ticket => !ticket.ticketUpdatedAt)

  return (
    <div className={'inner-col gap-4 sm:min-w-[260px]'}>
      <div className={'flex items-center justify-evenly gap-12'}>
        <TicketCounter id={'qa-RESOLVED-tickets'} count={resolvedTickets.length} status={TicketStatus.RESOLVED} />
        <TicketCounter id={'qa-CLOSED-tickets'} count={closedTickets.length} status={TicketStatus.CLOSED} />
      </div>
      {unattendedTickets.length > 0 && (
        <div>
          <div className={'flex gap-2 items-center'}>
            <CountUp countTo={unattendedTickets.length} />
            <GrayText lowercase={true} className={'text-90'}>
              {t('tickets.header.unattended')}
            </GrayText>
          </div>
          <div className={'flex gap-2 items-center ml-1'}>
            <CustomIcon
              path={arrowIcon}
              styles={`w-3 h-3 rotate-90 bg-gradient-to-r ${ticketStatusColor[TicketStatus.CLOSED].gradient}`}
            />
            <CountUp countTo={unattendedTickets.filter(t => t.dueBy < new Date()).length} />
            <GrayText lowercase={true} className={'text-90'}>
              {t('tickets.header.dueExpired')}
            </GrayText>
          </div>
          <div className={'flex gap-2 items-center ml-1'}>
            <CustomIcon
              path={arrowIcon}
              styles={`w-3 h-3 rotate-90 bg-gradient-to-r ${ticketStatusColor[TicketStatus.CLOSED].gradient}`}
            />
            <CountUp countTo={getNext7DaysDue(unattendedTickets).length} />
            <GrayText lowercase={true} className={'text-90'}>
              {t('tickets.header.dueInNext7Days')}
            </GrayText>
          </div>
        </div>
      )}
    </div>
  )
}

const TicketsStats = ({ openTickets, pendingTickets, resolvedTickets, closedTickets }: TicketSummaryProps) => {
  const { t } = useTranslation()
  const createdCurrMonth = openTickets
    .concat(pendingTickets)
    .concat(resolvedTickets)
    .concat(closedTickets)
    .filter(ticket => new Date(ticket.ticketCreatedAt).getMonth() === new Date().getMonth()).length
  const createdAndCompletedCurrMonth = resolvedTickets
    .concat(closedTickets)
    .filter(
      ticket =>
        new Date(ticket.ticketCreatedAt).getMonth() === new Date().getMonth() &&
        ticket.ticketUpdatedAt &&
        new Date(ticket.ticketUpdatedAt).getMonth() === new Date().getMonth()
    ).length

  const completedCurrMonth = resolvedTickets
    .concat(closedTickets)
    .filter(
      ticket => ticket.ticketUpdatedAt && new Date(ticket.ticketUpdatedAt).getMonth() === new Date().getMonth()
    ).length

  const completedLastMonth = resolvedTickets
    .concat(closedTickets)
    .filter(
      ticket => ticket.ticketUpdatedAt && new Date(ticket.ticketUpdatedAt).getMonth() === getLastMonth().getMonth()
    ).length

  const changeFromLastMonth = calculatePercent(completedCurrMonth, completedLastMonth) - 100

  return (
    <DataContainer looseSpacing={true}>
      <ContentWrapper>
        <Heading2>{t('tickets.header.monthStats', { month: formatMonthLong(new Date()) })}</Heading2>
        <FlexWrapper>
          <div className={'inner-col text-center'}>
            <GradientText className={'leading-none font-black from-gray-50 to-gray-200 text-350 pb-2 lg:text-450'}>
              <CountUp countTo={completedCurrMonth} />
            </GradientText>
            <div className={'flex gap-2 justify-center items-center font-semibold tracking-wide pb-2'}>
              <GrayText lowercase={true}>{t('tickets.header.ticketsCompleted')}</GrayText>
              {completedCurrMonth > 0 && <CustomIcon path={checkIcon} styles={'w-4 h-4 bg-success-100'} />}
            </div>
            <div className={'flex gap-2 items-center justify-center w-full'}>
              <ChangeIndicator change={changeFromLastMonth} invertColors={true} />
              <GrayText lowercase={true}>
                {changeFromLastMonth > 0
                  ? t('tickets.header.moreThanLastMonth')
                  : t('tickets.header.lessThanLastMonth')}
              </GrayText>
            </div>
            <div className={'flex gap-2 items-center justify-between w-full'}>
              <GrayText>{t('tickets.header.monthScore', { month: formatMonthLong(getLastMonth()) })}</GrayText>
              <WhiteText className={'font-semibold'}>
                <CountUp countTo={completedLastMonth} /> {t('common.pcs')}
              </WhiteText>
            </div>
          </div>

          <div className={'flex items-center gap-5 px-2'}>
            <BarChart
              height={28}
              width={11}
              theme={BarChartTheme.progress}
              percent={calculatePercent(createdAndCompletedCurrMonth, createdCurrMonth)}
              showPercent={true}
              direction={'vertical'}
            />
            <div>
              <div className={'flex gap-1.5 items-center'}>
                <TicketCounter
                  id={'qa-created-and-completed-tickets-current-month'}
                  count={createdAndCompletedCurrMonth}
                />
                <GrayText lowercase={true}>{t('tickets.header.ticketsCompleted')}</GrayText>
                {createdAndCompletedCurrMonth > 0 && <CustomIcon path={checkIcon} styles={'w-3 h-3 bg-success-100'} />}
                <GrayText lowercase={true}>{t('tickets.header.outOf')}</GrayText>
              </div>

              <div className={'flex gap-1 items-center'}>
                <TicketCounter id={'qa-created-tickets-current-month'} count={createdCurrMonth} />
                <GrayText normalCase={true}>
                  {t('tickets.header.newTickets', { month: formatMonthLong(new Date()) })}
                </GrayText>
              </div>
            </div>
          </div>
        </FlexWrapper>
      </ContentWrapper>
    </DataContainer>
  )
}

const ContentWrapper = styled.div`
  ${tw`flex flex-col h-full w-full gap-6`}
`

const FlexWrapper = styled.div`
  ${tw`flex flex-wrap w-full h-full gap-8 justify-evenly`}
  .inner-col {
    ${tw`flex flex-col px-2`}
  }
`

interface TicketCounterProps {
  id: string
  count: number
  status?: TicketStatus
}

export const TicketCounter = ({ count, status, id }: TicketCounterProps) => {
  if (status)
    return (
      <div className={'flex flex-col items-center justify-center'}>
        <GradientText className={`font-black text-300 ${ticketStatusColor[status].gradient}`}>
          <CountUp id={id} countTo={count} />
        </GradientText>
        <GrayText className={'text-90'}>{status}</GrayText>
      </div>
    )

  return (
    <GradientText className={'font-black from-gray-50 to-gray-100 text-125'}>
      <CountUp id={id} countTo={count} />
    </GradientText>
  )
}

const getNext7DaysDue = (tickets: FreshTicket[]) =>
  tickets.filter(
    ticket => ticket.dueBy >= new Date() && ticket.dueBy <= new Date(new Date().setDate(new Date().getDate() + 7))
  )
