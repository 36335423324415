import React, { useEffect, useState } from 'react'
import { HomeNoDataSummaryContainer, HomeSummaryContainer, SummaryAmount, SummaryText } from './HomeSharedComponents'
import { Trans, useTranslation } from 'react-i18next'
import { getHomeOptimizationSummary } from '../../api/home'
import { useCancelToken } from '../../api/client'
import { CountUp } from '../shared/CountUp'
import { roundNumber } from '../../utils/formats'
import { useErrorHandling } from '../../hooks/handleError'
import { CurrencyEntry, getCurrency } from '../../utils/Currency'

export const OptimizationSummary = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState<boolean>(true)
  const [monthlySavings, setMonthlySavings] = useState<CurrencyEntry | null>(null)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getHomeOptimizationSummary(cancelToken.token)
      .then(setMonthlySavings)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      setMonthlySavings(null)
      cancelToken.cancel()
    }
  }, [handleError, createCancelToken])

  if (loading || monthlySavings === null)
    return <HomeNoDataSummaryContainer title={t('home.optimizationSummary.title')} loading={loading} />

  return (
    <HomeSummaryContainer title={t('home.optimizationSummary.title')} isClickable={true}>
      <SummaryAmount color={'gradient-green'} textSize={'larger'} fontWeight={'bold'}>
        <CountUp id={'qa-home-potential-savings'} countTo={roundNumber(monthlySavings.amount, 0)} />{' '}
        <span className={'text-200 md:text-250'}>{getCurrency(monthlySavings.currency).symbol}</span>
      </SummaryAmount>
      <SummaryText largerText={true}>
        <Trans>{t('home.optimizationSummary.description')}</Trans>
      </SummaryText>
    </HomeSummaryContainer>
  )
}
