import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import { TreeBranch } from '../../shared/TreeBranch'
import { ComplianceControlFindings } from './ComplianceControlFindings'
import {
  AwsComplianceFindingsByControl,
  AzureComplianceFindingsByControl,
  ComplianceStatus,
  GcpComplianceFindingsByControl
} from '../../../api/compliance/tabs'
import { ComplianceCountLabel } from './ComplianceVendorTab'
import { TextBadge } from '../../shared/indicators/TextBadge'

export interface ComplianceControlProps {
  id: string
  vendor: Vendor
  isOpen?: boolean
  findingsByControl: AwsComplianceFindingsByControl | AzureComplianceFindingsByControl | GcpComplianceFindingsByControl
}

export const ComplianceControl = ({ id, vendor, findingsByControl }: ComplianceControlProps) => {
  return (
    <TreeBranch
      id={id}
      headerData={
        <div className={`ml-2 flex flex-col justify-between gap-4 w-full xl:flex-row xl:items-center`}>
          <div>{findingsByControl.control}</div>
          <ControlSummaryData vendor={vendor} findingsByControl={findingsByControl} />
        </div>
      }
      contentData={<ComplianceControlFindings vendor={vendor} findingsByControl={findingsByControl} />}
    />
  )
}

interface ComplianceControlSummaryProps {
  vendor: Vendor
  findingsByControl: AwsComplianceFindingsByControl | AzureComplianceFindingsByControl | GcpComplianceFindingsByControl
}

const ControlSummaryData = ({ vendor, findingsByControl }: ComplianceControlSummaryProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {'severity' in findingsByControl && (
        <CheckDetail>
          <TextBadge value={findingsByControl.severity} />
        </CheckDetail>
      )}
      <ActiveFindingsContainer>
        <ComplianceCountLabel
          count={findingsByControl.failedCount}
          label={
            vendor === Vendor.AWS || vendor === Vendor.AZURE
              ? t('common.resource', { count: findingsByControl.failedCount })
              : t('compliance.tabs.gcp.finding', { count: findingsByControl.failedCount })
          }
        />
        <TextBadge value={ComplianceStatus.FAILED} />
      </ActiveFindingsContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`flex flex-row gap-8 xl:items-center`}
`

const CheckDetail = styled.div`
  ${tw`flex min-w-35`}
`

const ActiveFindingsContainer = styled.div`
  ${tw`flex flex-row justify-end gap-8 w-[160px]`}
`
