import { Auth, CognitoUser } from '@aws-amplify/auth'
import { CancelToken } from 'axios'
import { client, openClient } from './client'
import { getPersistedLoginInfo } from './localStorage'
import { Tenant } from './system-admin'

export const handleSignUp = async (newUser: SignUpInfo, cancelToken: CancelToken): Promise<string> => {
  return await openClient.post<string>('signup', newUser, { cancelToken }).then(resp => resp.data)
}

export const handleMarketplaceSignUp = async (
  tenantId: string,
  newUser: SignUpInfo,
  cancelToken: CancelToken
): Promise<string> => {
  return await openClient
    .post<string>(`aws-marketplace/signup/${tenantId}`, newUser, { cancelToken })
    .then(resp => resp.data)
}

export const configureCognito = (loginInfo: LoginInfo) => {
  Auth.configure({
    region: loginInfo.cognitoRegion,
    userPoolId: loginInfo.cognitoPoolId,
    userPoolWebClientId: loginInfo.cognitoClientId,
    oauth: {
      domain: `${loginInfo.cognitoPoolDomain}.auth.${loginInfo.cognitoRegion}.amazoncognito.com`,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_BASE_URL}/federated-auth`,
      redirectSignOut: `${process.env.REACT_APP_BASE_URL}/logout`,
      responseType: 'code'
    }
  })
}

getPersistedLoginInfo().cognitoPoolId && configureCognito(getPersistedLoginInfo())

export const refreshJwtToken = async (): Promise<string> => {
  try {
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  } catch (error) {
    throw new Error('Authentication failed. Please log in again.')
  }
}

export const logOut = (): Promise<any> => Auth.signOut()

export const login = async (email: string, password: string): Promise<CognitoUser | any> => Auth.signIn(email, password)

export const completeNewPassword = async (user: CognitoUser | null, newPassword: string): Promise<CognitoUser> =>
  Auth.completeNewPassword(user, newPassword)

export const forgotPassword = async (email: string): Promise<CognitoUser> => Auth.forgotPassword(email)

export const forgotPasswordSubmit = async (email: string, code: string, newPassword: string): Promise<string> =>
  Auth.forgotPasswordSubmit(email, code, newPassword)

export const getLoginInfo = async (email: string): Promise<LoginInfo> => {
  const resp = await openClient.get<LoginInfo>(`/login-info/${email}`)
  return resp.data
}

export const getAuthInfo = async (cancelToken: CancelToken): Promise<AuthInfo> => {
  const resp = await client.get<AuthInfo>('/auth', { cancelToken: cancelToken })
  return {
    ...resp.data,
    site247Integration: resp.data.site247Integration ? new Date(resp.data.site247Integration) : undefined,
    freshIntegration: resp.data.freshIntegration ? new Date(resp.data.freshIntegration) : undefined,
    lastLoginAt: resp.data.lastLoginAt ? new Date(resp.data.lastLoginAt) : null,
    isLoggedIn: true
  }
}

export const resendActivationCode = async (email: string): Promise<string> => {
  const resp = await openClient.post<string>(`account-recovery/activation-code/${email}`)
  return resp.data
}

export interface AuthInfo {
  id: string
  firstName: string
  lastName: string
  emailAddress: string
  tenant: Tenant
  roles: UserRole[]
  hasIntegrations: boolean
  isSsoEnabled: boolean
  site247Integration?: Date
  freshIntegration?: Date
  awsAccess: boolean
  gcpAccess: boolean
  azureAccess: boolean
  costsAccess: boolean
  optimizationAccess: boolean
  complianceAccess: boolean
  sustainabilityAccess: boolean
  infraAccess: boolean
  ticketAccess: boolean
  notificationsAccess: boolean
  lastLoginAt: Date | null
  isLoggedIn: boolean
}

export enum UserRole {
  MASTER_ADMIN = 'MASTER_ADMIN',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  ADMIN = 'ADMIN',
  AWS_ADMIN = 'AWS_ADMIN',
  AZURE_ADMIN = 'AZURE_ADMIN',
  GCP_ADMIN = 'GCP_ADMIN',
  AWS_USER = 'AWS_USER',
  AZURE_USER = 'AZURE_USER',
  GCP_USER = 'GCP_USER',
  COSTS_USER = 'COSTS_USER',
  OPTIMIZATION_USER = 'OPTIMIZATION_USER',
  COMPLIANCE_USER = 'COMPLIANCE_USER',
  SUSTAINABILITY_USER = 'SUSTAINABILITY_USER',
  INFRA_USER = 'INFRA_USER',
  TICKET_USER = 'TICKET_USER'
}

export interface LoginInfo {
  cognitoRegion?: string | null
  cognitoClientId?: string | null
  cognitoPoolId?: string | null
  cognitoPoolDomain?: string | null
  cognitoIdP?: string | null
  ssoEnabled: boolean
  emailAddress: string
}

export interface SignUpInfo {
  tenantName: string
  firstName: string
  lastName: string
  emailAddress: string
}
