import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { useEffect, useState } from 'react'
import {
  CategorySavings,
  getOptimizationSummaryByCategory,
  getOptimizationSummaryTotal,
  OptimizationCommitmentTerm,
  RecommendationsSavingsSummary,
  SavingsByCategory
} from '../../api/optimization/shared'
import { DataContainer } from '../shared/containers/DataContainer'
import { Loading } from '../shared/Loading'
import { CountUp } from '../shared/CountUp'
import { roundNumber } from '../../utils/formats'
import { CapitalizedText, GradientText, GrayText, Heading } from '../shared/TextComponents'
import { useTranslation } from 'react-i18next'
import { StackedBarChart } from '../shared/charts/StackedBarChart'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../utils/vendors'
import { ToggleGroup } from '../shared/buttons/ToggleGroup'
import { InfoTooltip } from '../shared/tooltips/InfoTooltip'
import { CurrencyId, getCurrency } from '../../utils/Currency'
import { ChangeIndicator } from '../shared/indicators/ChangeIndicator'

interface AnnualSavingsSummaryProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedRecommendations: string[]
  selectedCurrency: CurrencyId
}

export const OptimizationTotalSummary = ({
  selectedVendors,
  selectedProjects,
  selectedRecommendations,
  selectedCurrency
}: AnnualSavingsSummaryProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState(true)
  const [totalSavings, setTotalSavings] = useState<RecommendationsSavingsSummary | null>(null)
  const [savingsByCategory, setSavingsByCategory] = useState<SavingsByCategory | null>(null)
  const [selectedTerm, setSelectedTerm] = useState<OptimizationCommitmentTerm>(OptimizationCommitmentTerm.THREE_YEARS)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getOptimizationSummaryTotal(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        selectedTerm,
        cancelToken.token
      ).then(setTotalSavings),
      getOptimizationSummaryByCategory(
        selectedVendors,
        selectedProjects,
        selectedRecommendations,
        selectedCurrency,
        selectedTerm,
        cancelToken.token
      ).then(setSavingsByCategory)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      cancelToken.cancel()
    }
  }, [
    createCancelToken,
    handleError,
    selectedProjects,
    selectedRecommendations,
    selectedVendors,
    selectedCurrency,
    selectedTerm
  ])

  if (loading && !totalSavings && !savingsByCategory)
    return (
      <DataContainer looseSpacing={true}>
        <Loading paddingY={'4.563rem'} />
      </DataContainer>
    )

  return (
    <ContainersGrid>
      <DataContainer looseSpacing={true} flexCol={true}>
        <TotalSavings amounts={totalSavings} />
      </DataContainer>
      <DataContainer looseSpacing={true} flexCol={true}>
        <Categories
          selectedTerm={selectedTerm}
          setSelectedTerm={setSelectedTerm}
          savingsByCategory={savingsByCategory}
        />
      </DataContainer>
    </ContainersGrid>
  )
}

const ContainersGrid = styled.div`
  ${tw`grid gap-5 grid-cols-1 lg:grid-cols-3`}
  > div:nth-child(2) {
    ${tw`lg:col-span-2`}
  }
`

const SummaryWrapper = styled.div`
  ${tw`flex flex-col gap-8 w-full h-full`}
`

interface TotalSavingsProps {
  amounts: RecommendationsSavingsSummary | null
}

const TotalSavings = ({ amounts }: TotalSavingsProps) => {
  const { t } = useTranslation()

  if (amounts === null)
    return (
      <SummaryWrapper>
        <Heading>{t('optimization.summary.summaryHeading')}</Heading>
        <div className={'flex flex-col items-center'}>
          <CapitalizedText>{t('optimization.summary.annualSavings')}</CapitalizedText>
          <AnnualAmount>-</AnnualAmount>
          <MissingDataNotification justify={'center'} />
        </div>
      </SummaryWrapper>
    )

  const currencySymbol = getCurrency(amounts.currency).symbol

  return (
    <SummaryWrapper>
      <Heading>{t('optimization.summary.summaryHeading')}</Heading>
      <div className={'flex flex-col items-center gap-5'}>
        <div className={'flex flex-col gap-2 text-center'}>
          <CapitalizedText>{t('optimization.summary.annualSavings')}</CapitalizedText>
          <AnnualAmount>
            <CountUp id={'qa-optimization-summary-annual-savings'} countTo={roundNumber(amounts.annualSavings, 0)} />{' '}
            <span className={'text-250'}>{currencySymbol}</span>
          </AnnualAmount>
          <GrayText className={'text-90'} lowercase={true}>
            {t('optimization.summary.annualSavingsEnd')}
          </GrayText>
        </div>

        <div className={'flex flex-col gap-1 w-full text-center'}>
          <GrayText>
            {t('optimization.summary.monthlySavingsIntro')}{' '}
            <GradientText className={'text-112 font-bold from-gray-50 via-gray-100 to-gray-100'}>
              <CountUp
                id={'qa-optimization-summary-monthly-savings'}
                countTo={roundNumber(amounts.monthlySavings, 0)}
              />{' '}
              {currencySymbol}
            </GradientText>
          </GrayText>
          <GrayText className={'inline-flex gap-1 self-center text-90'}>
            <ChangeIndicator
              id={'qa-optimization-summary-monthly-saving-percent'}
              change={amounts.monthlySavingsPercent * -1}
              counter={true}
            />
            {t('optimization.summary.forecastPercentComparison', {
              month: new Date().toLocaleString('default', { month: 'long' })
            })}
          </GrayText>
        </div>
      </div>
    </SummaryWrapper>
  )
}

const AnnualAmount = styled(GradientText)`
  ${tw`leading-none font-black from-success-100 via-success-100 to-success-500 text-300 lg:text-350`}
`

interface CategoriesProps {
  savingsByCategory: SavingsByCategory | null
  selectedTerm: OptimizationCommitmentTerm
  setSelectedTerm: (term: OptimizationCommitmentTerm) => void
}

const Categories = ({ savingsByCategory, selectedTerm, setSelectedTerm }: CategoriesProps) => {
  const { t } = useTranslation()
  const displayOptions = [
    {
      clickHandler: () => setSelectedTerm(OptimizationCommitmentTerm.ONE_YEAR),
      checked: selectedTerm === OptimizationCommitmentTerm.ONE_YEAR,
      value: t('optimization.summary.oneYear')
    },
    {
      clickHandler: () => setSelectedTerm(OptimizationCommitmentTerm.THREE_YEARS),
      checked: selectedTerm === OptimizationCommitmentTerm.THREE_YEARS,
      value: t('optimization.summary.threeYears')
    }
  ]

  return (
    <SummaryWrapper>
      <div className={'flex justify-between items-center gap-5'}>
        <Heading>{t('optimization.summary.categoriesHeading')}</Heading>
        <div className={'flex w-full justify-end gap-2 items-center'}>
          <ToggleGroup maxWidth={'9rem'} buttons={displayOptions} />
          <InfoTooltip useIcon={true} mousePosition={true} styles={'mt-4 -ml-24'}>
            {t('optimization.summary.termButtonTooltip')}
          </InfoTooltip>
        </div>
      </div>
      <div className={'flex flex-col gap-8 h-full w-full'}>
        <div className={'flex justify-between flex-col lg:flex-row gap-6 xl:gap-10 2xl:gap-12 3xl:gap-24'}>
          <CategorySection
            title={t('optimization.common.commitments')}
            titleStyle={'text-primary-400'}
            savings={savingsByCategory?.commitments ?? null}
            tooltipText={t('optimization.summary.categories.commitmentsTooltip')}
          />
          <CategorySection
            title={t('optimization.common.rightsizing')}
            titleStyle={'text-primary-300'}
            savings={savingsByCategory?.rightsizing ?? null}
          />
          <CategorySection
            title={t('optimization.summary.categories.utilization')}
            titleStyle={'text-primary-100'}
            savings={savingsByCategory?.utilization ?? null}
          />
        </div>

        <StackedBarChart
          size={'lg'}
          data={[
            { value: savingsByCategory?.commitments?.annual ?? 0, color: 'bg-primary-400' },
            { value: savingsByCategory?.rightsizing?.annual ?? 0, color: 'bg-primary-300' },
            { value: savingsByCategory?.utilization?.annual ?? 0, color: 'bg-primary-100' }
          ]}
        />
      </div>
    </SummaryWrapper>
  )
}

interface CategorySectionProps {
  title: string
  titleStyle: string
  savings: CategorySavings | null
  tooltipText?: string
}

const CategorySection = ({ title, titleStyle, savings }: CategorySectionProps) => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-col gap-5 w-full'}>
      <CapitalizedText className={`${titleStyle} font-bold tracking-wide`}>{title}</CapitalizedText>
      <div className={'flex flex-col gap-2'}>
        <CategoryDataRow
          id={'commitments-monthly-savings'}
          label={t('optimization.summary.categories.monthlySavings')}
          amount={savings?.monthly}
          currency={savings?.currency}
        />
        <CategoryDataRow
          id={'commitments-annual-savings'}
          label={t('optimization.summary.categories.annualSavings')}
          amount={savings?.annual}
          annual={true}
          currency={savings?.currency}
        />
      </div>
    </div>
  )
}

interface DataRowProps {
  id: string
  label: string
  amount: number | undefined
  annual?: boolean
  currency: CurrencyId | undefined
}

const CategoryDataRow = ({ id, label, amount, annual, currency }: DataRowProps) => {
  return (
    <div className={'flex items-center justify-between gap-8 border-b border-dotted border-gray-500 lg:border-none'}>
      <GrayText>{label}</GrayText>
      <div className={'text-gray-50'}>
        {amount && amount >= 0 && currency ? (
          <GradientText className={`${annual ? 'text-125' : 'text-112'} font-bold from-gray-50 to-gray-100`}>
            <CountUp id={`qa-optimization-summary-${id}`} countTo={roundNumber(amount ?? 0, 0)} />{' '}
            {getCurrency(currency).symbol}
          </GradientText>
        ) : (
          '-'
        )}
      </div>
    </div>
  )
}
