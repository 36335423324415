import { CancelToken } from 'axios'
import { client } from '../../client'
import { FilterOption } from '../../../components/shared/filters/FilterSelect'
import {
  AwsConfigAccountIntegration,
  AwsIntegrationsState,
  AwsSecurityAccountIntegration
} from '../../settings/profile'

export const createAwsIntegration = async (
  integrationDetails: AwsIntegrationRequest,
  cancelToken: CancelToken
): Promise<AwsIntegrationsState> => {
  return await client
    .post('admin/integrations/aws', integrationDetails, { cancelToken })
    .then(resp => resp.data.map((integration: AwsIntegrationsState) => getDateFormattedResponse(integration)))
}

export const editAwsMasterAccount = async (masterAccount: AwsMasterAccount): Promise<AwsIntegrationsState> => {
  return await client
    .put(`admin/integrations/aws/${masterAccount.accountId}`, masterAccount)
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteAwsIntegration = async (masterAccountId: string, cancelToken: CancelToken) => {
  return await client.delete(`admin/integrations/aws/${masterAccountId}`, { cancelToken })
}

export const recoverAwsIntegration = async (
  masterAccountId: string,
  cancelToken: CancelToken
): Promise<AwsIntegrationsState> => {
  return await client
    .post(`admin/integrations/aws/${masterAccountId}/recover`, {}, { cancelToken: cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const addAwsSecurityAccount = async (
  masterAccountId: string,
  securityAccount: AwsSecurityAccountIntegration
): Promise<AwsIntegrationsState> => {
  return await client
    .post(`admin/integrations/aws/${masterAccountId}/security/${securityAccount.accountId}`, securityAccount)
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteAwsSecurityAccount = async (
  masterAccountId: string,
  securityAccount: AwsSecurityAccountIntegration
): Promise<AwsIntegrationsState> => {
  return await client
    .delete(`admin/integrations/aws/${masterAccountId}/security/${securityAccount.accountId}`, {
      data: securityAccount
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const recoverAwsSecurityAccount = async (
  masterAccountId: string,
  securityAccount: AwsSecurityAccountIntegration
): Promise<AwsIntegrationsState> => {
  return await client
    .post(`admin/integrations/aws/${masterAccountId}/security/${securityAccount.accountId}/recover`, securityAccount)
    .then(resp => getDateFormattedResponse(resp.data))
}

export const addAwsConfigAccount = async (masterAccountId: string, configAccount: AwsConfigAccountIntegration) => {
  return await client
    .post<AwsIntegrationsState>(
      `admin/integrations/aws/${masterAccountId}/config/${configAccount.accountId}`,
      configAccount
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteAwsConfigAccount = async (
  masterAccountId: string,
  configAccount: AwsConfigAccountIntegration
): Promise<AwsIntegrationsState> => {
  return await client
    .delete(`admin/integrations/aws/${masterAccountId}/config/${configAccount.accountId}`, {
      data: configAccount
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const recoverAwsConfigAccount = async (
  masterAccountId: string,
  configAccount: AwsConfigAccountIntegration
): Promise<AwsIntegrationsState> => {
  return await client
    .post(`admin/integrations/aws/${masterAccountId}/config/${configAccount.accountId}/recover`, configAccount)
    .then(resp => getDateFormattedResponse(resp.data))
}

export const markAwsIntegrationDismissed = async (
  integration: AwsIntegrationsState,
  cancelToken: CancelToken
): Promise<AwsIntegrationsState> => {
  return await client
    .put(`admin/integrations/aws/${integration.masterAccountId}/dismiss`, integration, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const getAwsRegionOptions = async (cancelToken: CancelToken): Promise<FilterOption[]> => {
  return await client.get('admin/integrations/aws/regions', { cancelToken }).then(resp => resp.data)
}

const getDateFormattedResponse = (integration: AwsIntegrationsState): AwsIntegrationsState => {
  return {
    ...integration,
    lastOrganizationIntegrationAt: integration.lastOrganizationIntegrationAt
      ? new Date(integration.lastOrganizationIntegrationAt)
      : null,
    resources: integration.resources.map(resource => ({
      ...resource,
      lastIntegrationAt: resource.lastIntegrationAt ? new Date(resource.lastIntegrationAt) : null,
      deletedAt: resource.deletedAt ? new Date(resource.deletedAt) : null
    })),
    compliance: integration.compliance.map(compliance => ({
      ...compliance,
      lastIntegrationAt: compliance.lastIntegrationAt ? new Date(compliance.lastIntegrationAt) : null,
      deletedAt: compliance.deletedAt ? new Date(compliance.deletedAt) : null
    })),
    billing: {
      ...integration.billing,
      lastIntegrationAt: integration.billing.lastIntegrationAt ? new Date(integration.billing.lastIntegrationAt) : null
    },
    recommendation: {
      ...integration.recommendation,
      lastIntegrationAt: integration.recommendation.lastIntegrationAt
        ? new Date(integration.recommendation.lastIntegrationAt)
        : null
    },
    usage: {
      ...integration.usage,
      lastIntegrationAt: integration.usage.lastIntegrationAt ? new Date(integration.usage.lastIntegrationAt) : null
    },
    deletedAt: integration.deletedAt ? new Date(integration.deletedAt) : null
  }
}

export interface AwsIntegrationRequest {
  master: AwsMasterAccount
  config: AwsConfigAccount
  security: AwsSecurityAccount
}

export interface AwsMasterAccount {
  accountId: string
  name: string
  role: string
  deletedAt: Date | undefined
}

export interface AwsConfigAccount {
  accountId: string
  region: FilterOption
  aggregatorName: string
  lastIntegrationAt: Date | undefined
  deletedAt: Date | undefined
}

export interface AwsSecurityAccount {
  accountId: string
  region: FilterOption
  lastIntegrationAt: Date | undefined
  deletedAt: Date | undefined
}
