import React, { useContext } from 'react'
import { Vendor } from '../../../utils/vendors'
import { OptimizationAwsTab } from './aws/OptimizationAwsTab'
import { OptimizationAzureTab } from './azure/OptimizationAzureTab'
import { OptimizationGcpTab } from './gcp/OptimizationGcpTab'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { AwsRecommendations } from '../../../api/optimization/aws'
import { AzureRecommendations } from '../../../api/optimization/azure'
import { GcpRecommendations } from '../../../api/optimization/gcp'
import { ClockTimestamp } from '../../shared/ClockTimestamp'
import { formatDate, getMaxDate } from '../../../utils/formats'
import { UserInfoContext } from '../../../state/context/UserInfoContext'

interface VendorTabProps {
  vendor: Vendor
  selectedVendors: Vendor[]
  awsRecommendations: AwsRecommendations | null
  azureRecommendations: AzureRecommendations | null
  gcpRecommendations: GcpRecommendations | null
}

export const OptimizationVendorTab = ({
  vendor,
  selectedVendors,
  awsRecommendations,
  azureRecommendations,
  gcpRecommendations
}: VendorTabProps) => {
  const { integrationStates } = useContext(UserInfoContext)

  const latestIntegrations =
    vendor === Vendor.AWS
      ? (integrationStates.aws?.map(i => i.recommendation.lastIntegrationAt).filter(i => i) ?? [])
      : vendor === Vendor.AZURE
        ? (integrationStates.azure?.map(i => i.recommendation.lastIntegrationAt).filter(i => i) ?? [])
        : vendor === Vendor.GCP
          ? (integrationStates.gcp?.flatMap(i => i.recommendation.lastIntegrationAt)?.filter(i => i) ?? [])
          : []

  return (
    <div>
      <ClockTimestamp dateString={formatDate(getMaxDate(latestIntegrations))} />
      {vendor === Vendor.AWS ? (
        <OptimizationAwsTab selectedVendors={selectedVendors} data={awsRecommendations} />
      ) : vendor === Vendor.AZURE ? (
        <OptimizationAzureTab selectedVendors={selectedVendors} data={azureRecommendations} />
      ) : vendor === Vendor.GCP ? (
        <OptimizationGcpTab selectedVendors={selectedVendors} recommendations={gcpRecommendations} />
      ) : (
        <MissingDataNotification justify={'center'} />
      )}
    </div>
  )
}
