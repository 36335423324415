import { Trans, useTranslation } from 'react-i18next'
import { useCancelToken } from '../../../api/client'
import React, { useContext, useEffect, useState } from 'react'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { useErrorHandling } from '../../../hooks/handleError'
import { FilterOption, FilterSelect, GroupedOptions } from '../../shared/filters/FilterSelect'
import { ErrorText, GrayText, Heading, Heading2, Heading3, WhiteText } from '../../shared/TextComponents'
import { Button, ButtonStyle, ButtonType } from '../../shared/buttons/Button'
import { CostAnomalyBudgetInputs, CostAnomalyLimitInputs } from '../../shared/CostAnomalyInputs'
import { BasicFilterType } from '../../shared/filters/Filters'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ModalContext } from '../../../state/context/ModalContext'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  RegisterOptions,
  useForm,
  UseFormRegisterReturn,
  UseFormWatch
} from 'react-hook-form'
import { distinctArray, emailRegExp } from '../../../utils/formats'
import {
  CostAnomalyBudget,
  CostAnomalyLimits,
  CostAnomalyRuleForm,
  createTenantCostAnomalyRule,
  deleteTenantCostAnomalyRule,
  getCostAnomalyTargetOptions,
  getTenantCostAnomalyRules,
  TenantCostAnomalyRule
} from '../../../api/admin/alerts'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'

import { ModalActions } from '../../shared/modal/Modal'
import { CustomInput } from '../../shared/filters/FormComponents'
import { ChangeType } from '../../../api/notifications'
import { getCurrency } from '../../../utils/Currency'
import { StepMeter } from '../../shared/StepMeter'
import { TenantCostAnomalyRuleList } from './TenantCostAnomalyRuleList'
import { Vendor } from '../../../utils/vendors'
import { VendorIndicator } from '../../shared/indicators/VendorIndicator'
import { NestedPageHeader } from '../../shared/NestedPageHeader'
import { Layout, SubLayoutContentWrapper } from '../../../layout/Layout'
import { CustomIcon } from '../../shared/CustomIcon'
import addIcon from '../../../assets/svg/symbols/plus.svg'

export const TenantCostAnomalyRules = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { setModal } = useContext(ModalContext)
  const { setMessage } = useContext(MessageContext)
  const handleError = useErrorHandling()
  const [rules, setRules] = useState<TenantCostAnomalyRule[]>([])
  const [projectOptions, setProjectOptions] = useState<GroupedOptions[]>([])
  const [serviceOptions, setServiceOptions] = useState<GroupedOptions[]>([])

  useEffect(() => {
    const cancelToken = createCancelToken()
    Promise.all([
      getTenantCostAnomalyRules(cancelToken.token).then(setRules),
      getCostAnomalyTargetOptions(cancelToken.token).then(data => {
        setProjectOptions(data.projects)
        setServiceOptions(data.services)
      })
    ]).catch(handleError)
    return () => {
      cancelToken.cancel()
    }
  }, [createCancelToken, handleError])

  const openCreateModal = () => {
    const createRule = (rule: CostAnomalyRuleForm) => {
      createTenantCostAnomalyRule(rule, createCancelToken().token)
        .then(resp => {
          setRules(resp)
          setModal(null)
          setMessage({ type: MessageType.SUCCESS, message: t('admin.alerts.costs.createRuleSuccess') })
        })
        .catch(handleError)
    }
    setModal({
      useOnClickOutside: false,
      zIndex: 1000,
      body: (
        <CreateCostAnomalyRuleModal
          projectOptions={projectOptions}
          serviceOptions={serviceOptions}
          submitAction={createRule}
          ruleNames={rules.map(r => r.name)}
        />
      )
    })
  }

  const deleteRule = (rule: TenantCostAnomalyRule) => {
    const action = () =>
      deleteTenantCostAnomalyRule(rule.ruleId, createCancelToken().token)
        .then(resp => {
          setRules(resp)
          setModal(null)
          setMessage({
            type: MessageType.SUCCESS,
            message: t('admin.alerts.costs.deleteRuleSuccess')
          })
        })
        .catch(handleError)

    setModal({
      zIndex: 1000,
      header: t('admin.alerts.costs.deleteRule'),
      body: (
        <>
          <GrayText className={'text-center leading-6'}>
            <Trans>{t('admin.alerts.costs.deleteRuleConfirm', { name: rule.name })}</Trans>
          </GrayText>
          <ModalActions>
            <Button
              type={ButtonType.FORM}
              value={t('common.cancel')}
              buttonStyle={ButtonStyle.SECONDARY}
              clickHandler={() => setModal(null)}
            />
            <Button value={t('common.delete')} clickHandler={action} type={ButtonType.FORM} />
          </ModalActions>
        </>
      )
    })
  }

  return (
    <Layout type={'sub'}>
      <NestedPageHeader
        mainHeading={t('admin.alerts.costs.heading')}
        capitalize={true}
        subHeading={<GrayText>{t('admin.alerts.costs.subHeading')}</GrayText>}
      />
      <SubLayoutContentWrapper>
        <div className={'flex flex-col gap-10'}>
          <div className={'flex w-full justify-between items-center gap-10'}>
            <div>
              <Heading>{t('admin.alerts.costs.listHeading')}</Heading>
              <GrayText>{t('admin.alerts.costs.listDescription')}</GrayText>
            </div>
            <Button
              type={ButtonType.ICON}
              value={<CustomIcon path={addIcon} styles={'w-6 h-6 bg-gray-50'} />}
              clickHandler={() => openCreateModal()}
            />
          </div>
          <TenantCostAnomalyRuleList rules={rules} deleteRule={deleteRule} />
        </div>
      </SubLayoutContentWrapper>
    </Layout>
  )
}

interface CostAnomalyRuleModalProps {
  projectOptions: GroupedOptions[]
  serviceOptions: GroupedOptions[]
  submitAction: (rule: CostAnomalyRuleForm) => void
  ruleNames: string[]
}

const CreateCostAnomalyRuleModal = ({
  projectOptions,
  serviceOptions,
  submitAction,
  ruleNames
}: CostAnomalyRuleModalProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const [step, setStep] = useState(1)
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm<CostAnomalyRuleForm>({
    defaultValues: {
      name: null,
      emailAddresses: [],
      decrease: {
        percent: userSettings.defaultCostAnomalyLimits.decreasePercent,
        monthlyImpact: userSettings.defaultCostAnomalyLimits.decreaseMonthlyImpact
      },
      increase: {
        percent: userSettings.defaultCostAnomalyLimits.increasePercent,
        monthlyImpact: userSettings.defaultCostAnomalyLimits.increaseMonthlyImpact
      },
      budget: null,
      currency: userSettings.currency,
      projects: [],
      services: []
    }
  })
  const budget = watch('budget')

  useEffect(() => {
    !getEmailsFromString(watch('emailAddresses').toString()).length
      ? setError('emailAddresses', { type: 'required' }, { shouldFocus: true })
      : clearErrors('emailAddresses')

    const name = watch('name') !== '' ? watch('name') : null
    !name
      ? setError('name', { type: 'required' }, { shouldFocus: true })
      : ruleNames.includes(name)
        ? setError('name', { type: 'unique' })
        : clearErrors('name')

    !watch('projects').length ? setError('projects', { type: 'required' }) : clearErrors('projects')
  }, [step])

  const onSubmit = (data: CostAnomalyRuleForm) => {
    submitAction({
      ...data,
      emailAddresses: getEmailsFromString(data.emailAddresses.toString()),
      projects: data.projects.map(project => ({
        vendor: project.vendor,
        label: project.label,
        value: project.value
      }))
    })
  }

  const stepDescription =
    step === 1
      ? t('admin.alerts.costs.createRuleStep_1')
      : step === 2
        ? t('admin.alerts.costs.createRuleStep_2')
        : step === 3
          ? t('admin.alerts.costs.createRuleStep_3')
          : step === 4
            ? t('admin.alerts.costs.createRuleStep_4')
            : undefined

  return (
    <div className={'flex flex-col sm:px-6'}>
      <FlexCol>
        <Heading className={'text-center'}>{t('admin.alerts.costs.createModalHeader')}</Heading>
        <StepMeter totalSteps={4} currentStep={step} description={stepDescription} />
      </FlexCol>
      <form className={'flex flex-col pt-10 pb-4 w-full gap-16 lg:gap-20 max-w-125 sm:w-125'}>
        {step === 1 ? (
          <NameEmailsStep
            register={() => register('name')}
            emailInput={
              <CustomInput
                placeholder={t('admin.alerts.costs.emailsPlaceholder')}
                {...register('emailAddresses', {
                  required: true,
                  pattern: emailRegExp,
                  onBlur: e => setValue('emailAddresses', getEmailsFromString(e.target.value))
                })}
              />
            }
          />
        ) : step === 2 ? (
          <TargetsStep
            control={control}
            watch={watch}
            projectOptions={projectOptions}
            serviceOptions={serviceOptions}
          />
        ) : step === 3 ? (
          <LimitStep budget={budget} control={control} />
        ) : step === 4 ? (
          // @ts-ignore
          <CheckingStep watch={watch} errors={errors} setStep={setStep} />
        ) : null}
      </form>

      <ModalActions>
        {step > 1 && (
          <Button
            value={t('common.return')}
            buttonStyle={ButtonStyle.SECONDARY}
            type={ButtonType.FORM}
            clickHandler={() => setStep(step - 1)}
          />
        )}
        {step < 4 ? (
          <Button
            value={t('common.next')}
            buttonStyle={ButtonStyle.PRIMARY}
            type={ButtonType.FORM}
            clickHandler={() => setStep(step + 1)}
          />
        ) : (
          <Button
            value={t('admin.alerts.costs.create')}
            type={ButtonType.FORM}
            disabled={Object.keys(errors).length > 0}
            clickHandler={() => handleSubmit(onSubmit)()}
          />
        )}
      </ModalActions>
    </div>
  )
}

interface NameEmailsStepProps {
  register: (name: 'name', options?: RegisterOptions<CostAnomalyRuleForm, 'name'>) => UseFormRegisterReturn<'name'>
  emailInput: React.ReactNode
}

const NameEmailsStep = ({ register, emailInput }: NameEmailsStepProps) => {
  const { t } = useTranslation()

  return (
    <FormSection>
      <FlexCol>
        <Heading2>{t('admin.alerts.costs.ruleName')} *</Heading2>
        <GrayText>{t('admin.alerts.costs.nameDescription')}</GrayText>
        <CustomInput
          placeholder={t('admin.alerts.costs.namePlaceholder')}
          {...register('name', {
            required: true
          })}
        />
      </FlexCol>
      <FlexCol>
        <Heading2>{t('admin.alerts.costs.emails')} *</Heading2>
        <GrayText>{t('admin.alerts.costs.emailsDescription')}</GrayText>
        {emailInput}
      </FlexCol>
    </FormSection>
  )
}

interface TargetsStepProps {
  control: Control<CostAnomalyRuleForm>
  watch: UseFormWatch<CostAnomalyRuleForm>
  projectOptions: GroupedOptions[]
  serviceOptions: GroupedOptions[]
}

const TargetsStep = ({ control, watch, projectOptions, serviceOptions }: TargetsStepProps) => {
  const { t } = useTranslation()
  const [vendorServices, setVendorServices] = useState<GroupedOptions[]>(serviceOptions)

  useEffect(() => {
    const projects = watch('projects')
    setVendorServices(serviceOptions.filter(o => projects.some(p => p.vendor === o.label)))
  }, [serviceOptions, watch])

  return (
    <FormSection>
      <FlexCol>
        <Heading2>{t('admin.alerts.costs.targets')} *</Heading2>
        <GrayText>{t('admin.alerts.costs.targetsDescription')}</GrayText>
        <Controller
          name={'projects'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <CostAnomalyRuleTargetMenu
              type={BasicFilterType.PROJECTS}
              useHeader={false}
              options={projectOptions}
              value={value || []}
              onChange={selected => {
                onChange(selected)
                setVendorServices(serviceOptions.filter(o => selected.some(p => p.vendor === o.label)))
              }}
            />
          )}
        />
      </FlexCol>
      <FlexCol>
        <Heading3>{t('admin.alerts.costs.serviceTargetsHeading')}</Heading3>
        <GrayText>{t('admin.alerts.costs.serviceTargetsDescription')}</GrayText>
        <Controller
          name={'services'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <CostAnomalyRuleTargetMenu
              useHeader={false}
              type={BasicFilterType.SERVICES}
              options={vendorServices || []}
              value={value || []}
              onChange={onChange}
              noOptionsMessage={t('admin.alerts.costs.noServiceOptions')}
            />
          )}
        />
        <GrayText className={'text-90'}>{t('admin.alerts.costs.serviceTargetsDescriptionTip')}</GrayText>
      </FlexCol>
    </FormSection>
  )
}

interface FormStepProps {
  budget: CostAnomalyBudget | null
  control: Control<CostAnomalyRuleForm>
}

const LimitStep = ({ budget, control }: FormStepProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  return (
    <FormSection className={'pb-4'}>
      <FormSectionContent>
        <FlexCol>
          <Heading2>{t('admin.alerts.costs.ruleLimits')}</Heading2>
          <GrayText>{t('admin.alerts.costs.ruleLimitsDescription')}</GrayText>
        </FlexCol>
        <Controller
          name={'increase'}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <CostAnomalyLimitInputs type={ChangeType.INCREASING} limits={value} onChange={onChange} onBlur={onBlur} />
          )}
        />
        <Controller
          name={'decrease'}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <CostAnomalyLimitInputs type={ChangeType.DECREASING} limits={value} onChange={onChange} onBlur={onBlur} />
          )}
        />
        <Controller
          name={'budget'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <CostAnomalyBudgetInputs
              showDescription={true}
              showInitially={!!budget}
              monthlyBudget={value?.budget || null}
              budgetAlertPercent={value?.alertPercent || null}
              onBudgetChange={value =>
                value
                  ? onChange({
                      budget: value,
                      alertPercent: budget?.alertPercent || 75
                    })
                  : onChange(null)
              }
              onPercentChange={value => onChange({ ...budget, alertPercent: value })}
              onClear={() => onChange(null)}
              onBudgetBlur={() => undefined}
              onPercentBlur={() => undefined}
              currency={userSettings.currency}
            />
          )}
        />
      </FormSectionContent>
    </FormSection>
  )
}

interface CheckingStepProps {
  watch: UseFormWatch<CostAnomalyRuleForm>
  errors: FieldErrorsImpl<CostAnomalyRuleForm>
  setStep: (step: number) => void
}

const CheckingStep = ({ watch, errors, setStep }: CheckingStepProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const decrease = watch('decrease')
  const increase = watch('increase')
  const budget = watch('budget')
  const selectedProjects = watch('projects')
  const selectedServices = watch('services')
  const emails = watch('emailAddresses')
  const name = watch('name')

  const getVendorTargets = (vendor: Vendor) => {
    const projectCount = selectedProjects.filter(p => p.vendor === vendor).length
    const services = selectedServices.filter(s => s.vendor === vendor).length
    const servicePhrase =
      services > 0 ? (
        <>
          <strong>{services}</strong> {t('common.service', { count: services })}
        </>
      ) : (
        t('admin.alerts.costs.allServices')
      )
    return (
      <GrayText className={'flex items-center gap-3'}>
        <VendorIndicator type={'icon'} vendor={vendor} size={8} padding={0.8} />
        <span>
          <strong>{projectCount}</strong> {t(`vendors.${vendor}.short`)}{' '}
          {t(`vendors.${vendor}.projectPhrase`, { count: projectCount })}
          {' - '}
          {servicePhrase}
        </span>
      </GrayText>
    )
  }

  const LimitsInfo = ({ limits }: { limits: CostAnomalyLimits }) => {
    const rowStyles =
      'flex w-full gap-5 justify-between first:pb-1 last:pt-1 first:border-b border-dotted border-gray-500'
    return (
      <div>
        <div className={rowStyles}>
          <GrayText>{t('settings.notifications.changePercent')}</GrayText>
          <WhiteText className={'font-semibold'}>{limits.percent}</WhiteText>
        </div>
        <div className={rowStyles}>
          <GrayText>
            {t('settings.notifications.monthlyImpact', {
              currency: getCurrency(userSettings.currency).symbol
            })}
          </GrayText>
          <WhiteText className={'font-semibold'}>{limits.monthlyImpact}</WhiteText>
        </div>
      </div>
    )
  }

  const BudgetInfo = ({ budget }: { budget: CostAnomalyBudget }) => {
    return (
      <div>
        <div
          className={
            'flex w-full gap-5 justify-between first:pb-1 last:pt-1 first:border-b border-dotted border-gray-500'
          }
        >
          <GrayText>{t('settings.notifications.monthlyBudget')}</GrayText>
          <WhiteText className={'font-semibold'}>{budget.budget}</WhiteText>
        </div>
        <div
          className={
            'flex w-full gap-5 justify-between first:pb-1 last:pt-1 first:border-b border-dotted border-gray-500'
          }
        >
          <GrayText>{t('settings.notifications.budgetPercent')}</GrayText>
          <WhiteText className={'font-semibold'}>{budget.alertPercent}</WhiteText>
        </div>
      </div>
    )
  }

  return (
    <FormSection>
      <FormSectionContent>
        <Heading2>{t('admin.alerts.costs.reviewRule')}</Heading2>
        <FlexCol>
          <Heading3 className={'first-letter:capitalize text-90 font-semibold'}>
            {t('admin.alerts.costs.ruleName')}
          </Heading3>
          {errors.name?.type ? (
            <ErrorText onClick={() => setStep(1)} className={'cursor-pointer'}>
              {name !== '' && <GrayText lowercase={true}>{name}</GrayText>}
              {t(`admin.alerts.costs.nameError.${errors.name.type}`)}
            </ErrorText>
          ) : (
            <GrayText lowercase={true}>{name}</GrayText>
          )}
        </FlexCol>
        <FlexCol>
          <Heading3>{t('admin.alerts.costs.emails')}</Heading3>
          <div className={'flex flex-col gap-1'}>
            {errors.emailAddresses ? (
              <ErrorText onClick={() => setStep(1)} className={'cursor-pointer'}>
                {t('admin.alerts.costs.emailsError')}
              </ErrorText>
            ) : (
              emails.map(email => (
                <GrayText key={email} lowercase={true}>
                  {email}
                </GrayText>
              ))
            )}
          </div>
        </FlexCol>
      </FormSectionContent>
      <FormSectionContent>
        <FlexCol>
          <Heading3 className={'first-letter:capitalize text-90 font-semibold'}>
            {t('admin.alerts.costs.limitsForIncrease')}
          </Heading3>
          <LimitsInfo limits={increase} />
        </FlexCol>
        <FlexCol>
          <Heading3 className={'first-letter:capitalize text-90 font-semibold'}>
            {t('admin.alerts.costs.limitsForDecrease')}
          </Heading3>
          <LimitsInfo limits={decrease} />
        </FlexCol>
        {budget && (
          <FlexCol>
            <Heading3 className={'first-letter:capitalize text-90 font-semibold'}>
              {t('admin.alerts.costs.budgetLimits')}
            </Heading3>
            <BudgetInfo budget={budget} />
          </FlexCol>
        )}
      </FormSectionContent>
      <FlexCol>
        <Heading3>{t('admin.alerts.costs.targets')}</Heading3>
        <div className={'flex flex-col gap-1'}>
          {errors.projects ? (
            <ErrorText className={'cursor-pointer'} onClick={() => setStep(2)}>
              {t('admin.alerts.costs.projectsError')}
            </ErrorText>
          ) : (
            distinctArray(selectedProjects.map(p => p.vendor)).map(v => (
              <GrayText key={v}>{getVendorTargets(v)}</GrayText>
            ))
          )}
        </div>
      </FlexCol>
    </FormSection>
  )
}

interface TargetOptionsMenuProps {
  header?: string
  useHeader?: boolean
  type: BasicFilterType.PROJECTS | BasicFilterType.SERVICES
  menuOpen?: boolean
  options: GroupedOptions[]
  value: FilterOption[]
  onFocus?: () => void
  onChange: (selected: FilterOption[]) => void
  className?: string
  noOptionsMessage?: string
}

export const CostAnomalyRuleTargetMenu = ({
  header,
  useHeader = true,
  type,
  menuOpen,
  options,
  value,
  onFocus,
  onChange,
  className,
  noOptionsMessage
}: TargetOptionsMenuProps) => {
  return (
    <FilterSelect
      header={useHeader ? header || type : undefined}
      type={type}
      value={value}
      isMulti={true}
      menuIsOpen={menuOpen}
      autoFocus={menuOpen}
      noOptionsMessage={() => noOptionsMessage}
      closeMenuOnSelect={false}
      options={options}
      onFocus={onFocus}
      onChange={selected => onChange(selected as FilterOption[])}
      className={`${className} max-w-120`}
      styles={{
        menuList: base => ({ ...base, position: 'absolute' })
      }}
    />
  )
}

const FormSection = styled.div`
  ${tw`flex flex-col w-full gap-12`}
`

const FlexCol = styled.div`
  ${tw`flex flex-col gap-3`}
`

const FormSectionContent = styled.div`
  ${tw`flex flex-col gap-6 justify-between`}
`

const getEmailsFromString = (emails: string): string[] =>
  emails
    .split(',')
    .map(email => email.trim())
    .filter(email => emailRegExp.test(email))
