import Auth from '@aws-amplify/auth'
import axios, { CancelTokenSource, InternalAxiosRequestConfig } from 'axios'
import { useCallback, useEffect, useRef } from 'react'

let cachedToken: string | null = null
let tokenExpiration: number | null = null

const getToken = async () => {
  if (!cachedToken || !tokenExpiration || tokenExpiration < Date.now()) {
    const session = await Auth.currentSession()
    cachedToken = session.getIdToken().getJwtToken()
    tokenExpiration = session.getIdToken().getExpiration() * 1000
  }
  return cachedToken
}

const refreshToken = async () => {
  const session = await Auth.currentSession()
  return session.getRefreshToken().getToken()
}

export const client = axios.create({
  baseURL: `${process.env['REACT_APP_API_URL']}/api`
})
client.defaults.headers.post['Content-Type'] = 'application/json'

export const openClient = axios.create({
  baseURL: `${process.env['REACT_APP_API_URL']}`
})
openClient.defaults.headers.post['Content-Type'] = 'application/json'

client.interceptors.request.use(
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
      let token = await getToken()
      if (tokenExpiration && tokenExpiration < Date.now()) {
        token = await refreshToken()
      }
      const user = await Auth.currentAuthenticatedUser()
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`
        config.headers.userEmailAddress = user.attributes.email
      }
      return config
    } catch (error) {
      console.error('Failed to authenticate request:', error)
      return Promise.reject(error)
    }
  },
  error => Promise.reject(error)
)

// axios cancel token hook for preventing memory leaks
export const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource | null>(null)

  const createCancelToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source()
    return axiosSource.current
  }, [axiosSource])

  useEffect(() => {
    if (axiosSource.current) axiosSource.current.cancel()
  }, [])

  return { createCancelToken }
}
