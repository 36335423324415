// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { Props } from 'react-select'
import styled from 'styled-components'
import tw from 'twin.macro'
import { formatNumber } from '../../../utils/formats'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import {
  FilterActionIcon,
  SelectClearIndicator,
  selectControlStyles,
  SelectDropdownIndicator,
  selectGroupHeadingStyles,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectNoOptionsStyles,
  selectOptionStyles
} from './ReactSelectStyles'
import { CheckboxOption } from '../buttons/CheckboxOption'
import { Vendor, Vendors } from '../../../utils/vendors'
import { CapitalizedText, WhiteText } from '../TextComponents'
import { FilterNestedSelect } from './FilterNestedSelect'
import { ActionsFilterType, BasicFilterType, CurrencyFilterOption } from './Filters'
import { CurrencyFlag, CurrencyId } from '../../../utils/Currency'
import { useErrorHandling } from '../../../hooks/handleError'
import { useCancelToken } from '../../../api/client'
import { changeUserPreferredCurrency } from '../../../api/settings/profile'
import { getCostAnomalyNotifications } from '../../../api/notifications'
import { NotificationsContext } from '../../../state/context/NotificationsContext'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { Loading } from '../Loading'
import { MissingDataNotification } from '../MissingDataNotification'

export const filterSelectAllOption = { label: 'Select all', value: 'select-all' }

export interface FilterOption {
  vendor?: Vendor
  label: string
  value: string
  count?: number
  nest?: FilterOption[]
}

export interface GroupedOptions {
  label: string
  options: FilterOption[]
}

type CustomProps =
  | {
      loading?: boolean
      header?: string
      type: BasicFilterType | ActionsFilterType
      changeUserCurrency?: boolean
      indicatorColor?: string
      onClear?: () => void
      deletePresetAction?: (arg: string) => void
    }
  | {
      header?: string
      type: BasicFilterType.CURRENCY
      changeUserCurrency: boolean
      loading?: never
      indicatorColor?: never
      onClear?: never
      deletePresetAction?: never
    }

type FilterProps = CustomProps & Props

export const FilterSelect = (props: FilterProps) => {
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const { setUserSettings } = useContext(UserInfoContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { type, isMulti, value, header, indicatorColor, deletePresetAction, options, onClear } = props
  const { t } = useTranslation()
  const allOptions = [filterSelectAllOption, ...(options as FilterOption[])]
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([])
  const selectAllIsOption =
    isMulti && ![BasicFilterType.VENDORS, ActionsFilterType.PRESETS].includes(type) && options && options.length >= 1
  const [allSelected, setAllSelected] = useState(false)
  const [matchingOptions, setMatchingOptions] = useState<FilterOption[]>([])
  const [selected, setSelected] = useState<FilterOption[]>([])

  // custom onInputChange prevents resetting filter value after option has been selected
  // https://github.com/JedWatson/react-select/issues/3210
  const [inputValue, setInputValue] = useState<string>('')
  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') setInputValue(query)
  }

  const isNested: boolean = header === 'tags' || (options[0]?.options?.length && options[0].options[0].nest?.length > 0)

  useEffect(() => {
    options &&
      setFilterOptions(
        options.flatMap(option => (option?.options?.length > 0 ? option.options.map(option => option) : option))
      )
  }, [options])

  useEffect(() => {
    if (inputValue !== '') {
      setMatchingOptions(
        filterOptions.filter(
          option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toLowerCase().includes(inputValue.toLowerCase())
        )
      )
    } else setMatchingOptions(filterOptions)
  }, [filterOptions, inputValue])

  useEffect(() => {
    setAllSelected(matchingOptions.every(option => selected.map(s => s.value).includes(option.value)))
  }, [matchingOptions, selected])

  const handleSelect = (newOptions: FilterOption[]) => {
    let result = newOptions

    if (newOptions.find(option => option.value === filterSelectAllOption.value)) {
      result = allSelected
        ? newOptions.filter(
            option =>
              !matchingOptions.map(m => m.value).includes(option.value) && option.value !== filterSelectAllOption.value
          )
        : matchingOptions.concat(
            newOptions.filter(option => !matchingOptions.includes(option) && option.value !== t('filters.selectAll'))
          )
    }

    result.includes(filterSelectAllOption) && result.splice(result.indexOf(filterSelectAllOption), 1)
    props.onChange(result)
    setSelected(result)
  }

  const handleUserCurrencyChange = (selectedCurrency: CurrencyId) => {
    const cancelToken = createCancelToken()
    changeUserPreferredCurrency(selectedCurrency, cancelToken.token)
      .then(resp => {
        setUserSettings(resp)
        getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
      })
      .catch(handleError)
  }

  const filterOption = (option: FilterOption, query: string) => {
    return (
      (option.value === filterSelectAllOption.value && matchingOptions?.length >= 1) ||
      query === '' ||
      option.label.toLowerCase().includes(query.toLowerCase()) ||
      option.value.toLowerCase().includes(query.toLowerCase())
    )
  }

  const getPlaceholder = () => {
    if (value) {
      return value.length
        ? value.length === 1
          ? value[0].label
          : value.length === filterOptions.length
            ? t('filters.allSelected', { count: value.length })
            : t('filters.multipleSelected', { count: value.length })
        : props.placeholder
    }
    return props.placeholder
  }

  const formatOptionLabel = (data: FilterOption) => {
    if (type === ActionsFilterType.PRESETS || type === BasicFilterType.ADMIN_PRESETS)
      return (
        <FilterOptionContainer>
          <span>{data.label}</span>
          <div className={'pr-3'}>
            {deletePresetAction && (
              <FilterActionIcon type={'clear'} clickHandler={() => deletePresetAction(data.value)} />
            )}
          </div>
        </FilterOptionContainer>
      )
    if (type === BasicFilterType.CURRENCY)
      return (
        <FilterOptionContainer title={data.label}>
          <div className={'flex items-center gap-4'}>
            <CurrencyFlag currencyId={data.value} />
            {data.label}
          </div>
        </FilterOptionContainer>
      )
    return (
      <FilterOptionContainer title={data.label}>
        <CheckboxOption
          label={Vendors.find(vendor => vendor === data.label) ? t(`vendors.${data.label}.short`) : data.label}
          type={'filter'}
          clickHandler={() => props.onChange}
          checked={
            data.value === filterSelectAllOption.value ? allSelected : value.some(option => option.value === data.value)
          }
        />
        {(data.count || data.count === 0) && (
          <WhiteText className={'text-80'}>{`(${formatNumber(data.count)})`}</WhiteText>
        )}
      </FilterOptionContainer>
    )
  }

  const noOptionsMessage = () => {
    return (
      <MissingDataNotification
        paddingY={1}
        size={5}
        displayText={props?.noOptionsMessage ? props.noOptionsMessage() : t('filters.noOptions')}
      />
    )
  }

  return (
    <FilterMenuWrapper>
      {(indicatorColor || header || isNested) && (
        <LabelWrapper>
          {indicatorColor && (
            <FilterIndicator
              color={indicatorColor}
              ping={(type === BasicFilterType.ADMIN_PRESETS || type === ActionsFilterType.PRESETS) && value?.length > 0}
            />
          )}
          {header && <CapitalizedText className={'text-90 text-gray-100'}>{header}</CapitalizedText>}
          {isNested && (
            <InfoTooltip useIcon={true} styles={'w-60 mt-6'}>
              {t('filters.tagsInfo')}
            </InfoTooltip>
          )}
        </LabelWrapper>
      )}
      {isNested ? (
        <FilterNestedSelect keyOptions={options} setSelectedOptions={props.onChange} selectedOptions={value} />
      ) : (
        <Select
          {...props}
          unstyled
          isLoading={
            (options?.length === 0 && props.loading) || (props.loading && type === BasicFilterType.RESOURCE_GROUPS)
          }
          styles={props.styles}
          classNames={{
            control: () => selectControlStyles + ` ${props.loading && 'hover:cursor-wait'}`,
            menuList: () => selectMenuListStyles + ' w-full shadow-xs',
            noOptionsMessage: () => selectNoOptionsStyles,
            placeholder: () => 'text-gray-200',
            option: state => selectOptionStyles + ` max-w-full ${state.isFocused && 'bg-gray-600 text-gray-50'}`,
            group: () => 'my-4',
            groupHeading: () => selectGroupHeadingStyles
          }}
          menuIsOpen={props.loading && type === BasicFilterType.RESOURCE_GROUPS ? false : props.menuIsOpen}
          filterOption={filterOption}
          defaultValue={value}
          options={selectAllIsOption ? allOptions : options}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={noOptionsMessage}
          controlShouldRenderValue={type === BasicFilterType.CURRENCY}
          closeMenuOnSelect={!isMulti}
          isClearable={type !== BasicFilterType.CURRENCY}
          isSearchable={type !== BasicFilterType.CURRENCY}
          tabSelectsValue={false}
          hideSelectedOptions={false}
          placeholder={getPlaceholder()}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onChange={(newOptions: FilterOption | CurrencyFilterOption) => {
            selectAllIsOption ? handleSelect(newOptions) : props.onChange(newOptions)
            type === BasicFilterType.CURRENCY &&
              props.changeUserCurrency &&
              handleUserCurrencyChange(newOptions.value as CurrencyId)
          }}
          components={{
            ClearIndicator: () => (
              <SelectClearIndicator
                clearValue={() => {
                  onClear && onClear()
                  handleSelect([])
                  isMulti ? props.onChange([]) : props.onChange(null)
                }}
              />
            ),
            IndicatorSeparator: SelectIndicatorSeparator,
            DropdownIndicator: SelectDropdownIndicator,
            LoadingIndicator: () => (
              <div className={'px-4'}>
                <Loading height={28} />
              </div>
            )
          }}
        />
      )}
    </FilterMenuWrapper>
  )
}

export const FilterOptionContainer = styled.div`
  ${tw`flex justify-between gap-4 items-center`}
`

const FilterMenuWrapper = styled.div`
  ${tw`w-full flex flex-col gap-1 min-w-50 max-w-130`}
`

const LabelWrapper = styled.div`
  ${tw`flex flex-row gap-2 items-center`}
`

const FilterIndicator = ({ color, ping }: { color: string; ping?: boolean }) => {
  return (
    <div className={`inline-flex relative w-2.5 h-2.5`}>
      {ping && <div className={'animate-ping absolute rounded-full h-full w-full bg-primary-300'} />}
      <div className={`rounded-full h-full w-full ${color}`}></div>
    </div>
  )
}
