import React, { useContext, useRef, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { ComplianceFilters } from './ComplianceFilters'
import { ComplianceTotalSummary } from './total-summary/ComplianceTotalSummary'
import { ComplianceTabs } from './tabs/ComplianceTabs'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../layout/Layout'
import { TimeframeOption } from '../../utils/classes'

export const Compliance = () => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedStandards, setSelectedStandards] = useState<FilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [chartTimeframe, setChartTimeframe] = useState<TimeframeOption>(TimeframeOption.THIRTY_DAYS)
  const [includeSuppressed, setIncludeSuppressed] = useState(true)
  const refVisibleVendors = useRef(userSettings.visibleVendors)

  if (
    (!selectedVendors.length && userSettings.visibleVendors.length > 0) ||
    refVisibleVendors.current !== userSettings.visibleVendors
  ) {
    setSelectedVendors(
      userSettings.visibleVendors.map(v => ({
        label: t(`vendors.${v}.short`),
        value: v
      }))
    )
    refVisibleVendors.current = userSettings.visibleVendors
  }

  return (
    <Layout>
      <ComplianceFilters
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedStandards={selectedStandards}
        setSelectedStandards={setSelectedStandards}
        selectedRegions={selectedRegions}
        setSelectedRegions={setSelectedRegions}
        includeSuppressed={includeSuppressed}
        setIncludeSuppressed={setIncludeSuppressed}
      />
      <ComplianceTotalSummary
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
        selectedRegions={selectedRegions.map(r => r.value)}
        includeSuppressed={includeSuppressed}
      />
      <ComplianceTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
        selectedRegions={selectedRegions.map(r => r.value)}
        includeSuppressed={includeSuppressed}
        chartTimeframe={chartTimeframe}
        setChartTimeframe={setChartTimeframe}
      />
    </Layout>
  )
}
