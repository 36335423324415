import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TicketPriority } from '../../../api/tickets'
import { AwsWorkflowStatus, ComplianceStatus } from '../../../api/compliance/tabs'

export enum SeverityLevel {
  UNSPECIFIED = 'UNSPECIFIED',
  INFORMATIONAL = 'INFORMATIONAL',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL'
}

export enum StatusType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  OK = 'OK',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY'
}

type BadgeType = SeverityLevel | TicketPriority | StatusType | ComplianceStatus | AwsWorkflowStatus

interface IndicatorProps {
  value: BadgeType
}

export const TextBadge = ({ value }: IndicatorProps) => {
  const { t } = useTranslation()
  const passedStatuses = [ComplianceStatus.PASSED, ComplianceStatus.Compliant, ComplianceStatus.INACTIVE]
  const failedStatuses = [ComplianceStatus.FAILED, ComplianceStatus.NonCompliant, ComplianceStatus.ACTIVE]

  const criticalStatuses: BadgeType[] = [
    SeverityLevel.CRITICAL,
    TicketPriority.URGENT,
    StatusType.ERROR,
    StatusType.UNHEALTHY,
    ...failedStatuses
  ]
  const highStatuses: BadgeType[] = [SeverityLevel.HIGH, TicketPriority.HIGH, AwsWorkflowStatus.NEW]
  const mediumStatuses: BadgeType[] = [
    StatusType.WARNING,
    SeverityLevel.MEDIUM,
    TicketPriority.MEDIUM,
    AwsWorkflowStatus.NOTIFIED
  ]
  const lowStatuses: BadgeType[] = [SeverityLevel.LOW, TicketPriority.LOW]
  const informationalStatuses: BadgeType[] = [
    SeverityLevel.INFORMATIONAL,
    StatusType.OK,
    StatusType.HEALTHY,
    AwsWorkflowStatus.RESOLVED
  ]
  const unknownStatuses: BadgeType[] = [SeverityLevel.UNSPECIFIED, StatusType.NOT_AVAILABLE]

  const colorStyles = criticalStatuses.includes(value)
    ? 'bg-error-500/50 text-primary-500'
    : highStatuses.includes(value)
      ? 'bg-primary-600/35 text-primary-500'
      : mediumStatuses.includes(value)
        ? 'bg-warn-500/35 text-warn-500'
        : lowStatuses.includes(value)
          ? 'bg-tertiary-500/35 text-tertiary-500'
          : informationalStatuses.includes(value)
            ? 'bg-success-500/40 text-tertiary-200'
            : 'bg-gray-300/40 text-gray-200'

  return (
    <IndicatorWrapper key={value} className={colorStyles}>
      {passedStatuses.includes(value as ComplianceStatus)
        ? t('compliance.status.pass')
        : failedStatuses.includes(value as ComplianceStatus)
          ? t('compliance.status.fail')
          : unknownStatuses.includes(value)
            ? t('common.n_a')
            : value.replace('_', ' ')}
    </IndicatorWrapper>
  )
}

const IndicatorWrapper = styled.div`
  ${tw`h-fit px-2 py-1 rounded-full w-max leading-none text-75 font-semibold tracking-wide uppercase`}
`
