import React, { useContext, useEffect, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import {
  deleteInfraFilter,
  getInfraFilterOptions,
  getInfraFilterPresets,
  InfraFilterData,
  InfraFilterPreset,
  saveInfraFilterPreset
} from '../../api/infra'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { useTranslation } from 'react-i18next'
import { ActionsFilterType, BasicFilterType, FilterMenu, Filters } from '../shared/filters/Filters'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'

interface Props {
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedRegions: FilterOption[]
  setSelectedRegions: (selectedRegions: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedResourceGroups: FilterOption[]) => void
  selectedResources: FilterOption[]
  setSelectedResources: (selectedResources: FilterOption[]) => void
}

export const InfraFilters = ({
  selectedVendors,
  selectedRegions,
  selectedProjects,
  selectedResourceGroups,
  selectedResources,
  setSelectedVendors,
  setSelectedRegions,
  setSelectedProjects,
  setSelectedResourceGroups,
  setSelectedResources
}: Props) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [initFilterData, setInitFilterData] = useState<InfraFilterData | null>(null)
  const [filterOptions, setFilterOptions] = useState<InfraFilterData | null>()
  const [savedPresets, setSavedPresets] = useState<InfraFilterPreset[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    Promise.all([
      getInfraFilterOptions(cancelToken.token).then(resourceData => {
        setInitFilterData(resourceData)
        setFilterOptions(resourceData)
      }),
      getInfraFilterPresets(cancelToken.token).then(setSavedPresets)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setInitFilterData(null)
      setFilterOptions(null)
      setSavedPresets([])
    }
  }, [createCancelToken, handleError, authInfo])

  useEffect(() => {
    if (selectedVendors.length > 0 && initFilterData) {
      const regionOptions = initFilterData.regionOptions.filter(region =>
        selectedVendors.map(vendor => vendor.value).includes(region.label)
      )
      const projectOptions = initFilterData.projectOptions.filter(project =>
        selectedVendors.map(vendor => vendor.value).includes(project.label)
      )
      const resourceGroupOptions = selectedVendors.map(option => option.value).includes(Vendor.AZURE)
        ? initFilterData.resourceGroupOptions
        : []
      const resourceOptions = initFilterData.resourceOptions
        .filter(resource => selectedVendors.map(vendor => vendor.value).includes(resource.label))
        .map(groupedOption => ({
          ...groupedOption,
          options: groupedOption.options.map(option => ({
            ...option,
            label: option.label
          }))
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      setFilterOptions({
        regionOptions,
        projectOptions,
        resourceGroupOptions,
        resourceOptions
      })
    } else setFilterOptions(initFilterData)
  }, [initFilterData, selectedVendors])

  useEffect(() => {
    if (selectedPreset) {
      const chosenPreset = savedPresets.find(filter => filter.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? [])
      setSelectedRegions(chosenPreset?.regions ?? [])
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedResources(chosenPreset?.resources ?? [])
    }
  }, [selectedPreset])

  const saveFilter = (name: string) => {
    const filter: InfraFilterPreset = {
      name: name,
      vendors: selectedVendors,
      regions: selectedRegions.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      projects: selectedProjects.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      resourceGroups: selectedResourceGroups.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      resources: selectedResources.map(entry => ({
        value: entry.value,
        label: entry.label
      }))
    }
    saveInfraFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setSavedPresets([...savedPresets].filter(preset => preset.name !== savedPreset.name).concat(savedPreset))
        setSelectedPreset({
          label: savedPreset.name,
          value: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deleteFilter = (name: string) => {
    deleteInfraFilter(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setSavedPresets([...savedPresets].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const options: FilterMenu[] = [
    {
      type: BasicFilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projectOptions ?? [],
      onChange: setSelectedProjects
    }
  ]

  if (integrationStates.azure)
    options.push({
      type: BasicFilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroupOptions ?? [],
      onChange: setSelectedResourceGroups
    })

  options.push(
    {
      type: BasicFilterType.REGIONS,
      value: selectedRegions,
      options: filterOptions?.regionOptions ?? [],
      onChange: setSelectedRegions
    },
    {
      type: BasicFilterType.RESOURCE_TYPES,
      value: selectedResources,
      options: filterOptions?.resourceOptions ?? [],
      onChange: setSelectedResources
    },
    {
      type: ActionsFilterType.PRESETS,
      value: selectedPreset ? [selectedPreset] : [],
      options: savedPresets.map(preset => ({
        label: preset.name,
        value: preset.name
      })),
      onChange: (preset: FilterOption) => setSelectedPreset(preset),
      saveAction: saveFilter,
      deleteAction: deleteFilter
    }
  )

  return (
    <Filters
      selectedVendors={selectedVendors}
      setSelectedVendors={setSelectedVendors}
      filterMenuOptions={options}
      loading={loading}
    />
  )
}
