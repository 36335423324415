import React, { useContext, useEffect, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import {
  ActionsFilterType,
  BasicFilterType,
  CurrencyFilterOption,
  FilterMenu,
  Filters
} from '../shared/filters/Filters'
import {
  CostFilterPreset,
  CostsFilterOptions,
  CostsProjectAllocationPercent,
  deleteCostFilter,
  getCostFilterData,
  saveCostFilterPreset
} from '../../api/costs'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { CostNotificationDataKey } from '../notifications/SharedComponents'
import { distinctArray } from '../../utils/formats'
import { UserRole } from '../../api/auth'

interface Props {
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedResourceGroups: FilterOption[]) => void
  selectedServices: FilterOption[]
  setSelectedServices: (selectedServices: FilterOption[]) => void
  selectedTags: FilterOption[]
  setSelectedTags: (selectedTags: FilterOption[]) => void
  selectedCurrency: CurrencyFilterOption
  setSelectedCurrency: (selectedCurrency: CurrencyFilterOption) => void
  selectedRule: FilterOption | null
  setSelectedRule: (rulePreset: FilterOption | null) => void
  allocationPercents: CostsProjectAllocationPercent[]
  setAllocationPercents: (allocationPercents: CostsProjectAllocationPercent[]) => void
}

export const CostFilters = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  setSelectedVendors,
  setSelectedProjects,
  setSelectedResourceGroups,
  setSelectedServices,
  selectedTags,
  setSelectedTags,
  selectedCurrency,
  setSelectedCurrency,
  selectedRule,
  setSelectedRule,
  allocationPercents,
  setAllocationPercents
}: Props) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [initFilterData, setInitFilterData] = useState<CostsFilterOptions | null>(null)
  const [filterOptions, setFilterOptions] = useState<CostsFilterOptions | null>()
  const [savedPresets, setSavedPresets] = useState<CostFilterPreset[]>([])
  const [ruleOptions, setRuleOptions] = useState<FilterOption[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)
  const [selectedRulePreset, setSelectedRulePreset] = useState<FilterOption | null>(selectedRule)
  const storedVendor = sessionStorage.getItem(CostNotificationDataKey.VENDOR)
  const storedProjectId = sessionStorage.getItem(CostNotificationDataKey.PROJECT_ID)
  const storedProjectName = sessionStorage.getItem(CostNotificationDataKey.PROJECT_NAME)
  const storedService = sessionStorage.getItem(CostNotificationDataKey.SERVICE)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getCostFilterData(
      selectedProjects.map(project => project.value),
      cancelToken.token
    )
      .then(filterData => {
        setInitFilterData(filterData.options)
        setFilterOptions(filterData.options)
        setSavedPresets(filterData.presets)
        setRuleOptions(filterData.rules)
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [authInfo, createCancelToken, handleError, selectedProjects.length])

  useEffect(() => {
    if (selectedVendors.length > 0 && initFilterData) {
      const projectOptions = initFilterData.projectOptions.filter(project =>
        selectedVendors.map(vendor => vendor.value).includes(project.label)
      )

      const resourceGroupOptions = selectedVendors.map(vendor => vendor.value).includes(Vendor.AZURE)
        ? initFilterData.resourceGroupOptions
        : []

      const serviceOptions = initFilterData.serviceOptions.filter(service =>
        selectedVendors.map(vendor => vendor.value).includes(service.label)
      )

      const tagOptions = initFilterData.tagOptions.filter(tag =>
        selectedVendors.map(vendor => vendor.value).includes(tag.label)
      )

      setFilterOptions({
        projectOptions,
        resourceGroupOptions,
        serviceOptions,
        tagOptions,
        currencyOptions: initFilterData.currencyOptions
      })
    } else {
      setFilterOptions(initFilterData)
    }
  }, [initFilterData, selectedVendors])

  useEffect(() => {
    if (selectedPreset) {
      selectedRulePreset && setSelectedRulePreset(null)
      const chosenPreset = savedPresets.find(filter => filter.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? [])
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedServices(chosenPreset?.services ?? [])
      setSelectedTags(chosenPreset?.tags ?? [])
    }
  }, [selectedPreset])

  useEffect(() => {
    if (selectedRulePreset) {
      selectedPreset && setSelectedPreset(null)
      const chosenRule = ruleOptions.filter(filter => filter.value === selectedRulePreset.value)[0]
      const ruleVendors = distinctArray(chosenRule.nest?.map(o => o.vendor) ?? [])
      const ruleServices = distinctArray(chosenRule.nest?.flatMap(o => o.nest) ?? [])
      const projectAllocations: CostsProjectAllocationPercent[] =
        chosenRule.nest
          ?.filter(p => p.count)
          .map(
            p =>
              ({
                project: { id: p.value, name: p.label },
                allocationPercent: p.count
              }) as CostsProjectAllocationPercent
          ) ?? []

      setSelectedVendors(
        ruleVendors.map(v => ({
          label: v,
          value: v
        }))
      )
      setSelectedProjects(chosenRule?.nest?.map(p => ({ ...p, nest: [] })) ?? [])
      setSelectedServices(ruleServices)
      setAllocationPercents(projectAllocations)
    } else allocationPercents.length > 0 && setAllocationPercents([])
  }, [selectedRulePreset])

  useEffect(() => {
    if (storedVendor) {
      setSelectedVendors([{ label: storedVendor, value: storedVendor }])
      sessionStorage.removeItem(CostNotificationDataKey.VENDOR)
    }
    if (storedProjectId && storedProjectName) {
      setSelectedProjects([{ label: storedProjectName, value: storedProjectId }])
      sessionStorage.removeItem(CostNotificationDataKey.PROJECT_ID)
      sessionStorage.removeItem(CostNotificationDataKey.PROJECT_NAME)
    }
    if (storedService) {
      setSelectedServices([{ label: storedService, value: storedService }])
      sessionStorage.removeItem(CostNotificationDataKey.SERVICE)
    }
  }, [storedVendor, storedProjectId, storedProjectName, storedService, setSelectedProjects, setSelectedServices])

  const saveFilter = (name: string) => {
    const filter: CostFilterPreset = {
      name: name,
      vendors: selectedVendors,
      projects: selectedProjects.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      resourceGroups: selectedResourceGroups.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      services: selectedServices.map(entry => ({
        value: entry.value,
        label: entry.label
      })),
      tags: selectedTags.map(entry => ({
        vendor: entry.vendor,
        value: entry.value,
        label: entry.label,
        count: entry.nest?.length,
        nest: entry.nest?.map(tag => ({
          vendor: tag.vendor,
          value: tag.value,
          label: tag.label
        }))
      })),
      currency: selectedCurrency
    }
    saveCostFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setSavedPresets([...savedPresets].filter(preset => preset.name !== savedPreset.name).concat(savedPreset))
        setSelectedPreset({
          label: savedPreset.name,
          value: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deleteFilter = (name: string) => {
    deleteCostFilter(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setSavedPresets([...savedPresets].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const clearRulePreset = () => {
    setSelectedRulePreset(null)
    setSelectedVendors([])
    setSelectedProjects([])
    setSelectedResourceGroups([])
    setSelectedServices([])
    setSelectedTags([])
  }

  const options: FilterMenu[] = [
    {
      type: BasicFilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projectOptions ?? [],
      onChange: (selected: FilterOption[]) => {
        selectedRulePreset && clearRulePreset()
        setSelectedProjects(selected)
      }
    }
  ]

  if (integrationStates.azure)
    options.push({
      type: BasicFilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroupOptions ?? [],
      onChange: (selected: FilterOption[]) => {
        selectedRulePreset && clearRulePreset()
        setSelectedResourceGroups(selected)
      }
    })

  options.push(
    {
      type: BasicFilterType.SERVICES,
      value: selectedServices,
      options: filterOptions?.serviceOptions ?? [],
      onChange: (selected: FilterOption[]) => {
        selectedRulePreset && clearRulePreset()
        setSelectedServices(selected)
      }
    },
    {
      type: BasicFilterType.TAGS,
      value: selectedTags,
      options: filterOptions?.tagOptions ?? [],
      onChange: (selected: FilterOption[]) => {
        selectedRulePreset && clearRulePreset()
        setSelectedTags(selected)
      }
    },
    {
      type: BasicFilterType.CURRENCY,
      value: [selectedCurrency],
      options: filterOptions?.currencyOptions ?? [],
      onChange: setSelectedCurrency
    },
    {
      type: ActionsFilterType.PRESETS,
      value: selectedPreset ? [selectedPreset] : [],
      options: savedPresets.map(preset => ({
        label: preset.name,
        value: preset.name
      })),
      onChange: (preset: FilterOption) => setSelectedPreset(preset),
      saveAction: saveFilter,
      deleteAction: deleteFilter
    }
  )

  if (authInfo.roles.includes(UserRole.ADMIN))
    options.push({
      type: BasicFilterType.ADMIN_PRESETS,
      header: t('admin.alerts.costs.heading'),
      value: selectedRulePreset ? [selectedRulePreset] : [],
      options: ruleOptions,
      onChange: (preset: FilterOption) => setSelectedRulePreset(preset)
    })

  return (
    <Filters
      selectedVendors={selectedVendors}
      setSelectedVendors={setSelectedVendors}
      filterMenuOptions={options}
      loading={loading}
    />
  )
}
