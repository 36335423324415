import { CancelToken } from 'axios'
import { client } from '../../client'
import { GcpBillingAccountIntegration, GcpIntegrationsState } from '../../settings/profile'

export const createGcpIntegration = async (
  integrationDetails: GcpIntegrationRequest,
  cancelToken: CancelToken
): Promise<GcpIntegrationsState> => {
  return await client
    .post('admin/integrations/gcp', integrationDetails, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const deleteGcpIntegration = async (organizationId: string): Promise<GcpIntegrationsState[]> => {
  return await client.delete(`admin/integrations/gcp/${organizationId}`)
}

export const recoverGcpIntegration = async (organizationId: string): Promise<GcpIntegrationsState[]> => {
  return await client.post(`admin/integrations/gcp/${organizationId}/recover`)
}

export const editGcpIntegration = async (
  organizationId: string,
  name: string,
  cancelToken: CancelToken
): Promise<GcpIntegrationsState> => {
  return await client
    .put<GcpIntegrationsState>(
      `admin/integrations/gcp/${organizationId}`,
      { name: name },
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const markGcpIntegrationDismissed = async (
  integration: GcpIntegrationsState,
  cancelToken: CancelToken
): Promise<GcpIntegrationsState> => {
  return await client
    .put<GcpIntegrationsState>(`admin/integrations/gcp/${integration.organizationId}/dismiss`, integration, {
      cancelToken
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const addGcpBillingAccount = async (
  integrationDetails: GcpIntegrationRequest,
  cancelToken: CancelToken
): Promise<GcpIntegrationsState> => {
  return await client
    .post<GcpIntegrationsState>(
      `admin/integrations/gcp/${integrationDetails.organizationId}/${integrationDetails.accountId}`,
      integrationDetails,
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const editGcpBillingAccount = async (
  organizationId: string,
  billingAccount: GcpBillingAccountIntegration,
  cancelToken: CancelToken
): Promise<GcpIntegrationsState> => {
  return await client
    .put<GcpIntegrationsState>(`admin/integrations/gcp/${organizationId}/${billingAccount.accountId}`, billingAccount, {
      cancelToken
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteGcpBillingAccount = async (
  organizationId: string,
  billingAccount: GcpBillingAccountIntegration
): Promise<GcpIntegrationsState> => {
  return await client
    .delete(`admin/integrations/gcp/${organizationId}/${billingAccount.accountId}`, {
      data: billingAccount
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const recoverGcpBillingAccount = async (
  organizationId: string,
  billingAccount: GcpBillingAccountIntegration,
  cancelToken: CancelToken
): Promise<GcpIntegrationsState> => {
  return await client
    .post(`admin/integrations/gcp/${organizationId}/${billingAccount.accountId}/recover`, billingAccount, {
      cancelToken
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

const getDateFormattedResponse = (resp: GcpIntegrationsState): GcpIntegrationsState => ({
  ...resp,
  lastOrganizationIntegrationAt: resp.lastOrganizationIntegrationAt && new Date(resp.lastOrganizationIntegrationAt),
  compliance: {
    ...resp.compliance,
    lastIntegrationAt: resp.compliance.lastIntegrationAt && new Date(resp.compliance.lastIntegrationAt)
  },
  recommendation: {
    ...resp.recommendation,
    lastIntegrationAt: resp.recommendation.lastIntegrationAt && new Date(resp.recommendation.lastIntegrationAt)
  },
  billingAccounts: resp.billingAccounts.map(billingAccount => ({
    ...billingAccount,
    billing: {
      ...billingAccount.billing,
      lastIntegrationAt: billingAccount.billing.lastIntegrationAt && new Date(billingAccount.billing.lastIntegrationAt)
    },
    usage: {
      ...billingAccount.usage,
      lastIntegrationAt: billingAccount.usage.lastIntegrationAt && new Date(billingAccount.usage.lastIntegrationAt)
    },
    deletedAt: billingAccount.deletedAt && new Date(billingAccount.deletedAt)
  })),
  deletedAt: resp.deletedAt && new Date(resp.deletedAt)
})

export interface GcpIntegrationRequest {
  organizationId: string
  name: string
  accountId: string
  projectId: string
  tableId: string
}
