import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationTimestampsSection
} from '../Shared'
import { AwsAccountType } from './AwsIntegrationDetails'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formatDate } from '../../../../utils/formats'
import { AdminTabContent } from '../../adminStyles'
import { SpotterDocumentationLink } from '../../../login/Shared'
import { CapitalizedText } from '../../../shared/TextComponents'
import { AwsIntegrationsState, AwsSecurityAccountIntegration } from '../../../../api/settings/profile'
import { CustomIcon } from '../../../shared/CustomIcon'
import deletedIcon from '../../../../assets/svg/symbols/x-heavy.svg'

interface AwsIntegrationTimeBlocksProps {
  selectedIntegration: AwsIntegrationsState
  dismissError: (selectedIntegration: AwsIntegrationsState) => void
}

export const AwsIntegrationTimestamps = ({ selectedIntegration, dismissError }: AwsIntegrationTimeBlocksProps) => {
  return (
    <>
      <MasterAccountSection selectedIntegration={selectedIntegration} dismissError={dismissError} />
      <SecurityAccountSection selectedIntegration={selectedIntegration} dismissError={dismissError} />
      <ConfigAccountSection selectedIntegration={selectedIntegration} dismissError={dismissError} />
    </>
  )
}

interface AccountSectionProps {
  selectedIntegration: AwsIntegrationsState
  dismissError: (selectedIntegration: AwsIntegrationsState) => void
}

const MasterAccountSection = ({ selectedIntegration, dismissError }: AccountSectionProps) => {
  const { t } = useTranslation()
  const organizationError = !selectedIntegration.lastOrganizationIntegrationAt && !selectedIntegration.deletedAt
  const billingError = !selectedIntegration.billing.lastIntegrationAt && !selectedIntegration.deletedAt
  const recommendationError = !selectedIntegration.recommendation.lastIntegrationAt && !selectedIntegration.deletedAt
  const sustainabilityError = !selectedIntegration.usage.lastIntegrationAt && !selectedIntegration.deletedAt
  const errorSolutions = []
  organizationError &&
    errorSolutions.push(
      <Trans
        key={'organizationError'}
        i18nKey='admin.integrations.aws.missingOrganizationData'
        components={{
          CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
        }}
      />
    )
  sustainabilityError &&
    errorSolutions.push(
      <CapitalizedText key={'sustainabilityError'}>{t('admin.integrations.aws.missingUsageData')}</CapitalizedText>
    )

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })} />
          <IntegrationDetailRowsWrapper
            hasActions={false}
            detailRows={
              <>
                <IntegrationDetailRow
                  label={t('admin.integrations.organization')}
                  value={formatDate(selectedIntegration.lastOrganizationIntegrationAt, true, true, true)}
                  error={organizationError}
                />
                <IntegrationDetailRow
                  label={t('sideNav.costs')}
                  value={formatDate(selectedIntegration.billing.lastIntegrationAt, true, true, true)}
                  error={billingError && !selectedIntegration.billing.dismissed}
                  showErrorToggle={billingError}
                  setHasError={() =>
                    billingError &&
                    dismissError({
                      ...selectedIntegration,
                      billing: {
                        ...selectedIntegration.billing,
                        dismissed: !selectedIntegration.billing.dismissed
                      }
                    })
                  }
                />
                <IntegrationDetailRow
                  label={t('sideNav.optimization')}
                  value={formatDate(selectedIntegration.recommendation.lastIntegrationAt, true, true, true)}
                  error={recommendationError && !selectedIntegration.recommendation.dismissed}
                  showErrorToggle={recommendationError}
                  setHasError={() =>
                    recommendationError &&
                    dismissError({
                      ...selectedIntegration,
                      recommendation: {
                        ...selectedIntegration.recommendation,
                        dismissed: !selectedIntegration.recommendation.dismissed
                      }
                    })
                  }
                />
                <IntegrationDetailRow
                  label={t('sideNav.sustainability')}
                  value={formatDate(selectedIntegration.usage.lastIntegrationAt, true, true, true)}
                  error={sustainabilityError && !selectedIntegration.usage.dismissed}
                  showErrorToggle={sustainabilityError}
                  setHasError={() =>
                    sustainabilityError &&
                    dismissError({
                      ...selectedIntegration,
                      usage: {
                        ...selectedIntegration.usage,
                        dismissed: !selectedIntegration.usage.dismissed
                      }
                    })
                  }
                />
              </>
            }
          />
        </AdminTabContent>
      }
      errorSolutions={errorSolutions}
    />
  )
}

const SecurityAccountSection = ({ selectedIntegration, dismissError }: AccountSectionProps) => {
  const { t } = useTranslation()
  const [collapsedSecurityAccounts, setCollapsedSecurityAccounts] = useState<number[]>([])
  const hasComplianceError = (account: AwsSecurityAccountIntegration) =>
    !account.deletedAt && !account.lastIntegrationAt

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.SECURITY })} />
          {selectedIntegration.compliance.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.compliance')}
                    value={formatDate(account.lastIntegrationAt, true, true, true)}
                    error={hasComplianceError(account)}
                    showErrorToggle={hasComplianceError(account) && !account.dismissed}
                    setHasError={() =>
                      hasComplianceError(account) &&
                      dismissError({
                        ...selectedIntegration,
                        compliance: selectedIntegration.compliance.map(a =>
                          a.accountId === account.accountId && a.region === account.region
                            ? { ...a, dismissed: !a.dismissed }
                            : a
                        )
                      })
                    }
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedSecurityAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedSecurityAccounts.includes(index)
                        ? setCollapsedSecurityAccounts([...collapsedSecurityAccounts, index])
                        : setCollapsedSecurityAccounts(collapsedSecurityAccounts.filter(item => item !== index))
                    }
                    value={account.accountId}
                  />
                  {collapsedSecurityAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        selectedIntegration.compliance.some(account => !account.lastIntegrationAt && !account.dismissed) && (
          <Trans
            i18nKey='admin.integrations.aws.missingComplianceData'
            components={{
              CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
            }}
          />
        )
      }
    />
  )
}

const ConfigAccountSection = ({ selectedIntegration }: AccountSectionProps) => {
  const { t } = useTranslation()
  const [collapsedConfigAccounts, setCollapsedConfigAccounts] = useState<number[]>([])

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.aws.account', { type: AwsAccountType.CONFIG })} />
          {selectedIntegration.resources.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.infra')}
                    value={formatDate(account.lastIntegrationAt, true, true, true)}
                    error={!account.lastIntegrationAt && !account.deletedAt}
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedConfigAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedConfigAccounts.includes(index)
                        ? setCollapsedConfigAccounts([...collapsedConfigAccounts, index])
                        : setCollapsedConfigAccounts(collapsedConfigAccounts.filter(item => item !== index))
                    }
                    value={
                      <div className={'flex gap-2 items-center'}>
                        {account.accountId}
                        {account.deletedAt && <CustomIcon path={deletedIcon} styles={'bg-primary-600 w-4 h-4'} />}
                      </div>
                    }
                  />
                  {collapsedConfigAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
                      <IntegrationDetailRow
                        label={t('admin.integrations.aws.aggregatorName')}
                        value={account.aggregatorName}
                      />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        selectedIntegration.resources.some(account => !account.lastIntegrationAt && !account.dismissed) && (
          <Trans
            i18nKey='admin.integrations.aws.missingOrganizationData'
            components={{
              CustomLink: <SpotterDocumentationLink path={'aws-access-management'} />
            }}
          />
        )
      }
    />
  )
}
