import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from '../CustomIcon'
import close from '../../../assets/svg/actions/close-heavy.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { InputHTMLAttributes, useRef, useState } from 'react'
import searchIcon from '../../../assets/svg/actions/search.svg'
import emailIcon from '../../../assets/svg/symbols/email.svg'
import passwordIcon from '../../../assets/svg/objects/key.svg'
import penIcon from '../../../assets/svg/actions/edit.svg'
import textIcon from '../../../assets/svg/symbols/text.svg'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { CapitalizedText, GrayText } from '../TextComponents'
import { capitalizeFirstLetter } from '../../../utils/formats'

export enum InputType {
  SEARCH,
  EMAIL,
  PASSWORD,
  EDIT,
  TEXT
}

export type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> & {
  type: InputType
  id?: string
  setInputValue?: (text: string) => void
  size?: 'small' | 'default'
} & (
    | {
        useTooltip: true
        tooltipText: string
        tooltipPosition: {
          x: number
          y: number
        }
      }
    | {
        useTooltip?: false
        tooltipText?: never
        tooltipPosition?: never
      }
  )

export const TextInput = ({
  type,
  id,
  placeholder,
  setInputValue,
  size = 'default',
  disabled,
  useTooltip = false,
  tooltipPosition,
  tooltipText,
  value,
  autoComplete,
  autoFocus
}: TextInputProps) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [focused, setFocused] = useState(!!autoFocus)
  const inputId = `${id}-input-${type}`

  const displayTooltip = () => {
    setShowTooltip(true)
    setTimeout(() => setShowTooltip(false), 5000)
  }

  const icon = [
    { type: InputType.SEARCH, path: searchIcon },
    { type: InputType.EMAIL, path: emailIcon },
    { type: InputType.PASSWORD, path: passwordIcon },
    { type: InputType.EDIT, path: penIcon },
    { type: InputType.TEXT, path: textIcon }
  ]

  return (
    <>
      <InputContainer
        ref={ref}
        size={size}
        onMouseMove={() => displayTooltip()}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => document.getElementById(inputId)?.focus()}
        focused={focused}
        disabled={disabled}
        className={'group'}
      >
        <CustomIcon
          styles={`w-[18px] h-[18px] ${focused ? 'bg-gray-200' : 'bg-gray-300/90'} ${!disabled && 'group-hover:bg-gray-200'}`}
          path={icon[type].path}
        />
        <div className={`flex items-center gap-1.5 w-full`}>
          <input
            id={inputId}
            type={type === InputType.EMAIL ? 'email' : type === InputType.PASSWORD ? 'password' : 'text'}
            placeholder={placeholder ? capitalizeFirstLetter(placeholder) : t('common.search')}
            disabled={disabled}
            onChange={event => setInputValue && setInputValue(event.target.value)}
            value={value}
            className={'w-full outline-none'}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
          />
          <CustomIcon
            styles={`w-4 h-4 ${disabled || value === '' ? 'bg-transparent' : 'bg-gray-300 hover:bg-gray-50'}`}
            iconType={IconType.VECTOR}
            path={close}
            onClick={() => !disabled && setInputValue && setInputValue('')}
          />
        </div>
      </InputContainer>
      {useTooltip && tooltipPosition && showTooltip && (
        <InfoTooltip x={tooltipPosition.x} y={tooltipPosition.y}>
          <CapitalizedText>{tooltipText}</CapitalizedText>
        </InfoTooltip>
      )}
    </>
  )
}

interface ContainerProps {
  size: 'small' | 'default'
  focused?: boolean
  disabled?: boolean
}

const InputContainer = styled.div<ContainerProps>`
  ${tw`flex items-center text-gray-100 justify-center h-fit w-full max-w-120 transition-all ease-in-out duration-300 cursor-pointer bg-gray-700/60 border rounded gap-2 py-2 px-3 border-gray-400/60 hover:shadow-input-focus`}
  ${({ size }) => size === 'small' && tw`py-1.5 px-2 min-w-[34px]`}
  ${({ focused }) => focused && tw`border-gray-400 shadow-input-focus`}
  ${({ disabled }) => disabled && tw`bg-gray-700/20 cursor-not-allowed hover:shadow-none`}
  input {
    ${tw`bg-transparent`}
    ${({ size }) => size === 'small' && tw`text-90`}
    ${({ disabled }) => disabled && tw`cursor-not-allowed`}
  }

  input::placeholder {
    ${tw`text-gray-300 leading-tight`}
  }
`

interface LabeledSearchInputProps {
  label: string
  placeholder: string
  searchText: string
  setSearchText: (searchText: string) => void
}

export const LabeledSearchInput = ({ label, placeholder, searchText, setSearchText }: LabeledSearchInputProps) => {
  return (
    <div>
      <GrayText className={'text-90'}>{label}</GrayText>
      <TextInput type={InputType.SEARCH} placeholder={placeholder} value={searchText} setInputValue={setSearchText} />
    </div>
  )
}
