import { useTranslation } from 'react-i18next'
import { FullScreenContainer } from '../shared/layout/FullScreenPage'
import { FullScreenLogoContainer, SpotterLogo } from '../shared/layout/Logo'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { SpotterDocumentationLink } from './Shared'
import { Env } from '../../utils/env'

interface FrontPageProps {
  children?: JSX.Element | JSX.Element[]
}

export const FrontPageContainer = ({ children }: FrontPageProps) => {
  return (
    <FullScreenContainer>
      <div className={'flex flex-col justify-center items-center h-full w-100 gap-12 -mb-12 lg:-mb-24'}>
        <ContentWrapper>
          <FullScreenLogoContainer>
            <SpotterLogo animated={true} />
          </FullScreenLogoContainer>
          {children}
        </ContentWrapper>
      </div>
      <Footer />
    </FullScreenContainer>
  )
}

const ContentWrapper = styled.div`
  ${tw`flex flex-col justify-center items-center max-w-120 px-12 py-16`}
  input {
    ${tw`flex w-80`}
  }
`

const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterWrapper>
      <a href={'http://cloud2.fi/spotter'} target={'_blank'} rel={'noreferrer'}>
        {t('login.learnMore')}
      </a>
      <SpotterDocumentationLink capitalize={true} />
      {process.env.REACT_APP_ENV === Env.PROD && (
        <a href={'mailto:anton.floor@cloud2.fi?subject=Spotter'}>{t('footer.contactUs')}</a>
      )}
      <div>{t('footer.message')}</div>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  ${tw`flex justify-center items-center text-center flex-wrap w-max gap-x-12 gap-y-6 bg-gray-800 w-screen p-10 text-90 text-gray-300 shadow-inner-xl text-90 lg:py-12 xl:gap-24`}
  a {
    ${tw`font-normal text-gray-300 hover:text-primary-300 first-letter:capitalize`}
  }
`
