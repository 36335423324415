import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { CustomIcon } from '../CustomIcon'
import zeroIcon from '../../../assets/svg/symbols/zero-icon.svg'
import checkIcon from '../../../assets/svg/symbols/check-heavy.svg'

export interface RadioButtonProps {
  checked: boolean
  disabled?: boolean
  onChange: () => void
  label: React.ReactNode
  description?: string
  tooltipText?: string
  fullWidth?: boolean
  type?: 'small' | 'default' | 'filter' | 'secondary' | 'container'
}

export const RadioButton = ({
  checked,
  disabled,
  onChange,
  label,
  description,
  tooltipText,
  fullWidth,
  type = 'default'
}: RadioButtonProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const mousePos = useMousePosition()

  if (type === 'container')
    return (
      <div
        className={`flex gap-3 items-center justify-between px-5 py-2.5 rounded transition-all ease duration-200 cursor-pointer ${checked ? 'border border-gray-50/90 shadow-sm' : 'border border-gray-50/20 hover:border-gray-50/60'}`}
        onClick={onChange}
      >
        {label}
        <CustomIcon path={checkIcon} styles={`w-4 h-4 ${checked ? 'bg-gray-50' : 'bg-transparent'}`} />
      </div>
    )

  if (type === 'secondary')
    return (
      <div
        className={`group flex w-full items-center gap-3 ${!disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
        onClick={onChange}
      >
        {checked ? (
          <CustomIcon path={checkIcon} styles={'w-4 h-4 bg-primary-400 scale-105'} />
        ) : (
          <CustomIcon
            path={zeroIcon}
            styles={`w-3.5 h-3.5 mr-0.5 ${disabled ? 'bg-gray-300/60' : 'bg-gray-300'} ${!disabled && 'group-hover:bg-gray-50/80'}`}
          />
        )}
        <div
          className={`first-letter:capitalize text-90 ${checked ? 'text-gray-50' : 'text-gray-50/80'} ${disabled ? 'text-gray-200/60' : !checked && 'group-hover:text-gray-50/80'}`}
        >
          {label}
        </div>
      </div>
    )

  return (
    <Container
      checked={checked}
      disabled={disabled}
      fullWidth={fullWidth}
      type={type}
      onClick={disabled !== true ? (!checked ? onChange : undefined) : () => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <input readOnly type={'radio'} checked={checked} />
      <div>
        <Value disabled={disabled}>{label}</Value>
        {description && <Description disabled={disabled}>{description}</Description>}
      </div>
      {showTooltip && tooltipText && (
        <InfoTooltip x={mousePos.x} y={mousePos.y}>
          {tooltipText}
        </InfoTooltip>
      )}
    </Container>
  )
}

interface ContainerProps {
  checked: boolean
  disabled?: boolean
  fullWidth?: boolean
  type?: 'small' | 'default' | 'filter' | 'secondary' | 'container'
}

const Container = styled.div<ContainerProps>`
  ${tw`flex gap-4 items-start w-fit cursor-pointer`}
  ${({ checked }) => checked && tw`cursor-default`}
  ${({ disabled }) => disabled && tw`cursor-not-allowed`}
  ${({ fullWidth, type }) => (type === 'filter' || fullWidth) && tw`w-full`}
  input {
    ${tw`mt-1 appearance-none border border-gray-400 rounded-full cursor-pointer checked:border-primary-500 checked:border-4 checked:bg-gray-50`}
    ${({ checked }) => checked && tw`cursor-default`}
    ${({ disabled }) => disabled && tw`cursor-not-allowed`}
    ${({ type }) => (type !== 'default' ? tw`min-w-4 w-4 h-4` : tw`min-w-5 w-5 h-5`)}
  }
`

interface ValueProps {
  disabled?: boolean
}

const Value = styled.div<ValueProps>`
  ${tw`first-letter:capitalize text-gray-50`}
  ${({ disabled }) => disabled && tw`text-gray-200`}
`

const Description = styled(Value)`
  ${tw`text-gray-200`}
  ${({ disabled }) => disabled && tw`text-gray-300`}
`
