import { ToggleGroup, ToggleGroupOption } from '../buttons/ToggleGroup'
import { CapitalizedText } from '../TextComponents'
import React from 'react'
import { capitalizeFirstLetter } from '../../../utils/formats'
import { BasicFilterType } from './Filters'

export interface FilterToggleProps {
  type: BasicFilterType
  header?: string
  options: ToggleGroupOption[]
}

export const FilterToggle = ({ type, header, options }: FilterToggleProps) => {
  return (
    <div className={'flex flex-col w-full gap-1'}>
      <div className={'flex gap-2 items-center'}>
        <div className={'bg-primary-300 w-2.5 h-2.5 rounded-full'} />
        <CapitalizedText className={'text-90 text-gray-100'}>{header || capitalizeFirstLetter(type)}</CapitalizedText>
      </div>
      <ToggleGroup minWidth={'13rem'} maxWidth={'14rem'} buttons={options} />
    </div>
  )
}
