import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader,
  IntegrationTimestampsSection
} from '../Shared'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { formatDate } from '../../../../utils/formats'
import { AdminTabContent } from '../../adminStyles'
import {
  GcpBillingAccountIntegration,
  GcpIntegrationsState,
  getIntegrationStates
} from '../../../../api/settings/profile'
import { useCancelToken } from '../../../../api/client'
import { useErrorHandling } from '../../../../hooks/handleError'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { markGcpIntegrationDismissed } from '../../../../api/admin/integrations/gcp'
import { MissingDataNotification } from '../../../shared/MissingDataNotification'
import { CustomIcon } from '../../../shared/CustomIcon'
import deletedIcon from '../../../../assets/svg/symbols/x-heavy.svg'

interface GcpIntegrationTimestampsProps {
  selectedIntegration: GcpIntegrationsState
  setSelectedIntegration: (integration: GcpIntegrationsState | null) => void
}

export const GcpIntegrationTimestamps = ({
  selectedIntegration,
  setSelectedIntegration
}: GcpIntegrationTimestampsProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { integrationStates, setIntegrationStates } = useContext(UserInfoContext)

  useEffect(() => {
    setSelectedIntegration(
      integrationStates.gcp?.find(integration => integration.organizationId === selectedIntegration.organizationId) ||
        null
    )
  }, [integrationStates, selectedIntegration])

  const dismissErrorAction = (integration: GcpIntegrationsState) => {
    const cancelToken = createCancelToken()
    markGcpIntegrationDismissed(integration, cancelToken.token)
      .then(resp => {
        setSelectedIntegration(resp)
        getIntegrationStates(cancelToken.token).then(setIntegrationStates).catch(handleError)
      })
      .catch(handleError)
  }

  if (!selectedIntegration) return <MissingDataNotification />

  return (
    <>
      <OrganizationSection selectedIntegration={selectedIntegration} dismissErrorAction={dismissErrorAction} />
      <BillingAccountsSection selectedIntegration={selectedIntegration} dismissErrorAction={dismissErrorAction} />
    </>
  )
}

interface SectionProps {
  selectedIntegration: GcpIntegrationsState
  dismissErrorAction: (integration: GcpIntegrationsState) => void
}

const OrganizationSection = ({ selectedIntegration, dismissErrorAction }: SectionProps) => {
  const { t } = useTranslation()

  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.gcp.organization')} />
          <IntegrationDetailRowsWrapper
            hasActions={false}
            detailRows={
              <>
                <IntegrationDetailRow
                  label={t('admin.integrations.organization')}
                  value={formatDate(selectedIntegration.lastOrganizationIntegrationAt, true, true, true)}
                  error={!selectedIntegration.lastOrganizationIntegrationAt && !selectedIntegration.deletedAt}
                />
                <IntegrationDetailRow
                  label={t('sideNav.optimization')}
                  value={formatDate(selectedIntegration.recommendation.lastIntegrationAt, true, true, true)}
                  error={
                    !selectedIntegration.recommendation.lastIntegrationAt &&
                    !selectedIntegration.recommendation.dismissed &&
                    !selectedIntegration.deletedAt
                  }
                  showErrorToggle={
                    !selectedIntegration.recommendation.lastIntegrationAt && !selectedIntegration.deletedAt
                  }
                  setHasError={() => {
                    dismissErrorAction({
                      ...selectedIntegration,
                      recommendation: {
                        lastIntegrationAt: selectedIntegration.recommendation.lastIntegrationAt,
                        dismissed: !selectedIntegration.recommendation?.dismissed
                      }
                    })
                  }}
                />
                <IntegrationDetailRow
                  label={t('sideNav.compliance')}
                  value={formatDate(selectedIntegration.compliance.lastIntegrationAt, true, true, true)}
                  error={
                    !selectedIntegration.compliance.lastIntegrationAt &&
                    !selectedIntegration.compliance.dismissed &&
                    !selectedIntegration.deletedAt
                  }
                  showErrorToggle={!selectedIntegration.compliance.lastIntegrationAt && !selectedIntegration.deletedAt}
                  setHasError={() => {
                    dismissErrorAction({
                      ...selectedIntegration,
                      compliance: {
                        lastIntegrationAt: selectedIntegration.compliance.lastIntegrationAt,
                        dismissed: !selectedIntegration.compliance?.dismissed
                      }
                    })
                  }}
                />
                <IntegrationDetailRow
                  label={t('sideNav.infra')}
                  value={formatDate(selectedIntegration.lastOrganizationIntegrationAt, true, true, true)}
                  error={!selectedIntegration.lastOrganizationIntegrationAt && !selectedIntegration.deletedAt}
                />
              </>
            }
          />
        </AdminTabContent>
      }
      errorSolutions={false}
    />
  )
}

const BillingAccountsSection = ({ selectedIntegration, dismissErrorAction }: SectionProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)
  const [collapsedAccounts, setCollapsedAccounts] = useState<number[]>([])
  const isActive = (account: GcpBillingAccountIntegration) => !account.deletedAt && !selectedIntegration.deletedAt
  return (
    <IntegrationTimestampsSection
      timestampComponent={
        <AdminTabContent>
          <IntegrationDetailsHeader header={t('admin.integrations.gcp.billingAccount_other')} />
          {selectedIntegration.billingAccounts.map((account, index) => (
            <IntegrationDetailRowsWrapper
              key={index}
              hasActions={false}
              detailRows={
                <>
                  <IntegrationDetailRow
                    label={t('sideNav.costs')}
                    value={formatDate(account.billing.lastIntegrationAt, true, true, true)}
                    error={!account.billing.lastIntegrationAt && !account.billing.dismissed && isActive(account)}
                    showErrorToggle={!account.billing.lastIntegrationAt && isActive(account)}
                    setHasError={() => {
                      dismissErrorAction &&
                        dismissErrorAction({
                          ...selectedIntegration,
                          billingAccounts: selectedIntegration.billingAccounts.map(acc =>
                            JSON.stringify(acc) === JSON.stringify(account)
                              ? {
                                  ...acc,
                                  billing: {
                                    lastIntegrationAt: acc.billing.lastIntegrationAt,
                                    dismissed: !acc.billing?.dismissed
                                  }
                                }
                              : acc
                          )
                        })
                    }}
                  />
                  <IntegrationDetailRow
                    label={t('sideNav.sustainability')}
                    value={formatDate(account.usage.lastIntegrationAt, true, true, true)}
                    error={!account.usage.lastIntegrationAt && !account.usage.dismissed && isActive(account)}
                    showErrorToggle={!account.usage.lastIntegrationAt && isActive(account)}
                    setHasError={() => {
                      dismissErrorAction &&
                        dismissErrorAction({
                          ...selectedIntegration,
                          billingAccounts: selectedIntegration.billingAccounts.map(acc =>
                            JSON.stringify(acc) === JSON.stringify(account)
                              ? {
                                  ...acc,
                                  usage: {
                                    lastIntegrationAt: acc.usage.lastIntegrationAt,
                                    dismissed: !acc.usage?.dismissed
                                  }
                                }
                              : acc
                          )
                        })
                    }}
                  />
                  <IntegrationDetailRow
                    label={t('admin.integrations.accountId')}
                    isCollapsed={collapsedAccounts.includes(index)}
                    collapseHandler={() =>
                      !collapsedAccounts.includes(index)
                        ? setCollapsedAccounts([...collapsedAccounts, index])
                        : setCollapsedAccounts(collapsedAccounts.filter(item => item !== index))
                    }
                    value={
                      <div className={'flex gap-2 items-center'}>
                        {account.accountId}
                        {account.deletedAt && <CustomIcon path={deletedIcon} styles={'bg-primary-600 w-4 h-4'} />}
                      </div>
                    }
                  />
                  {collapsedAccounts.includes(index) && (
                    <>
                      <IntegrationDetailRow label={t('admin.integrations.gcp.projectId')} value={account.projectId} />
                      <IntegrationDetailRow
                        label={t('admin.integrations.gcp.bigQueryTableId')}
                        value={account.tableId}
                      />
                      <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
                    </>
                  )}
                </>
              }
            />
          ))}
        </AdminTabContent>
      }
      errorSolutions={
        integrationStates.gcp?.find(
          i =>
            i.organizationId === selectedIntegration.organizationId &&
            !selectedIntegration.deletedAt &&
            i.billingAccounts.some(
              a =>
                !a.deletedAt &&
                ((!a.billing.lastIntegrationAt && !a.billing.dismissed) ||
                  (!a.usage.lastIntegrationAt && !a.usage.dismissed))
            )
        ) && t('admin.integrations.gcp.missingCostAndUsageData')
      }
    />
  )
}
