import React, { useState } from 'react'
import { CustomIcon, IconType } from '../CustomIcon'
import arrow from '../../../assets/svg/directional/arrow-heavy.svg'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { CountUp } from '../CountUp'
import { formatNumber, roundNumber } from '../../../utils/formats'

interface ChangeIndicatorProps {
  id?: string
  change: number | null
  tooltip?: string
  bold?: boolean
  counter?: boolean
  invertColors?: boolean
}

export const ChangeIndicator = ({ id, change, bold, counter, tooltip, invertColors }: ChangeIndicatorProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const mousePos = useMousePosition()

  if (change === null) {
    return <div className={`text-gray-50 ${bold ? 'font-bold' : 'font-semibold'}`}>-</div>
  }

  const color =
    change === 0
      ? 'tertiary-500'
      : !invertColors
        ? change > 0
          ? 'primary-500'
          : 'success-100'
        : change > 0
          ? 'success-100'
          : 'primary-500'
  const styles = `flex gap-1 items-center ${bold ? 'font-bold' : 'font-semibold'} text-${color}`

  return (
    <>
      <div
        className={styles}
        onMouseMove={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {change === 0 ? (
          ''
        ) : (
          <CustomIcon
            iconType={IconType.VECTOR}
            path={arrow}
            styles={`bg-${color} w-4 h-4 ${change < 0 && 'rotate-180'}`}
          />
        )}
        {counter ? (
          <CountUp id={id} countTo={roundNumber(Math.abs(change), 0)} />
        ) : (
          <span id={id}>{formatNumber(roundNumber(Math.abs(change), 0))}</span>
        )}
        %
      </div>
      {tooltip && showTooltip && (
        <InfoTooltip x={mousePos.x - 112} y={mousePos.y + 14}>
          {tooltip}
        </InfoTooltip>
      )}
    </>
  )
}
