import { CustomIcon } from './CustomIcon'
import clockIcon from '../../assets/svg/objects/timer-heavy.svg'
import React from 'react'

export const ClockTimestamp = ({ dateString }: { dateString: string }) => {
  return (
    <div className={'flex items-end gap-1.5 justify-self-end'}>
      <CustomIcon path={clockIcon} styles={'bg-gray-300 w-4 h-4'} />
      <div className={'text-80 leading-none text-gray-300'}>{dateString}</div>
    </div>
  )
}
