import serviceRequest from '../../assets/svg/flat-colored/service-request.svg'
import incident from '../../assets/svg/flat-colored/incident.svg'
import majorIncident from '../../assets/svg/flat-colored/fire.svg'
import open from '../../assets/svg/actions/open-in-new-tab.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useContext, useState } from 'react'
import { FreshTicket, TicketPriority, TicketStatus, TicketType } from '../../api/tickets'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import { GrayText, Heading4, WhiteText } from '../shared/TextComponents'
import { formatDate } from '../../utils/formats'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { TextBadge } from '../shared/indicators/TextBadge'

interface TableProps {
  tickets: FreshTicket[]
}

export const TicketsTable = ({ tickets }: TableProps) => {
  const { t } = useTranslation()

  if (!tickets.length)
    return (
      <TicketSummary>
        <MissingDataNotification paddingY={6} justify={'start'} displayText={t('tickets.noTickets')} />
      </TicketSummary>
    )

  return (
    <Table>
      {tickets.map(ticket => (
        <Ticket key={ticket.ticketId} ticket={ticket} />
      ))}
    </Table>
  )
}

const Table = styled.div`
  ${tw`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-x-3 gap-y-4`}
`

interface TicketProps {
  ticket: FreshTicket
}

const Ticket = ({ ticket }: TicketProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const [selected, setSelected] = useState<number[]>([])
  const isOpen = selected.includes(ticket.ticketId)
  const ticketDomain = authInfo.emailAddress.includes('@cloud2.fi') ? 'cloud2oy.freshservice.com' : ticket.domain

  return (
    <div>
      <TicketSummary
        onClick={() =>
          !selected?.includes(ticket.ticketId)
            ? setSelected([...selected, ticket.ticketId])
            : setSelected(selected.filter(id => id !== ticket.ticketId))
        }
      >
        <div className={'flex flex-row items-center w-full gap-4 justify-between'}>
          <div className={'flex gap-2 items-center'}>
            <TicketTypeIcon type={ticket.type} priority={ticket.priority} />
            <WhiteText>{ticket.type}</WhiteText>
          </div>
          <TextBadge value={ticket.priority} />
        </div>
        <div className={'flex gap-5 items-center justify-between w-full'}>
          <Heading4>{ticket.subject}</Heading4>
          {ticket.domain && (
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href={`https://${ticketDomain}/helpdesk/tickets/${ticket.ticketId}`}
            >
              <CustomIcon
                iconType={IconType.VECTOR}
                path={open}
                styles={'w-4 h-4 bg-gray-50'}
                tooltipText={t('tickets.ticketDetails.openFreshTicket')}
                tooltipStyles={'min-w-max'}
              />
            </a>
          )}
        </div>
        <Description className={'self-start font-light'}>
          {ticket.description.substring(0, 100)}
          {'...'}
        </Description>
      </TicketSummary>
      <TicketDetails ticket={ticket} isOpen={isOpen} />
    </div>
  )
}

export const TicketContainer = styled.div`
  ${tw`px-5 sm:px-8`}
`

const TicketSummary = styled(TicketContainer)`
  ${tw`flex flex-col w-full gap-6 bg-gray-600/90 hover:bg-gray-600 items-center rounded-md cursor-pointer py-6`}
`

interface TicketDetailsProps {
  ticket: FreshTicket
  isOpen: boolean
}

const TicketDetails = ({ ticket, isOpen }: TicketDetailsProps) => {
  const { t } = useTranslation()
  const hasDueDate =
    ticket.status === TicketStatus.OPEN ||
    ticket.status === TicketStatus.PENDING ||
    (ticket.status === TicketStatus.CLOSED && !ticket.ticketUpdatedAt)
  const hasResolvedDate =
    (ticket.status === TicketStatus.RESOLVED || ticket.status === TicketStatus.CLOSED) && ticket.ticketUpdatedAt

  return (
    <TicketContainer
      className={`w-full text-90 transition-all ease-in-out border-t border-dashed ${isOpen ? 'flex flex-col py-6 gap-6 border-gray-500 bg-gray-600/90' : 'py-0 border-transparent'}`}
    >
      {isOpen && (
        <>
          <div className={'flex flex-col xl:flex-row gap-4 xl:gap-0 items-start justify-between'}>
            <div className={'text-90 w-full'}>
              <DetailRow>
                <div>{t('tickets.ticketDetails.id')}</div>
                <GrayText>{ticket.ticketId}</GrayText>
              </DetailRow>
              {ticket.monitorResource && (
                <DetailRow>
                  <WhiteText className={'first-letter:capitalize break-keep'}>
                    {t('tickets.ticketDetails.sourceResource')}
                  </WhiteText>
                  <GrayText className={'break-all text-right'}>{ticket.monitorResource}</GrayText>
                </DetailRow>
              )}
              <DetailRow>
                <div className={'first-letter:capitalize'}>{t('tickets.ticketDetails.created')}</div>
                <GrayText>{formatDate(ticket.ticketCreatedAt)}</GrayText>
              </DetailRow>
              {(hasDueDate || hasResolvedDate) && hasDueDate ? (
                <DetailRow>
                  <WhiteText className={'first-letter:capitalize'}>{t('tickets.ticketDetails.due')}</WhiteText>
                  <GrayText>{formatDate(ticket.dueBy)}</GrayText>
                </DetailRow>
              ) : (
                <DetailRow>
                  <WhiteText className={'first-letter:capitalize'}>{t('tickets.ticketDetails.resolved')}</WhiteText>
                  <GrayText>{formatDate(ticket.ticketUpdatedAt)}</GrayText>
                </DetailRow>
              )}

              {ticket.category && (
                <DetailRow>
                  <WhiteText className={'first-letter:capitalize'}>{t('tickets.ticketDetails.category')}</WhiteText>
                  <GrayText>{ticket.category}</GrayText>
                </DetailRow>
              )}
              {ticket.incidentSubcategory && (
                <DetailRow>
                  <WhiteText className={'first-letter:capitalize'}>
                    {t('tickets.ticketDetails.incidentSubcategory')}
                  </WhiteText>
                  <GrayText>{ticket.incidentSubcategory}</GrayText>
                </DetailRow>
              )}
            </div>
          </div>
          <div className={'flex flex-col gap-4'}>
            <Heading4>{t('tickets.ticketDetails.fullDescription')}</Heading4>
            <Description>{ticket.description}</Description>
          </div>
        </>
      )}
    </TicketContainer>
  )
}

const DetailRow = styled.div`
  ${tw`flex gap-5 items-center justify-between border-b border-dashed border-gray-500/70 mb-2 last:mb-0`}
`

const Description = styled(GrayText)`
  ${tw`font-light break-all`}
`

interface TicketTypeIconProps {
  type: TicketType
  priority: TicketPriority
}

const TicketTypeIcon = ({ type, priority }: TicketTypeIconProps) => {
  const color =
    priority === TicketPriority.URGENT
      ? 'bg-primary-600/70'
      : priority === TicketPriority.HIGH
        ? 'bg-primary-500/70'
        : priority === TicketPriority.MEDIUM
          ? 'bg-warn-500/70'
          : priority === TicketPriority.LOW
            ? 'bg-tertiary-500/70'
            : ''
  return (
    <CustomIcon
      path={
        type === 'Incident'
          ? incident
          : type === 'Major Incident'
            ? majorIncident
            : type === 'Service Request'
              ? serviceRequest
              : ''
      }
      styles={`w-7 h-8 ${color}`}
    />
  )
}
