import React from 'react'

export interface PingProps {
  dimmed: boolean
  size?: 'small' | 'default'
  position?: string
}

export const Ping = ({ dimmed, size = 'default', position }: PingProps) => {
  return (
    <div className={`inline-flex absolute ${size === 'small' ? 'w-2 h-2' : 'h-3 w-3'}  ${position}`}>
      <div className={'animate-ping absolute rounded-full h-full w-full bg-primary-300/80'} />
      <div className={`rounded-full h-full w-full ${dimmed ? 'bg-primary-500/60' : 'bg-primary-500/90'}`}></div>
    </div>
  )
}
