import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { NavbarContext } from '../../state/context/NavbarContext'
import { FiltersContext } from '../../state/context/FiltersContext'
import { useScreenSize } from '../../hooks/useScreenSize'
import { Link, useLocation, useParams } from 'react-router-dom'
import { UserRole } from '../../api/auth'
import { CustomIcon, IconType } from '../../components/shared/CustomIcon'
import { twXl } from '../../design/constants'
import { Button, ButtonStyle, ButtonType } from '../../components/shared/buttons/Button'
import menuIcon from '../../assets/svg/actions/expand-burger-menu.svg'
import { TenantMenu } from './TenantMenu'
import filterIcon from '../../assets/svg/actions/filter.svg'
import { getNavLinks } from './Sidenav'
import { Ping, PingProps } from '../../components/shared/indicators/Ping'
import { WhiteText } from '../../components/shared/TextComponents'
import spotterLogo2 from '../../assets/svg/brand/spotter-logo-2.svg'
import pinIcon from '../../assets/svg/objects/map-pin.svg'

export const TopNav = () => {
  const { t } = useTranslation()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const { navOpen, setNavOpen } = useContext(NavbarContext)
  const { activeFilters, filtersOpen, setFiltersOpen } = useContext(FiltersContext)
  const screenSize = useScreenSize()
  const location = useLocation()
  const navbarLinks = getNavLinks(authInfo, integrationStates, t)
  const isSysAdmin = authInfo.roles.includes(UserRole.SYSTEM_ADMIN)
  const pathParams = useParams()
  let locationPaths = location.pathname.split('/')
  Object.keys(pathParams).length > 0 && locationPaths.pop()
  const locationLabel = locationPaths.join(' / ').replaceAll('-', ' ').substring(2)

  const filteredPaths = navbarLinks.filter(link => link.hasFilters).map(link => link.path)

  const MobileLocationLabel = () => {
    return (
      <WhiteText
        className={
          'flex gap-1.5 items-center text-90 w-fit cursor-pointer transition-all ease-in-out duration-200 hover:text-gray-50 xl:hidden first:pl-0 first:pr-5 first:border-r first:border-gray-50/90 last:border-none last:pr-1'
        }
        onClick={() => setNavOpen(!navOpen)}
      >
        <CustomIcon path={pinIcon} styles={'w-5 h-5 bg-gray-50'} />
        {locationLabel}
      </WhiteText>
    )
  }

  return (
    <div
      id={'top-nav'}
      className={
        'sticky top-0 z-[999] backdrop-filter backdrop-blur flex w-full h-14 gap-2 items-center justify-between px-3 border-b border-gray-500/50'
      }
    >
      <div className={'inline-flex gap-3 items-center'}>
        {screenSize.width < twXl && (
          <Button
            clickHandler={() => setNavOpen(!navOpen)}
            buttonStyle={ButtonStyle.GHOST}
            type={ButtonType.ICON}
            value={
              <CustomIcon
                path={menuIcon}
                styles={'w-5 h-5 bg-gray-50 hover:scale-105'}
                tooltipText={t('topNav.openMenu')}
              />
            }
          />
        )}
        <Link
          to={'/home'}
          className={`${isSysAdmin ? 'hidden xs:flex' : 'flex'} items-center justify-center px-2 w-fit xl:justify-start xl:w-50`}
        >
          <CustomIcon
            id={'qa-navbar-logo'}
            iconType={IconType.VECTOR}
            path={spotterLogo2}
            styles={'bg-primary-500 w-24 md:w-29 h-6'}
          />
        </Link>
        {isSysAdmin ? (
          <TenantMenu />
        ) : (
          <WhiteText className={'hidden xs:block text-90'}>{authInfo.tenant.name}</WhiteText>
        )}
      </div>
      <div className={'flex items-center'}>
        <MobileLocationLabel />
        {filteredPaths.includes(location.pathname) && (
          <FilterIcon
            ping={true}
            dimmed={!activeFilters}
            clickHandler={() => setFiltersOpen(!filtersOpen)}
            count={activeFilters}
          />
        )}
      </div>
    </div>
  )
}

interface FilterIconProps extends PingProps {
  ping?: boolean
  clickHandler?: () => void
  count?: number
}

export const FilterIcon = ({ dimmed, size = 'small', ping, clickHandler, count }: FilterIconProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex pl-3'}>
      {ping && <Ping dimmed={dimmed} position={'-mt-0.5 ml-7'} size={size} />}
      <Button
        type={ButtonType.ICON}
        buttonStyle={ButtonStyle.GHOST}
        value={
          <CustomIcon
            iconType={IconType.VECTOR}
            path={filterIcon}
            styles={'w-6 h-6 bg-gray-50'}
            tooltipText={t('topNav.filters')}
            tooltipStyles={'-ml-18'}
          />
        }
        clickHandler={clickHandler}
      />
      {count !== undefined && count > 0 && (
        <div className={'-ml-1 self-end text-90 font-semibold text-primary-500'}>{count}</div>
      )}
    </div>
  )
}
