import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import caretIcon from '../../assets/svg/directional/caret.svg'
import { TicketContainer, TicketsTable } from './TicketsTable'
import { FreshTicket, TicketPriority, TicketStatus, TicketType } from '../../api/tickets'
import { Trans, useTranslation } from 'react-i18next'
import { CountUp } from '../shared/CountUp'
import { CustomIcon } from '../shared/CustomIcon'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { GradientText, GrayText, Heading3, Heading4 } from '../shared/TextComponents'
import { InputType, TextInput } from '../shared/filters/TextInput'
import { DateRangePicker } from '../shared/filters/DateRangePicker'
import { RadioButton } from '../shared/buttons/RadioButton'
import { getMaxDate, getMinDate } from '../../utils/formats'
import { TextBadge } from '../shared/indicators/TextBadge'
import { FilterIcon } from '../../layout/navigation/Topnav'

export const ticketStatusColor = {
  OPEN: { gradient: 'from-primary-400 to-primary-500', flat: 'bg-primary-500' },
  PENDING: { gradient: 'from-primary-500/90 to-primary-600/90', flat: 'bg-primary-600/90' },
  RESOLVED: { gradient: 'from-success-100 to-success-500', flat: 'bg-success-500' },
  CLOSED: { gradient: 'from-gray-200 to-gray-400', flat: 'bg-gray-400' }
}

interface TicketsByStatusProps {
  status: TicketStatus
  tickets: FreshTicket[]
}

export const TicketsStatusContainer = ({ status, tickets }: TicketsByStatusProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(true)
  const [filteredTickets, setFilteredTickets] = useState<FreshTicket[]>(tickets)
  const [firstSearchText, setFirstSearchText] = useState<string>('')
  const [secondSearchText, setSecondSearchText] = useState<string>('')
  const [createdMinDate, setCreatedMinDate] = useState<Date | null>(null)
  const [createdMaxDate, setCreatedMaxDate] = useState<Date | null>(new Date())
  const [dueMinDate, setDueMinDate] = useState<Date | null>(null)
  const [dueMaxDate, setDueMaxDate] = useState<Date | null>(new Date())
  const [resolvedMinDate, setResolvedMinDate] = useState<Date | null>(null)
  const [resolvedMaxDate, setResolvedMaxDate] = useState<Date | null>(null)
  const [createdStartDate, setCreatedStartDate] = useState<Date | null>(createdMinDate)
  const [createdEndDate, setCreatedEndDate] = useState<Date | null>(createdMaxDate)
  const [dueStartDate, setDueStartDate] = useState<Date | null>(dueMinDate)
  const [dueEndDate, setDueEndDate] = useState<Date | null>(dueMaxDate)
  const [resolvedStartDate, setResolvedStartDate] = useState<Date | null>(resolvedMinDate)
  const [resolvedEndDate, setResolvedEndDate] = useState<Date | null>(resolvedMaxDate)
  const [onlyUnattended, setOnlyUnattended] = useState(false)
  const [onlyUnattendedDuePassed, setOnlyUnattendedDuePassed] = useState(false)
  const [duePassed, setDuePassed] = useState(false)

  useEffect(() => {
    const createdDates = tickets.map(ticket => ticket.ticketCreatedAt)
    const dueDates = tickets.map(ticket => ticket.dueBy)
    const resolvedDates = tickets
      .filter(t => (t.status === TicketStatus.RESOLVED || t.status === TicketStatus.CLOSED) && t.ticketUpdatedAt)
      .map(t => t.ticketUpdatedAt as Date)
    setCreatedMinDate(getMinDate(createdDates))
    setCreatedMaxDate(getMaxDate(createdDates))
    setDueMinDate(getMinDate(dueDates))
    setDueMaxDate(getMaxDate(dueDates))
    resolvedDates.length > 0 && setResolvedMinDate(getMinDate(resolvedDates))
    resolvedDates.length > 0 && setResolvedMaxDate(getMaxDate(resolvedDates))
    createdMinDate?.setHours(0, 0, 0, 0)
    createdMaxDate?.setHours(23, 59, 59, 999)
    dueMinDate?.setHours(0, 0, 0, 0)
    dueMaxDate?.setHours(23, 59, 59, 999)
    resolvedMinDate?.setHours(0, 0, 0, 0)
    resolvedMaxDate?.setHours(23, 59, 59, 999)
  }, [tickets])

  useEffect(() => {
    setCreatedStartDate(createdMinDate)
    setCreatedEndDate(createdMaxDate)
    setDueStartDate(dueMinDate)
    setDueEndDate(dueMaxDate)
    setResolvedStartDate(resolvedMinDate)
    setResolvedEndDate(resolvedMaxDate)
  }, [
    createdMaxDate,
    createdMinDate,
    dueMinDate,
    dueMaxDate,
    resolvedMaxDate,
    resolvedMinDate,
    duePassed,
    onlyUnattended,
    onlyUnattendedDuePassed
  ])

  useEffect(() => {
    setFilteredTickets(
      tickets
        .filter(
          ticket =>
            ticket.subject.toLowerCase().includes(firstSearchText.toLowerCase()) ||
            ticket.ticketId.toString().includes(firstSearchText)
        )
        .filter(ticket => ticket.description.toLowerCase().includes(secondSearchText.toLowerCase()))
        .filter(ticket =>
          createdStartDate && createdEndDate
            ? ticket.ticketCreatedAt >= createdStartDate && ticket.ticketCreatedAt <= createdEndDate
            : ticket
        )
        .filter(ticket =>
          dueStartDate && dueEndDate ? ticket.dueBy >= dueStartDate && ticket.dueBy <= dueEndDate : ticket
        )
        .filter(ticket =>
          resolvedStartDate && resolvedEndDate
            ? ticket.ticketUpdatedAt
              ? ticket.ticketUpdatedAt >= resolvedStartDate && ticket.ticketUpdatedAt <= resolvedEndDate
              : ticket
            : ticket
        )
        .filter(t => (onlyUnattended ? !t.ticketUpdatedAt : t))
        .filter(t => (onlyUnattendedDuePassed ? !t.ticketUpdatedAt && t.dueBy < new Date() : t))
        .filter(t => (duePassed ? t.dueBy < new Date() : t))
    )
  }, [
    onlyUnattended,
    onlyUnattendedDuePassed,
    duePassed,
    createdEndDate,
    firstSearchText,
    secondSearchText,
    createdStartDate,
    dueStartDate,
    dueEndDate,
    resolvedStartDate,
    resolvedEndDate,
    createdMinDate,
    createdMaxDate,
    dueMinDate,
    dueMaxDate,
    resolvedMinDate,
    resolvedMaxDate,
    tickets
  ])

  useEffect(() => {
    if (filteredTickets.length === 0) {
      setCreatedStartDate(createdMinDate)
      setCreatedEndDate(createdMaxDate)
      setDueStartDate(dueMinDate)
      setDueEndDate(dueMaxDate)
      setResolvedStartDate(resolvedMinDate)
      setResolvedEndDate(resolvedMaxDate)
    } else {
      const createdAtDates = filteredTickets.map(ticket => ticket.ticketCreatedAt)
      const dueDates = filteredTickets.map(ticket => ticket.dueBy)
      const resolvedDates = filteredTickets.filter(t => t.ticketUpdatedAt).map(t => t.ticketUpdatedAt as Date)
      setCreatedStartDate(getMinDate(createdAtDates))
      setCreatedEndDate(getMaxDate(createdAtDates))
      setDueStartDate(getMinDate(dueDates))
      setDueEndDate(getMaxDate(dueDates))
      if (!onlyUnattendedDuePassed && !onlyUnattended) {
        setResolvedStartDate(getMinDate(resolvedDates))
        setResolvedEndDate(getMaxDate(resolvedDates))
      }
    }
  }, [filteredTickets.length])

  const resetFilters = () => {
    setFirstSearchText('')
    setCreatedStartDate(createdMinDate)
    setCreatedEndDate(createdMaxDate)
    setDueStartDate(dueMinDate)
    setDueEndDate(dueMaxDate)
    setResolvedStartDate(resolvedMinDate)
    setResolvedEndDate(resolvedMaxDate)
    setOnlyUnattended(false)
    setOnlyUnattendedDuePassed(false)
    setDuePassed(false)
  }

  return (
    <>
      <div className={'flex flex-col bg-gray-600/90 rounded-md sticky top-14 z-[1]'}>
        <div className={`w-full rounded-t-md pb-1 ${ticketStatusColor[status].flat}`} />
        <CollapsibleSummary setIsOpen={setIsOpen} isOpen={isOpen} status={status} tickets={filteredTickets} />
        <Wrapper
          className={`${isOpen ? 'py-6 border-t border-dashed ' : 'py-0 border-none'} transition-all ease-in-out duration-150 border-gray-500`}
        >
          {isOpen &&
            (filtersOpen ? (
              <div className={'flex flex-col w-full'}>
                <Heading4>{t('tickets.findTicket')}</Heading4>
                <TicketFilterWrapper>
                  <div>
                    <GrayText className={'text-90'}>{t('tickets.searchLabel')}</GrayText>
                    <TextInput
                      id={`${status}-tickets-search-input-1`}
                      type={InputType.SEARCH}
                      value={firstSearchText}
                      setInputValue={setFirstSearchText}
                      placeholder={t('tickets.searchPlaceholder')}
                    />
                  </div>
                  <div>
                    <GrayText className={'text-90'}>{t('tickets.searchLabel2')}</GrayText>
                    <TextInput
                      id={`${status}-tickets-search-input-2`}
                      type={InputType.SEARCH}
                      value={secondSearchText}
                      setInputValue={setSecondSearchText}
                      placeholder={t('tickets.searchPlaceholder2')}
                    />
                  </div>
                  {createdMinDate && (
                    <DateRangePicker
                      id={`${status}-created-date-range`}
                      label={t('tickets.createdAtDateRange')}
                      minDate={createdMinDate}
                      maxDate={createdMaxDate}
                      startDate={createdStartDate}
                      endDate={createdEndDate}
                      setStartDate={setCreatedStartDate}
                      setEndDate={setCreatedEndDate}
                    />
                  )}
                  {dueMinDate && (
                    <DateRangePicker
                      id={`${status}-due-date-range`}
                      label={t('tickets.dueDateRange')}
                      minDate={dueMinDate}
                      maxDate={dueMaxDate}
                      startDate={dueStartDate}
                      endDate={dueEndDate}
                      setStartDate={setDueStartDate}
                      setEndDate={setDueEndDate}
                    />
                  )}
                  {resolvedMinDate && (
                    <DateRangePicker
                      id={`${status}-resolved-date-range`}
                      label={t('tickets.resolvedDateRange')}
                      minDate={resolvedMinDate}
                      maxDate={resolvedMaxDate}
                      startDate={resolvedStartDate}
                      endDate={resolvedEndDate}
                      setStartDate={setResolvedStartDate}
                      setEndDate={setResolvedEndDate}
                      disabled={onlyUnattended || onlyUnattendedDuePassed}
                    />
                  )}
                  {(status === TicketStatus.OPEN || status === TicketStatus.PENDING) &&
                  tickets.some(t => t.dueBy < new Date()) ? (
                    <div>
                      <GrayText className={'text-90 pb-1.5'}>{t('tickets.possiblyUnattended')}</GrayText>
                      <RadioButton
                        type={'secondary'}
                        checked={duePassed}
                        onChange={() => setDuePassed(!duePassed)}
                        label={t('tickets.header.dueExpired')}
                      />
                    </div>
                  ) : (
                    (status === TicketStatus.CLOSED || status === TicketStatus.RESOLVED) &&
                    tickets.some(t => !t.ticketUpdatedAt) && (
                      <div>
                        <GrayText className={'text-90 pb-1.5'}>{t('tickets.possiblyUnattended')}</GrayText>
                        <RadioButton
                          type={'secondary'}
                          checked={onlyUnattended}
                          onChange={() => {
                            onlyUnattendedDuePassed && setOnlyUnattendedDuePassed(false)
                            setOnlyUnattended(!onlyUnattended)
                          }}
                          label={t('tickets.neverUpdated')}
                        />
                        <RadioButton
                          type={'secondary'}
                          checked={onlyUnattendedDuePassed}
                          onChange={() => {
                            onlyUnattended && setOnlyUnattended(false)
                            setOnlyUnattendedDuePassed(!onlyUnattendedDuePassed)
                          }}
                          label={t('tickets.neverUpdatedDueExpired')}
                        />
                      </div>
                    )
                  )}
                </TicketFilterWrapper>
                {filteredTickets.length !== tickets.length && (
                  <div className={'flex w-full justify-between gap-10 items-center'}>
                    <GrayText className={'text-90 p-2 pb-0'}>
                      <Trans>{t('tickets.ticketsFound', { count: filteredTickets.length })}</Trans>
                    </GrayText>
                    <Button
                      value={t('filters.clearAll')}
                      clickHandler={() => resetFilters()}
                      buttonStyle={ButtonStyle.GHOST}
                      size={ButtonSize.XSMALL}
                    />
                  </div>
                )}
                <div className={'self-center'}>
                  <Button
                    clickHandler={() => setFiltersOpen(!filtersOpen)}
                    type={ButtonType.ICON}
                    buttonStyle={ButtonStyle.GHOST}
                    value={
                      <CustomIcon
                        path={caretIcon}
                        styles={`w-6 h-6 bg-gray-50 ${filtersOpen ? 'rotate-180' : 'rotate-0'}`}
                        alt={t('common.open')}
                      />
                    }
                  />
                </div>
              </div>
            ) : (
              <div
                className={'flex w-full items-center justify-between gap-10 cursor-pointer'}
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                <Heading4>{t('tickets.findTicket')}</Heading4>
                <FilterIcon
                  dimmed={false}
                  ping={filteredTickets.length !== tickets.length}
                  count={filteredTickets.length !== tickets.length ? filteredTickets.length : undefined}
                />
              </div>
            ))}
        </Wrapper>
      </div>
      {isOpen && <TicketsTable tickets={filteredTickets} />}
    </>
  )
}

const TicketFilterWrapper = styled.div`
  ${tw`flex flex-wrap items-center w-full`}
  > div {
    ${tw`w-full pr-10 last:pr-0 py-3 sm:max-w-max sm:min-w-[300px]`}
  }
`

interface CollapsibleSummaryProps extends TicketsByStatusProps {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}

const CollapsibleSummary = ({ status, tickets, isOpen, setIsOpen }: CollapsibleSummaryProps) => {
  const { t } = useTranslation()
  const countStyles = `font-black text-300 ${ticketStatusColor[status].gradient}`
  const incidentCount = tickets.filter(ticket => ticket.type === TicketType.INCIDENT).length
  const majorIncidentCount = tickets.filter(ticket => ticket.type === TicketType.MAJOR_INCIDENT).length
  const serviceRequestCount = tickets.filter(ticket => ticket.type === TicketType.SERVICE_REQUEST).length
  const countsByType = []
  if (majorIncidentCount > 0) countsByType.push(t('tickets.ticketCounts.majorIncident', { count: majorIncidentCount }))
  if (incidentCount > 0) countsByType.push(t('tickets.ticketCounts.incident', { count: incidentCount }))
  if (serviceRequestCount > 0)
    countsByType.push(t('tickets.ticketCounts.serviceRequest', { count: serviceRequestCount }))

  return (
    <Wrapper className={'cursor-pointer py-6 hover:bg-gray-600'} onClick={() => setIsOpen(!isOpen)}>
      <div className={'flex items-center gap-4'}>
        <GradientText className={countStyles}>
          <CountUp countTo={tickets.length} id={`qa-tickets-${status}-count`} />
        </GradientText>
        <div className={'flex flex-col'}>
          <Heading3>{status}</Heading3>
          <GrayText>{countsByType.join(' - ')}</GrayText>
        </div>
      </div>
      <div className={'flex gap-8 items-center'}>
        {[TicketStatus.OPEN, TicketStatus.PENDING].includes(status) &&
          tickets.some(t => t.priority === TicketPriority.URGENT || t.priority === TicketPriority.HIGH) && (
            <div className={'flex flex-wrap justify-end gap-2.5'}>
              {tickets.some(t => t.priority === TicketPriority.URGENT) && <TextBadge value={TicketPriority.URGENT} />}
              {tickets.some(t => t.priority === TicketPriority.HIGH) && <TextBadge value={TicketPriority.HIGH} />}
            </div>
          )}
        <Button
          clickHandler={() => setIsOpen(!isOpen)}
          type={ButtonType.ICON}
          buttonStyle={ButtonStyle.GHOST}
          value={
            <CustomIcon
              path={caretIcon}
              styles={`w-6 h-6 bg-gray-50 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
              alt={t('common.open')}
            />
          }
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(TicketContainer)`
  ${tw`flex justify-between items-center gap-5 w-full`}
`
