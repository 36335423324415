import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CustomIcon, IconType } from './CustomIcon'
import caret from '../../assets/svg/directional/caret.svg'
import { useScreenSize } from '../../hooks/useScreenSize'
import arrow from '../../assets/svg/directional/nested-property-arrow.svg'

export interface TreeBranchProps {
  headerData: React.ReactNode
  contentData: React.ReactNode
  combineContent?: boolean
  depth?: number
  showConnector?: boolean
  id?: string
}

export const TreeBranch = ({
  headerData,
  contentData,
  depth = 0,
  showConnector = true,
  id,
  combineContent = false
}: TreeBranchProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const headerDepthId = (depth: number) => `${depth}-tree-header`
  const [parentHeight, setParentHeight] = useState<number>()
  const [prevHeight, setPrevHeight] = useState<number>()
  const screenSize = useScreenSize()

  useEffect(() => {
    setParentHeight(document.getElementById(headerDepthId(0))?.getBoundingClientRect().height)
    depth > 1 && setPrevHeight(document.getElementById(headerDepthId(depth - 1))?.getBoundingClientRect().height)
  }, [depth, id, isOpen, parentHeight, prevHeight, screenSize.width])

  const top =
    depth > 2 ? (depth - 1) * (prevHeight ?? 0) + (parentHeight ?? 0) : (prevHeight ?? 0) + (parentHeight ?? 0)

  return (
    <Branch id={id} depth={depth} showConnector={showConnector} zIndex={10 - depth}>
      {!combineContent ? (
        <LeveledContent
          headerDepthId={headerDepthId}
          top={top}
          depth={depth}
          headerData={headerData}
          contentData={contentData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <CombinedContentWrapper
          depth={depth}
          id={id}
          headerData={headerData}
          contentData={contentData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </Branch>
  )
}

interface ContentProps {
  depth: number
  id?: string
  headerData: React.ReactNode
  contentData: React.ReactNode
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

interface LeveledContentProps extends ContentProps {
  headerDepthId: (depth: number) => string
  top: number
}

const LeveledContent = ({
  depth,
  id,
  headerData,
  contentData,
  isOpen,
  setIsOpen,
  headerDepthId,
  top
}: LeveledContentProps) => {
  const topNavHeight = document.getElementById('top-nav')?.getBoundingClientRect().height ?? 0
  return (
    <>
      <div
        className={isOpen ? 'backdrop-blur-lg ml-1px' : ''}
        id={headerDepthId(depth)}
        style={{
          zIndex: isOpen ? 10 - depth : 0,
          position: isOpen ? 'sticky' : 'static',
          top: isOpen && depth > 0 ? `${topNavHeight - 1 + top}px` : topNavHeight - 2
        }}
      >
        <Header
          id={`${depth}-${id}-tree-header`}
          hasContent={!!contentData}
          depth={depth}
          onClick={() => contentData && setIsOpen(!isOpen)}
          showConnector={isOpen && !!contentData}
        >
          {contentData && (
            <div className={'p-2 bg-gray-500/60 rounded-md cursor-pointer'} onClick={() => setIsOpen(!isOpen)}>
              <CustomIcon
                path={caret}
                iconType={IconType.VECTOR}
                styles={`w-5 h-5 bg-gray-50 ${isOpen && 'rotate-180'}`}
              />
            </div>
          )}
          <div className={'w-full'}>{headerData}</div>
        </Header>
      </div>
      {isOpen && <ContentData depth={depth}>{contentData}</ContentData>}
    </>
  )
}

const CombinedContentWrapper = ({ depth, id, headerData, contentData, setIsOpen, isOpen }: ContentProps) => {
  return (
    <Header
      id={`${depth}-${id}-tree-header`}
      hasContent={!!contentData}
      depth={depth}
      onClick={() => contentData && setIsOpen(!isOpen)}
      showConnector={false}
      className={'group border-b border-gray-500/60 border-dashed last:border-none'}
    >
      <div className={'w-full'}>
        <div className={isOpen ? 'xl:pb-2' : 'p-0'}>{headerData}</div>
        {isOpen && <div className={'xl:pt-2'}>{contentData}</div>}
      </div>
      <RowToggleArrow isOpen={isOpen} />
    </Header>
  )
}

interface RowToggleArrowProps {
  isOpen: boolean
}

const RowToggleArrow = ({ isOpen }: RowToggleArrowProps) => {
  return (
    <div className={'hidden xl:flex'}>
      <CustomIcon
        iconType={IconType.VECTOR}
        path={arrow}
        styles={`w-6 h-6 bg-gray-200 group-hover:bg-gray-50 ${isOpen && 'rotate-90'} transition-transform ease-in-out duration-200`}
      />
    </div>
  )
}

interface BranchProps {
  zIndex: number
  depth: number
  showConnector: boolean
}

const Branch = styled.li<BranchProps>`
  ${tw`w-full relative`}
  ${({ depth }) =>
    depth > 0 &&
    tw`before:content-[''] before:top-[50%] before:w-5 before:absolute before:left-0 before:border-t before:border-gray-400 last:before:bg-gray-600 last:before:h-auto last:before:bottom-0`}
  ${({ showConnector }) => !showConnector && tw`before:hidden`}
`

interface ContentDataProps {
  depth: number
}

const ContentData = styled.ul<ContentDataProps>`
  ${tw`flex flex-col w-auto pt-4 mb-8 relative divide-y divide-gray-500/60 before:block before:absolute before:-top-2 before:bottom-0 before:w-0 before:left-0 before:border-l before:border-gray-400`}
  ${props => (props.depth === 0 ? tw`ml-7` : tw`ml-10`)}
`

interface HeaderProps {
  depth: number
  showConnector: boolean
  hasContent: boolean
}

const Header = styled.div<HeaderProps>`
  ${tw`flex relative before:absolute gap-2 py-4 px-2 rounded-md items-center`}
  ${({ hasContent }) => hasContent && tw`cursor-pointer hover:bg-gray-500/30`}
  ${({ showConnector }) =>
    showConnector &&
    tw`before:block before:bottom-0 before:w-0 before:left-7 before:border-l before:border-gray-400 before:h-[calc(50% - 18px)]`}
  ${({ depth }) => depth > 0 && tw`px-5 items-start rounded-sm xl:items-center before:h-[calc(100% - 52px)]`}
  ${({ showConnector }) => showConnector && tw`shadow-xs`}
  margin-left: ${({ showConnector, depth }) => showConnector && `${depth - 1}px`};

  ::before {
    left: ${({ showConnector, depth }) => showConnector && depth > 0 && `${40 - depth}px`};
  }
`
