import { TabNoDataMessage } from '../../shared/tabs/TabSharedComponents'
import { Tree } from '../../shared/Tree'
import {
  CostEntry,
  CostProject,
  CostResourceGroup,
  CostService,
  CostsProjectAllocationPercent,
  CostVendor
} from '../../../api/costs'
import { useTranslation } from 'react-i18next'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../shared/DataTableSharedComponents'
import { formatCurrency, formatDate, formatNumber, getMaxDate } from '../../../utils/formats'
import styled from 'styled-components'
import tw from 'twin.macro'
import { TreeBranch } from '../../shared/TreeBranch'
import React, { memo, useContext } from 'react'
import { VictoryLine } from 'victory'
import { primary500 } from '../../../design/constants'
import { Vendor } from '../../../utils/vendors'
import { ClipboardCopy } from '../../shared/ClipboardCopy'
import { CurrencyFlag, getCurrency, KronaCurrencies } from '../../../utils/Currency'
import { Loading } from '../../shared/Loading'
import { ChangeIndicator } from '../../shared/indicators/ChangeIndicator'
import { TimeframeOption } from '../../../utils/classes'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { ClockTimestamp } from '../../shared/ClockTimestamp'
import { StatusType, TextBadge } from '../../shared/indicators/TextBadge'

interface CostTabContentProps {
  selectedVendors: Vendor[]
  projectList?: CostVendor
  selectedTab: Vendor
  selectedTimeframe: TimeframeOption
  loading: boolean
  allocationPercents: CostsProjectAllocationPercent[]
}

export const CostsVendorTab = ({
  selectedVendors,
  projectList,
  selectedTab,
  selectedTimeframe,
  loading,
  allocationPercents
}: CostTabContentProps) => {
  return (
    <>
      {loading ? (
        <Loading paddingY={'2rem'} height={64} />
      ) : !projectList?.projects.length || !selectedVendors.includes(selectedTab) ? (
        <TabNoDataMessage selectedVendors={selectedVendors} vendor={selectedTab} />
      ) : (
        <>
          <CostTabHeader vendorData={projectList} selectedTimeframe={selectedTimeframe} />
          <Tree key={projectList.projects.length}>
            {projectList.projects.map((project, index) => (
              <CostTreeProject
                index={index}
                key={project.project.id}
                vendor={selectedTab}
                projectData={project}
                selectedTimeframe={selectedTimeframe}
                allocationPercent={
                  allocationPercents.find(p => p.project.id === project.project.id)?.allocationPercent || null
                }
              />
            ))}
          </Tree>
        </>
      )}
    </>
  )
}

interface CostTabHeaderProps {
  vendorData: CostVendor
  selectedTimeframe: TimeframeOption
}

const CostTabHeader = ({ vendorData, selectedTimeframe }: CostTabHeaderProps) => {
  const { t } = useTranslation()
  const { integrationStates } = useContext(UserInfoContext)

  const latestIntegrations =
    vendorData.vendor === Vendor.AWS
      ? (integrationStates.aws?.map(i => i.billing.lastIntegrationAt).filter(i => i) ?? [])
      : vendorData.vendor === Vendor.AZURE
        ? (integrationStates.azure?.map(i => i.billing.lastIntegrationAt).filter(i => i) ?? [])
        : vendorData.vendor === Vendor.GCP
          ? (integrationStates.gcp?.flatMap(i => i.billingAccounts.map(b => b.billing.lastIntegrationAt)) ?? [])
          : []

  const lastIntegrationAt = formatDate(getMaxDate(latestIntegrations))

  const Name = () => {
    return (
      <>
        <Title>{t('costs.tabs.vendors.title')}</Title>
        <DataTableLabel isHeader={true}>
          {`${vendorData.projects.length} ${t(`vendors.${vendorData.vendor}.projectPhrase`, {
            count: vendorData.projects.length
          })}`}
        </DataTableLabel>
      </>
    )
  }
  return (
    <div className={'pb-4'}>
      <ClockTimestamp dateString={lastIntegrationAt} />
      <CostDataRow
        isHeader={true}
        selectedTimeframe={selectedTimeframe}
        name={<Name />}
        data={vendorData}
        prevCostMargin={'xl:ml-9'}
        currCostMargin={'xl:ml-6'}
        forecastMargin={'xl:ml-2.5'}
      />
    </div>
  )
}

const Title = styled.div`
  ${tw`capitalize text-125 font-bold text-gray-50`}
`

interface CostTreeProjectProps {
  index: number
  vendor: Vendor
  projectData: CostProject
  allocationPercent: number | null
  selectedTimeframe: TimeframeOption
}

const CostTreeProject = ({
  index,
  vendor,
  projectData,
  allocationPercent,
  selectedTimeframe
}: CostTreeProjectProps) => {
  return (
    <TreeBranch
      id={`qa-costs-${vendor}-tab-content-${index}`}
      headerData={
        <CostDataRow
          name={
            <DataTableValue>
              <div className={'flex items-center gap-3'}>
                <ClipboardCopy
                  restrictClickArea={true}
                  copyText={projectData.project.name}
                  secondCopyText={projectData.project.id}
                  limit={50}
                />
                {allocationPercent && (
                  <span className={'text-gray-200/70 text-90 min-w-max'}>{allocationPercent} %</span>
                )}
              </div>
            </DataTableValue>
          }
          data={projectData}
          selectedTimeframe={selectedTimeframe}
        />
      }
      contentData={
        <>
          {projectData.services.map(service => (
            <CostTreeService
              key={`${projectData.project.id}-${service.name}`}
              serviceData={service}
              selectedTimeframe={selectedTimeframe}
            />
          ))}
          {vendor === Vendor.AZURE &&
            projectData.resourceGroups?.map(rg => (
              <CostTreeResourceGroup
                key={`${projectData.project.name}-${rg.name}`}
                data={rg}
                selectedTimeframe={selectedTimeframe}
              />
            ))}
        </>
      }
    />
  )
}

interface CostTreeResourceGroupProps {
  data: CostResourceGroup
  selectedTimeframe: TimeframeOption
}

const CostTreeResourceGroup = ({ data, selectedTimeframe }: CostTreeResourceGroupProps) => {
  return (
    <>
      {data && (
        <TreeBranch
          depth={2}
          headerData={
            <CostDataRow
              selectedTimeframe={selectedTimeframe}
              name={data.name}
              data={data}
              prevCostMargin={'xl:-ml-6'}
              currCostMargin={'xl:-ml-3'}
              forecastMargin={'xl:-ml-0.5'}
            />
          }
          contentData={
            data.services.length > 0 &&
            data.services.map(service => (
              <CostTreeService
                key={`${data.name}-${service.name}`}
                serviceData={service}
                isResourceGroup={true}
                selectedTimeframe={selectedTimeframe}
              />
            ))
          }
        />
      )}
    </>
  )
}

interface TrendLineProps {
  data?: CostEntry[]
}

const TrendLine = memo(({ data }: TrendLineProps) => {
  return data && data.length > 1 ? (
    <div className={'max-w-85 h-8 p-0 xl:py-1 xl:h-full'}>
      <VictoryLine
        height={60}
        padding={0}
        data={data}
        x={'date'}
        y={'amount'}
        style={{
          data: {
            stroke: primary500,
            strokeWidth: 1.25
          }
        }}
      />
    </div>
  ) : (
    <div className={'h-8'} />
  )
})

interface CostTreeServiceProps {
  serviceData: CostService
  isResourceGroup?: boolean
  selectedTimeframe: TimeframeOption
}

const CostTreeService = ({ serviceData, isResourceGroup, selectedTimeframe }: CostTreeServiceProps) => {
  const { t } = useTranslation()
  const Name = () => {
    return (
      <>
        <DataTableLabel>{t('costs.tabs.vendors.service')}</DataTableLabel>
        <DataTableValue>{serviceData.name}</DataTableValue>
      </>
    )
  }
  return (
    <TreeBranch
      depth={2}
      headerData={
        <CostDataRow
          selectedTimeframe={selectedTimeframe}
          name={<Name />}
          data={serviceData}
          prevCostMargin={isResourceGroup ? 'xl:-ml-[18px]' : 'xl:ml-2.5'}
          currCostMargin={isResourceGroup ? 'xl:-ml-1.5' : 'xl:ml-3'}
        />
      }
      contentData={false}
    />
  )
}

interface CostDataRowProps {
  isHeader?: boolean
  selectedTimeframe: TimeframeOption
  name: React.ReactNode
  data: CostService | CostProject | CostVendor | CostResourceGroup
  prevCostMargin?: string
  currCostMargin?: string
  forecastMargin?: string
}

const CostDataRow = ({
  isHeader,
  selectedTimeframe,
  name,
  data,
  prevCostMargin,
  currCostMargin,
  forecastMargin
}: CostDataRowProps) => {
  const { t } = useTranslation()
  const useLongCurrencySymbol = 'vendor' in data && KronaCurrencies.includes(data.currency)
  return (
    <DataTableRow divider={!isHeader} styles={'xl:grid-cols-12'}>
      <DataTableCell styles={`xl:col-span-3`}>{name}</DataTableCell>
      <DataTableCell styles={`xl:col-span-2 ${prevCostMargin}`}>
        <DataTableLabel isHeader={isHeader}>{t(`common.timeframeOptions.${selectedTimeframe}`)}</DataTableLabel>
        <DataTableValue className={'inline-flex gap-2 items-center'}>
          {formatCurrency(data.currency, data.previousCost)}
          {useLongCurrencySymbol && <CurrencyFlag currencyId={data.currency} size={4} />}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell styles={`${currCostMargin} xl:col-span-2`}>
        <DataTableLabel isHeader={isHeader}>
          {selectedTimeframe === TimeframeOption.LAST_MONTH
            ? t('common.currentMonth')
            : t('common.timeframeOptions.CURRENT_YEAR')}
        </DataTableLabel>
        <DataTableValue className={'inline-flex gap-2 items-center'}>
          {formatCurrency(data.currency, data.currentCost)}
          {useLongCurrencySymbol && <CurrencyFlag currencyId={data.currency} size={4} />}
        </DataTableValue>
      </DataTableCell>
      {selectedTimeframe === TimeframeOption.LAST_MONTH && 'forecastCost' in data && (
        <DataTableCell styles={`${forecastMargin} xl:col-span-2`}>
          <DataTableLabel isHeader={isHeader}>{t('common.forecasted')}</DataTableLabel>
          <DataTableValue className={'inline-flex gap-3 items-center'}>
            {data.forecastCost && data.forecastCost !== 0 ? (
              `${formatNumber(data.forecastCost, 0)} ${
                useLongCurrencySymbol ? (
                  <CurrencyFlag currencyId={data.currency} size={4} />
                ) : (
                  getCurrency(data.currency).symbol
                )
              }`
            ) : (
              <TextBadge value={StatusType.NOT_AVAILABLE} />
            )}
            {'forecastChange' in data && data.forecastChange != null && (
              <ChangeIndicator change={data.forecastChange} />
            )}
          </DataTableValue>
        </DataTableCell>
      )}
      {selectedTimeframe === TimeframeOption.FROM_LAST_YEAR && 'costsChange' in data && (
        <DataTableCell>
          <DataTableLabel>{t('costs.tabs.vendors.changeFromLastYear')}</DataTableLabel>
          <DataTableValue>
            <ChangeIndicator change={data.costsChange} tooltip={t('costs.tabs.summary.vsLastYearTooltip')} />
          </DataTableValue>
        </DataTableCell>
      )}
      <DataTableCell styles={'xl:col-end-13 xl:col-span-3'}>
        <DataTableLabel isHeader={isHeader}>{t('costs.tabs.vendors.trend')}</DataTableLabel>
        <DataTableValue>{<TrendLine data={'chartData' in data ? data.chartData : []} />}</DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
