import { CancelToken } from 'axios'
import { client } from '../../client'
import { AzureIntegrationsState } from '../../settings/profile'

export const createAzureIntegration = async (
  integrationDetails: AzureIntegrationRequest,
  cancelToken: CancelToken
): Promise<AzureIntegrationsState> => {
  return await client
    .post('admin/integrations/azure', integrationDetails, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const editAzureIntegration = async (
  integrationDetails: AzureIntegrationRequest,
  cancelToken: CancelToken
): Promise<AzureIntegrationsState> => {
  return await client
    .put(`admin/integrations/azure/${integrationDetails.azureTenantId}`, integrationDetails, {
      cancelToken
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const deleteAzureIntegration = async (azureTenantId: string) => {
  return client.delete(`admin/integrations/azure/${azureTenantId}`)
}

export const recoverAzureIntegration = async (azureTenantId: string): Promise<AzureIntegrationsState> => {
  return client
    .post(`admin/integrations/azure/${azureTenantId}/recover`)
    .then(resp => getDateFormattedResponse(resp.data))
}

export const markAzureIntegrationDismissed = async (
  integration: AzureIntegrationsState,
  cancelToken: CancelToken
): Promise<AzureIntegrationsState> => {
  return await client
    .put(`admin/integrations/azure/${integration.azureTenantId}/dismiss`, integration, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

const getDateFormattedResponse = (resp: AzureIntegrationsState): AzureIntegrationsState => {
  return {
    ...resp,
    lastOrganizationIntegrationAt: resp.lastOrganizationIntegrationAt && new Date(resp.lastOrganizationIntegrationAt),
    billing: {
      ...resp.billing,
      lastIntegrationAt: resp.billing.lastIntegrationAt && new Date(resp.billing.lastIntegrationAt)
    },
    recommendation: {
      ...resp.recommendation,
      lastIntegrationAt: resp.recommendation.lastIntegrationAt && new Date(resp.recommendation.lastIntegrationAt)
    },
    compliance: {
      ...resp.compliance,
      lastIntegrationAt: resp.compliance.lastIntegrationAt && new Date(resp.compliance.lastIntegrationAt)
    },
    usage: {
      ...resp.usage,
      lastIntegrationAt: resp.usage.lastIntegrationAt && new Date(resp.usage.lastIntegrationAt)
    },
    deletedAt: resp.deletedAt && new Date(resp.deletedAt)
  }
}

export interface AzureIntegrationRequest {
  azureTenantId: string
  name: string
}
