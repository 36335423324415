import React, { useContext, useEffect, useState } from 'react'
import { Button, ButtonType } from './shared/buttons/Button'
import { useCancelToken } from '../api/client'
import { CreateUserRequest, EndUser, getUsersAsSystemAdmin, systemAdminCreateUser } from '../api/system-admin'
import { useErrorHandling } from '../hooks/handleError'
import { UserRole } from '../api/auth'
import { formatDate, testCognitoEmail } from '../utils/formats'
import { UserInfoContext } from '../state/context/UserInfoContext'
import Select, { Props } from 'react-select'
import { CustomInput, CustomLabel } from './shared/filters/FormComponents'
import {
  SelectClearIndicator,
  selectControlStyles,
  SelectDropdownIndicator,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectOptionStyles
} from './shared/filters/ReactSelectStyles'
import { MessageContext, MessageState, MessageType } from '../state/context/MessageContext'
import { Loading } from './shared/Loading'
import { InputType, TextInput } from './shared/filters/TextInput'
import { ScrollContainer } from './shared/containers/ScrollContainer'
import { FilterOption } from './shared/filters/FilterSelect'
import { GrayText, Heading } from './shared/TextComponents'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { CustomIcon, IconType } from './shared/CustomIcon'
import addIcon from '../assets/svg/symbols/plus.svg'
import { ModalContext } from '../state/context/ModalContext'
import { ModalActions } from './shared/modal/Modal'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Layout, SubLayoutContentWrapper } from '../layout/Layout'

export const SystemAdmin = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { authInfo, tenants } = useContext(UserInfoContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { setModal } = useContext(ModalContext)
  const [users, setUsers] = useState<EndUser[]>([])
  const [filteredUsers, setFilteredUsers] = useState<EndUser[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState('')
  const handleError = useErrorHandling()

  useEffect(() => {
    setLoading(true)
    getUsersAsSystemAdmin(createCancelToken().token)
      .then(setUsers)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      setUsers([])
    }
  }, [createCancelToken, handleError])

  useEffect(() => {
    searchText !== ''
      ? setFilteredUsers(
          users.filter(
            user =>
              user.firstName.toLowerCase().includes(searchText) ||
              user.lastName.toLowerCase().includes(searchText) ||
              user.emailAddress.toLowerCase().includes(searchText)
          )
        )
      : setFilteredUsers(users)
  }, [searchText, users])

  const openCreateUserModal = () => {
    const createUser = (user: CreateUserRequest) => {
      const cancelToken = createCancelToken()
      setLoading(true)
      systemAdminCreateUser(cancelToken.token, user)
        .then(() => {
          setMessage({
            type: MessageType.SUCCESS,
            message: t('systemAdmin.userCreateSuccessToast')
          })
          getUsersAsSystemAdmin(createCancelToken().token).then(setUsers).catch(handleError)
          setModal(null)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    setModal({
      header: t('systemAdmin.createUser'),
      body: (
        <CreateUserForm
          tenants={tenants?.map(t => ({ label: t.name, value: t.id })) || []}
          submitAction={createUser}
          loading={loading}
        />
      )
    })
  }

  if (!authInfo.roles.includes(UserRole.SYSTEM_ADMIN) && !authInfo.roles.includes(UserRole.MASTER_ADMIN))
    return <div>Not Authorized</div>

  return (
    <Layout type={'sub'}>
      <SubLayoutContentWrapper className={'lg:max-w-6/12'}>
        <div className={'flex flex-col gap-6 w-full'}>
          <Heading>{t('common.user_other')}</Heading>
          <div className={'flex justify-between gap-6 items-end'}>
            <div>
              <CustomLabel>{t('systemAdmin.usersSearch')}</CustomLabel>
              <TextInput type={InputType.SEARCH} value={searchText} setInputValue={setSearchText} />
            </div>
            <Button
              type={ButtonType.ICON}
              value={<CustomIcon path={addIcon} iconType={IconType.VECTOR} styles={'w-6 h-6 bg-gray-50'} />}
              clickHandler={openCreateUserModal}
            />
          </div>

          <ScrollContainer className={'max-h-[70vh] w-full'}>
            {filteredUsers.map(user => (
              <div key={user.id} className={'flex flex-col w-full my-2 mr-4 p-4 gap-2 bg-gray-500/25'}>
                <CenteredRow>
                  <div>
                    <div>
                      {`${user.firstName} ${user.lastName}`}{' '}
                      <span className={'text-gray-200 text-90'}>{user.id === authInfo.id ? '(you)' : ''}</span>
                    </div>
                    <div className={'text-90 break-all'}>{user.emailAddress}</div>
                  </div>
                  <div>{user.tenantName}</div>
                </CenteredRow>
                <CenteredRow className={'text-90'}>
                  <GrayText>{t('admin.usersList.lastLogin')}</GrayText>
                  <GrayText>{formatDate(user.lastLoginAt, true, true, true)}</GrayText>
                </CenteredRow>
              </div>
            ))}
          </ScrollContainer>
        </div>
      </SubLayoutContentWrapper>
    </Layout>
  )
}

const CenteredRow = styled.div`
  ${tw`flex justify-between gap-12 w-full`}
`

interface CreateUserFormProps {
  tenants: FilterOption[]
  submitAction: (user: CreateUserRequest) => void
  loading: boolean
}

const CreateUserForm = ({ tenants, submitAction, loading }: CreateUserFormProps) => {
  const { t } = useTranslation()

  interface CreateUserForm extends CreateUserRequest {
    tenant: FilterOption
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isSubmitting }
  } = useForm<CreateUserForm>()

  const submit = (user: CreateUserForm) => {
    submitAction({
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      tenantId: user.tenant.value
    })
  }

  return (
    <>
      <form className={'flex flex-col gap-4 w-full p-2 pt-0'}>
        <div>
          <CustomLabel>{t('common.firstName')} *</CustomLabel>
          <CustomInput
            {...register('firstName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
            autoFocus={true}
          />
        </div>
        <div>
          <CustomLabel>{t('common.lastName')} *</CustomLabel>
          <CustomInput
            {...register('lastName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
          />
        </div>
        <div>
          <CustomLabel>{t('common.emailAddress')} *</CustomLabel>
          <CustomInput
            type={'email'}
            {...register('emailAddress', {
              required: true,
              validate: value => testCognitoEmail(value)
            })}
          />
        </div>
        <div>
          <CustomLabel>{t('common.tenant')} *</CustomLabel>
          <Controller
            name={'tenant'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TenantMenu options={tenants} onChange={onChange} value={value} />
            )}
          />
        </div>
      </form>
      <ModalActions>
        {loading ? (
          <Loading height={40} />
        ) : (
          <Button value={'Create'} disabled={loading || !isValid || isSubmitting} clickHandler={handleSubmit(submit)} />
        )}
      </ModalActions>
    </>
  )
}

interface TenantMenuProps extends Props {
  options: FilterOption[]
}

const TenantMenu = (props: TenantMenuProps) => {
  const formatOptionLabel = (data: FilterOption) => {
    if (data === props.value) return <div className={'text-gray-50'}>{data.label}</div>
    return (
      <div className={'flex flex-col items-center'}>
        <div>{data.label}</div>
        <GrayText className={'text-80 break-all'}>{data.value}</GrayText>
      </div>
    )
  }

  return (
    <Select
      {...props}
      unstyled
      placeholder={'Select tenant'}
      formatOptionLabel={option => formatOptionLabel(option as FilterOption)}
      isClearable={true}
      options={props.options}
      onChange={props.onChange}
      classNames={{
        control: () => selectControlStyles,
        menuList: () => selectMenuListStyles,
        option: () => selectOptionStyles,
        placeholder: () => 'text-gray-200'
      }}
      components={{
        ClearIndicator: SelectClearIndicator,
        IndicatorSeparator: SelectIndicatorSeparator,
        DropdownIndicator: SelectDropdownIndicator
      }}
    />
  )
}
