import React, { useEffect, useState } from 'react'

interface CountProps {
  countTo: number
  duration?: number
  id?: string
}

export const CountUp = ({ countTo, duration = 500, id }: CountProps) => {
  const [count, setCount] = useState(0)
  const absCountTo = Math.abs(countTo)
  const minIncrementTime = 10
  const countDuration = absCountTo < 5 ? 200 : duration

  useEffect(() => {
    if (absCountTo > 0) {
      let runner = 0
      const incrementTime = Math.max(Math.ceil(countDuration / absCountTo), minIncrementTime)
      let incrementStep = Math.ceil((absCountTo / countDuration) * incrementTime)

      const timer = setInterval(() => {
        if (runner + incrementStep > absCountTo) {
          incrementStep = Math.max(Math.floor(incrementStep / 2), 1)
        }

        runner += incrementStep
        if (runner >= absCountTo) {
          setCount(absCountTo)
          clearInterval(timer)
        } else {
          setCount(runner)
        }
      }, incrementTime)

      return () => clearInterval(timer)
    } else {
      setCount(0)
    }
  }, [absCountTo, countDuration])

  return <span id={id}>{countTo < 0 ? (count * -1).toLocaleString('fi-FI') : count.toLocaleString('fi-FI')}</span>
}
