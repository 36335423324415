import React, { useEffect, useRef, useState } from 'react'
import { DataContainer } from '../shared/containers/DataContainer'
import { useTranslation } from 'react-i18next'
import {
  ConsumptionData,
  getSustainabilityChartData,
  SustainabilityChartData,
  SustainabilityChartEntry
} from '../../api/sustainability'
import { Loading } from '../shared/Loading'
import { formatMonthShortString, formatNumber, formatToYear, roundNumber } from '../../utils/formats'
import { ToggleGroup } from '../shared/buttons/ToggleGroup'
import { SwitchButton } from '../shared/buttons/SwitchButton'
import { CountUp } from '../shared/CountUp'
import {
  ChartZoomToggles,
  CustomLineChart,
  LineChartContainer,
  LineChartHeader,
  LineChartTooltip
} from '../shared/charts/CustomLineChart'
import { Vendor } from '../../utils/vendors'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { GradientText, GrayText, WhiteText } from '../shared/TextComponents'
import { TimeframeOption } from '../../utils/classes'
import { DomainTuple, Tuple } from 'victory'
import { VendorIndicator } from '../shared/indicators/VendorIndicator'

interface SustainabilityChartProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedRegions: string[]
  selectedTimeframe: TimeframeOption
}

export const SustainabilityChart = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedRegions,
  selectedTimeframe
}: SustainabilityChartProps) => {
  const initialMount = useRef(true)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [chartData, setChartData] = useState<SustainabilityChartData[]>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [displayOption, setDisplayOption] = useState<ConsumptionData>(ConsumptionData.cloudEmissions)
  const [hideComboLine, setHideComboLine] = useState<boolean>(true)
  const minZoomDomain: DomainTuple = [0.5, 13.5]
  const [maxZoomDomain, setMaxZoomDomain] = useState<Tuple<number>>([11.5, 24.5])
  const [zoomDomain, setZoomDomain] = useState<Tuple<number>>(maxZoomDomain)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (initialMount.current) {
      setLoading(true)
    }
    getSustainabilityChartData(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedRegions,
      cancelToken.token
    )
      .then(resp => {
        if (initialMount.current) {
          initialMount.current = false
          const historyMonths = resp.filter(e => e.vendor === Vendor.ALL)[0].chartEntries.length
          setZoomDomain([historyMonths - 12.5, historyMonths + 0.5])
          setMaxZoomDomain([historyMonths - 12.5, historyMonths + 0.5])
        }
        setChartData(resp)
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      initialMount.current = true
      setLoading(false)
    }
  }, [
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedRegions,
    handleError,
    createCancelToken
  ])

  const displayOptions = [
    {
      clickHandler: () => setDisplayOption(ConsumptionData.kwhSpend),
      checked: displayOption === ConsumptionData.kwhSpend,
      value: t('common.units.kWh')
    },
    {
      clickHandler: () => setDisplayOption(ConsumptionData.cloudEmissions),
      checked: displayOption === ConsumptionData.cloudEmissions,
      value: t('common.units.kgCO2')
    }
  ]

  const showCloudEmissions = displayOption === ConsumptionData.cloudEmissions
  const yAxisData = (datum: any, displayData: ConsumptionData) => {
    return displayData === ConsumptionData.cloudEmissions ? datum.cloudEmissions : datum.kwhSpend
  }
  const chartEntries = chartData.flatMap(vendorEntry => vendorEntry.chartEntries)
  const maxAmount = Math.max(
    ...chartEntries.map(data => (showCloudEmissions ? data.cloudEmissions : data.kwhSpend) ?? 0)
  )

  const currentYear = new Date().getFullYear()

  const vendorTotals: { vendor: Vendor; lastYear: number; currentYear: number }[] = selectedVendors.map(v => ({
    vendor: v,
    lastYear:
      chartData
        .find(entry => entry.vendor === v)
        ?.chartEntries.filter(entry => new Date(entry.date).getFullYear() < currentYear)
        .reduce(
          (acc, curr) => (showCloudEmissions ? acc + (curr.cloudEmissions ?? 0) : acc + (curr.kwhSpend ?? 0)),
          0
        ) ?? 0,
    currentYear:
      chartData
        .find(entry => entry.vendor === v)
        ?.chartEntries.filter(entry => new Date(entry.date).getFullYear() === currentYear)
        .reduce(
          (acc, curr) => (showCloudEmissions ? acc + (curr.cloudEmissions ?? 0) : acc + (curr.kwhSpend ?? 0)),
          0
        ) ?? 0
  }))

  const lastYearTotal = vendorTotals.reduce((acc, curr) => acc + curr.lastYear, 0)
  const currentYearTotal = vendorTotals.reduce((acc, curr) => acc + curr.currentYear, 0)

  if (loading)
    return (
      <DataContainer>
        <Loading paddingY={'14rem'} />
      </DataContainer>
    )

  return (
    <DataContainer looseSpacing={true}>
      <LineChartContainer>
        <LineChartHeader
          heading={
            displayOption === ConsumptionData.cloudEmissions
              ? t('sustainability.chart.title.emissions', {
                  startYear: currentYear - 1,
                  endYear: currentYear
                })
              : t('sustainability.chart.title.electricity', {
                  startYear: currentYear - 1,
                  endYear: currentYear
                })
          }
          subheading={
            <div>
              <ChartTextRow
                selectedTimeframe={selectedTimeframe}
                label={t('sustainability.chart.total')}
                lastYear={lastYearTotal}
                currentYear={currentYearTotal}
                unit={showCloudEmissions ? t('common.units.kgCO2') : t('common.units.kWh')}
                emissions={showCloudEmissions}
                total={true}
              />
              {vendorTotals.map(v => (
                <ChartTextRow
                  key={v.vendor}
                  selectedTimeframe={selectedTimeframe}
                  label={t(`vendors.${v.vendor}.short`)}
                  lastYear={v.lastYear}
                  currentYear={v.currentYear}
                  unit={showCloudEmissions ? t('common.units.kgCO2') : t('common.units.kWh')}
                  emissions={showCloudEmissions}
                  vendor={v.vendor}
                />
              ))}
            </div>
          }
          actions={
            <>
              <ToggleGroup minWidth={'60px'} maxWidth={'12rem'} buttons={displayOptions} lowercase={true} />
              <SwitchButton
                clickHandler={() => setHideComboLine(!hideComboLine)}
                checked={selectedVendors.length > 1 && !hideComboLine}
                label={t('sustainability.chart.showTotal')}
                disabled={selectedVendors.length <= 1}
              />
              <ChartZoomToggles
                domain={zoomDomain}
                setDomain={setZoomDomain}
                minDomain={minZoomDomain}
                maxDomain={maxZoomDomain}
              />
            </>
          }
        />
        <CustomLineChart
          chartData={chartData}
          domain={[0, maxAmount > 1 ? maxAmount : 1]}
          tooltipLabel={({ datum }) => datum.cloudEmissions}
          tooltip={<ComparisonTooltip showCloudEmissions={showCloudEmissions} />}
          selectedVendors={selectedVendors}
          showVendorLegend={true}
          showLeftAxis={true}
          leftYAxisDataKey={datum => yAxisData(datum, displayOption)}
          hideComboLine={hideComboLine}
          zoomProps={{
            minDomain: minZoomDomain,
            maxDomain: maxZoomDomain,
            domain: zoomDomain,
            setDomain: setZoomDomain
          }}
        />
      </LineChartContainer>
    </DataContainer>
  )
}

interface ChartTextRowProps {
  selectedTimeframe: TimeframeOption
  label: string
  lastYear: number
  currentYear: number
  unit: string
  emissions: boolean
  total?: boolean
  vendor?: Vendor
}

const ChartTextRow = ({
  selectedTimeframe,
  label,
  lastYear,
  currentYear,
  unit,
  emissions,
  total,
  vendor
}: ChartTextRowProps) => {
  const activeColor = total
    ? emissions
      ? 'from-tertiary-100 to-tertiary-200'
      : 'from-tertiary-400 to-tertiary-500'
    : 'from-gray-50 to-gray-100'

  const amountStyle = (amountsTimeframe: TimeframeOption) =>
    `transition-all ease-in-out duration-300 ${selectedTimeframe === amountsTimeframe ? `${total ? 'text-125 leading-tight' : 'text-100'} ${activeColor} font-black` : 'text-80 from-gray-200 to-gray-200'}`

  return (
    <div className={'flex justify-between gap-4 items-center pb-0.5 first:py-2 last:pb-0'}>
      <div className={'flex gap-1.5 items-center'}>
        {vendor && <VendorIndicator vendor={vendor} type={'plain'} size={'xs'} />}
        {total ? (
          <WhiteText className={'font-bold first-letter:capitalize'}>{label}</WhiteText>
        ) : (
          <GrayText className={'text-90'}>{label}</GrayText>
        )}
      </div>
      <div>
        <div className={'flex gap-1.5 items-center'}>
          <GradientText className={amountStyle(TimeframeOption.LAST_YEAR)}>
            <CountUp
              id={total ? 'qa-consumption-chart-last-year-total' : `qa-consumption-chart-${vendor}-last-year-total`}
              countTo={roundNumber(lastYear, 0)}
            />
          </GradientText>
          <GrayText normalCase={true} className={'text-90'}>
            /
          </GrayText>
          <GradientText className={amountStyle(TimeframeOption.CURRENT_YEAR)}>
            <CountUp
              id={
                total ? 'qa-consumption-chart-current-year-total' : `qa-consumption-chart-${vendor}-current-year-total`
              }
              countTo={roundNumber(currentYear, 0)}
            />
          </GradientText>
          {total ? (
            <WhiteText className={'font-semibold text-90'}>{unit}</WhiteText>
          ) : (
            <GrayText normalCase={true} className={'text-90'}>
              {unit}
            </GrayText>
          )}
        </div>
      </div>
    </div>
  )
}

interface ComparisonTooltipProps {
  active?: boolean
  datum?: SustainabilityChartEntry
  y?: number
  x?: number
  showCloudEmissions: boolean
}

const ComparisonTooltip = ({ active, datum, y, x, showCloudEmissions }: ComparisonTooltipProps) => {
  const { t } = useTranslation()

  const tooltipText =
    showCloudEmissions && datum?.cloudEmissions
      ? `${formatNumber(
          roundNumber(datum.cloudEmissions, datum.cloudEmissions < 1 ? 1 : 0)
        )} ${t('common.units.kgCO2')}`
      : datum?.kwhSpend
        ? `${formatNumber(roundNumber(datum.kwhSpend, datum.kwhSpend < 1 ? 1 : 0))} ${t('common.units.kWh')}`
        : ''

  return (
    <LineChartTooltip
      active={active && !!datum}
      x={x}
      y={y}
      width={140}
      height={68}
      label={datum ? `${formatMonthShortString(datum.date).toLowerCase()} ${formatToYear(datum.date)}` : ''}
      value={tooltipText}
    />
  )
}
