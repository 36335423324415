import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'
import { CapitalizedText, GrayText } from '../TextComponents'

export enum BarChartTheme {
  score = 'score',
  progress = 'progress',
  pink = 'pink'
}

type BarChartProps =
  | {
      direction?: 'vertical'
      theme: BarChartTheme
      colorIndex?: number
      percent: number
      showPercent?: boolean
      height?: number
      width?: number
      chartLabel?: never
      chartLegend?: never
      legendAlign?: never
      labelId?: never
      legendId?: never
    }
  | {
      direction?: 'horizontal'
      theme: BarChartTheme
      colorIndex?: number
      percent: number
      showPercent?: boolean
      chartLabel?: string
      chartLegend?: string
      legendAlign?: 'left' | 'right'
      height?: number
      width?: number
      labelId?: string
      legendId?: string
    }

export const BarChart = ({
  theme,
  colorIndex,
  percent,
  showPercent,
  chartLabel,
  chartLegend,
  legendAlign,
  height,
  width,
  labelId,
  legendId,
  direction = 'horizontal'
}: BarChartProps) => {
  const indexedPercent = percent < 50 ? 0 : percent >= 50 && percent < 75 ? 1 : 2
  const progressColorStyles = [
    'from-tertiary-600 to-error-100',
    'from-tertiary-500 via-tertiary-500 to-tertiary-400',
    'from-success-500 via-success-100 to-success-100'
  ]

  const pinkColorStyles = [
    'from-primary-500 to-primary-400',
    'from-primary-400 to-primary-300',
    'from-primary-300 to-primary-200',
    'from-primary-200 to-primary-100'
  ]

  const colorStyles = [
    {
      name: 'score',
      color: 'from-success-500 via-success-100 to-success-100'
    },
    { name: 'progress', color: progressColorStyles[indexedPercent] },
    { name: 'pink', color: pinkColorStyles[colorIndex ?? 0] }
  ]

  const color = colorStyles.find(item => item.name === theme)?.color

  if (direction === 'vertical')
    return (
      <BaseBar
        className={'border border-gray-400/50'}
        style={{
          height: height ? `${height * 4}px` : '4rem',
          width: width ? `${width * 4}px` : '2.25rem'
        }}
      >
        {percent > 0 && (
          <ScoreBar
            className={`${color} bg-gradient-to-t bottom-0 border-2 border-gray-700`}
            style={{
              width: '100%',
              height: `${percent}%`,
              minHeight: '1rem'
            }}
          />
        )}
        {showPercent &&
          (percent < 85 ? (
            <PercentSpan
              style={{
                bottom: `${percent - (percent < 4 ? 0 : 4)}%`,
                left: width ? width / 2 : 5
              }}
            >
              {percent} %
            </PercentSpan>
          ) : (
            <PercentSpan
              style={{
                bottom: 2,
                left: width ? width / 2 : 5
              }}
            >
              {percent} %
            </PercentSpan>
          ))}
      </BaseBar>
    )

  return (
    <div className={'flex flex-col gap-1'}>
      {chartLabel && (
        <CapitalizedText className={'text-gray-50 text-90 font-semibold'} id={labelId}>
          {chartLabel}
        </CapitalizedText>
      )}
      <BaseBar
        style={{
          height: height ? `${height * 4}px` : '1rem',
          width: width ? `${width * 4}px` : '100%'
        }}
      >
        {percent > 0 && (
          <ScoreBar
            className={`${color} bg-gradient-to-r`}
            style={{
              width: `${percent}%`,
              minWidth: '1rem'
            }}
          />
        )}
        {showPercent &&
          (percent < 90 ? (
            <PercentSpan
              style={{
                left: percent < 4 ? '1.5rem' : `${percent + 1}%`,
                top: -3
              }}
            >
              {percent} %
            </PercentSpan>
          ) : (
            <PercentSpan
              style={{
                right: `${102 - percent}%`,
                top: -3,
                filter: 'invert(0.9)'
              }}
            >
              {percent} %
            </PercentSpan>
          ))}
      </BaseBar>
      {chartLegend && (
        <GrayText id={legendId} className={legendAlign && `text-${legendAlign}`}>
          {chartLegend}
        </GrayText>
      )}
    </div>
  )
}

const BaseBar = styled.div`
  ${tw`rounded-lg relative bg-gray-700 w-full`}
`

const ScoreBar = styled.div`
  ${tw`rounded-lg absolute h-full`}
`

const PercentSpan = styled.span`
  ${tw`absolute w-max text-90 font-semibold`}
`
