import React, { useContext, useEffect, useState } from 'react'
import { FilterOption, GroupedOptions } from '../shared/filters/FilterSelect'
import {
  deleteSustainabilityFilterPreset,
  getSavedSustainabilityFilterPresets,
  getSustainabilityFilterData,
  saveSustainabilityFilterPreset,
  SustainabilityFilterData,
  SustainabilityFilterPreset
} from '../../api/sustainability'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { ActionsFilterType, BasicFilterType, FilterMenu, Filters } from '../shared/filters/Filters'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { TimeframeOption } from '../../utils/classes'

interface FilterProps {
  selectedTimeframe: TimeframeOption
  setSelectedTimeframe: (timeframe: TimeframeOption) => void
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedProjects: FilterOption[]) => void
  selectedServices: FilterOption[]
  setSelectedServices: (selectedProjects: FilterOption[]) => void
  selectedRegions: FilterOption[]
  setSelectedRegions: (selectedProjects: FilterOption[]) => void
}

export const SustainabilityFilters = ({
  selectedTimeframe,
  setSelectedTimeframe,
  selectedVendors,
  setSelectedVendors,
  selectedProjects,
  setSelectedProjects,
  selectedResourceGroups,
  setSelectedResourceGroups,
  selectedServices,
  setSelectedServices,
  selectedRegions,
  setSelectedRegions
}: FilterProps) => {
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [filterOptions, setFilterOptions] = useState<SustainabilityFilterData | null>(null)
  const [initFilterOptions, setInitFilterOptions] = useState<SustainabilityFilterData | null>(null)
  const [filterPresets, setFilterPresets] = useState<SustainabilityFilterPreset[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    Promise.all([
      getSustainabilityFilterData(
        selectedProjects.map(p => p.value),
        cancelToken.token
      ).then(data => {
        setFilterOptions(data)
        setInitFilterOptions(data)
      }),
      getSavedSustainabilityFilterPresets(cancelToken.token).then(setFilterPresets)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setFilterPresets([])
    }
  }, [selectedProjects, createCancelToken, handleError, authInfo])

  useEffect(() => {
    if (initFilterOptions) {
      const vendorOptions = selectedVendors.map(vendor => vendor.value)
      const projectOptions = initFilterOptions.projects.filter(project => vendorOptions.includes(project.label))
      const resourceGroupOptions = selectedVendors.map(vendor => vendor.value).includes(Vendor.AZURE)
        ? initFilterOptions.resourceGroups
        : []
      const serviceOptions = initFilterOptions.services.filter(service => vendorOptions.includes(service.label))
      const excludedServiceOptions = initFilterOptions.excludedServices.filter(service =>
        vendorOptions.includes(service.label)
      )
      const regionOptions = initFilterOptions.regions.filter(region => vendorOptions.includes(region.label))
      setFilterOptions({
        projects: projectOptions,
        resourceGroups: resourceGroupOptions,
        services: serviceOptions,
        excludedServices: excludedServiceOptions,
        regions: regionOptions
      })
    } else {
      setFilterOptions(initFilterOptions)
    }
  }, [initFilterOptions, selectedVendors])

  useEffect(() => {
    if (selectedPreset) {
      const chosenPreset = filterPresets.find(preset => preset.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? [])
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedServices(chosenPreset?.services ?? [])
      setSelectedRegions(chosenPreset?.regions ?? [])
    }
  }, [selectedPreset])

  const savePreset = (name: string) => {
    const filter: SustainabilityFilterPreset = {
      name: name,
      vendors: selectedVendors,
      projects: selectedProjects.map(project => ({
        value: project.value,
        label: project.label
      })),
      resourceGroups: selectedResourceGroups.map(resourceGroup => ({
        value: resourceGroup.value,
        label: resourceGroup.label
      })),
      services: selectedServices.map(service => ({
        value: service.value,
        label: service.label
      })),
      regions: selectedRegions.map(region => ({
        value: region.value,
        label: region.label
      }))
    }
    saveSustainabilityFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setFilterPresets([...filterPresets].filter(preset => savedPreset.name !== preset.name).concat(savedPreset))
        setSelectedPreset({
          value: savedPreset.name,
          label: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deletePreset = (name: string) => {
    deleteSustainabilityFilterPreset(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setFilterPresets([...filterPresets].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const excludedServices: GroupedOptions[] =
    filterOptions?.excludedServices
      .filter(group => group.options.length > 0)
      .map(group => ({
        label: `${group.label} ${t('filters.external')}`,
        options: group.options
      })) ?? []

  const options: FilterMenu[] = [
    {
      type: BasicFilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projects ?? [],
      onChange: setSelectedProjects
    }
  ]

  if (integrationStates.azure)
    options.push({
      type: BasicFilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroups ?? [],
      onChange: setSelectedResourceGroups
    })

  options.push(
    {
      type: BasicFilterType.REGIONS,
      value: selectedRegions,
      options: filterOptions?.regions ?? [],
      onChange: setSelectedRegions
    },
    {
      type: BasicFilterType.SERVICES,
      value: selectedServices,
      options: filterOptions?.services.concat(excludedServices) ?? [],
      onChange: setSelectedServices
    },
    {
      type: ActionsFilterType.PRESETS,
      value: selectedPreset ? [selectedPreset] : [],
      options: filterPresets.map(preset => ({
        label: preset.name,
        value: preset.name
      })),
      onChange: (preset: FilterOption) => setSelectedPreset(preset),
      saveAction: savePreset,
      deleteAction: deletePreset
    }
  )

  const timeframeOptions = [
    {
      clickHandler: () => setSelectedTimeframe(TimeframeOption.LAST_YEAR),
      checked: selectedTimeframe === TimeframeOption.LAST_YEAR,
      value: t(`common.timeframeOptions.${TimeframeOption.LAST_YEAR}`)
    },
    {
      clickHandler: () => setSelectedTimeframe(TimeframeOption.CURRENT_YEAR),
      checked: selectedTimeframe === TimeframeOption.CURRENT_YEAR,
      value: t(`common.timeframeOptions.${TimeframeOption.CURRENT_YEAR}`)
    }
  ]

  return (
    <Filters
      selectedVendors={selectedVendors}
      setSelectedVendors={setSelectedVendors}
      filterMenuOptions={options}
      loading={loading}
      toggleFilter={{
        type: BasicFilterType.TIMEFRAME,
        options: timeframeOptions
      }}
    />
  )
}
