import { client } from './client'
import { CancelToken } from 'axios'

export const getTicketsByStatus = async (cancelToken: CancelToken): Promise<TicketsByStatus[]> => {
  const resp = await client.get<TicketsByStatus[]>(`/tickets`, {
    cancelToken: cancelToken
  })
  return resp.data.map(byStatus => ({
    ...byStatus,
    tickets: byStatus.tickets.map(ticket => ({
      ...ticket,
      dueBy: new Date(ticket.dueBy),
      ticketCreatedAt: new Date(ticket.ticketCreatedAt),
      ticketUpdatedAt: ticket.ticketUpdatedAt && new Date(ticket.ticketUpdatedAt)
    }))
  }))
}

export interface TicketsByStatus {
  status: TicketStatus
  tickets: FreshTicket[]
}

export interface FreshTicket {
  domain: string | null
  ticketId: number
  category: string | null
  status: TicketStatus
  type: TicketType
  incidentSubcategory: string | null
  monitorResource: string | null
  priority: TicketPriority
  escalated: boolean
  subject: string
  description: string
  dueBy: Date
  ticketCreatedAt: Date
  ticketUpdatedAt: Date | null
}

export enum TicketStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED'
}

export const TicketStatuses = [TicketStatus.OPEN, TicketStatus.PENDING, TicketStatus.RESOLVED, TicketStatus.CLOSED]

export enum TicketPriority {
  URGENT = 'URGENT',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export enum TicketType {
  INCIDENT = 'Incident',
  MAJOR_INCIDENT = 'Major Incident',
  SERVICE_REQUEST = 'Service Request'
}
