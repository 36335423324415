import React, { useContext, useEffect, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { ActionsFilterType, BasicFilterType, FilterMenu, Filters } from '../shared/filters/Filters'
import {
  ComplianceFilterData,
  ComplianceFilterPreset,
  deleteComplianceFilterPreset,
  getComplianceFilterData,
  getComplianceFilterPresets,
  saveComplianceFilterPreset
} from '../../api/compliance/filters'

interface Props {
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedResourceGroups: FilterOption[]) => void
  selectedStandards: FilterOption[]
  setSelectedStandards: (selectedStandards: FilterOption[]) => void
  selectedRegions: FilterOption[]
  setSelectedRegions: (selectedRegions: FilterOption[]) => void
  includeSuppressed: boolean
  setIncludeSuppressed: (includeSuppressed: boolean) => void
}

export const ComplianceFilters = ({
  selectedVendors,
  setSelectedVendors,
  selectedProjects,
  setSelectedProjects,
  selectedResourceGroups,
  setSelectedResourceGroups,
  selectedStandards,
  setSelectedStandards,
  selectedRegions,
  setSelectedRegions,
  includeSuppressed,
  setIncludeSuppressed
}: Props) => {
  const { authInfo, integrationStates, userSettings } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)

  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(true)
  const [initFilterData, setInitFilterData] = useState<ComplianceFilterData | null>(null)
  const [filterOptions, setFilterOptions] = useState<ComplianceFilterData | null>(null)
  const [savedPresets, setSavedPresets] = useState<ComplianceFilterPreset[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    Promise.all([
      getComplianceFilterData(
        selectedProjects.map(project => project.value),
        cancelToken.token
      ).then(filterData => {
        setFilterOptions(filterData)
        setInitFilterData(filterData)
      }),
      getComplianceFilterPresets(cancelToken.token).then(setSavedPresets)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [selectedProjects, createCancelToken, handleError, authInfo])

  useEffect(() => {
    if (selectedVendors.length > 0 && initFilterData) {
      const projectOptions = initFilterData.projectOptions.filter(project =>
        selectedVendors.map(vendor => vendor.value).includes(project.label)
      )
      const resourceGroupOptions = selectedVendors.map(vendor => vendor.value).includes(Vendor.AZURE)
        ? initFilterData.resourceGroupOptions
        : []
      const standardOptions = initFilterData.standardOptions.filter(standard =>
        selectedVendors.map(vendor => vendor.value).includes(standard.label)
      )
      const regionOptions = initFilterData.regionOptions.filter(region =>
        selectedVendors.map(vendor => vendor.value).includes(region.label)
      )
      setFilterOptions({
        projectOptions,
        resourceGroupOptions,
        standardOptions,
        regionOptions
      })
    } else {
      setFilterOptions(initFilterData)
    }
  }, [initFilterData, selectedVendors, setSelectedVendors])

  useEffect(() => {
    if (selectedPreset) {
      const chosenPreset = savedPresets.find(filter => filter.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? [])
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedStandards(chosenPreset?.standards ?? [])
      setSelectedRegions(chosenPreset?.regions ?? [])
    }
  }, [selectedPreset])

  const saveFilter = (name: string) => {
    const filter: ComplianceFilterPreset = {
      name: name,
      vendors: selectedVendors,
      projects: selectedProjects.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      })),
      resourceGroups: selectedResourceGroups.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      })),
      standards: selectedStandards.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      })),
      regions: selectedRegions.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      }))
    }
    saveComplianceFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setSavedPresets([...savedPresets].filter(preset => preset.name !== savedPreset.name).concat(savedPreset))
        setSelectedPreset({
          label: savedPreset.name,
          value: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deleteFilter = (name: string) => {
    deleteComplianceFilterPreset(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setSavedPresets([...savedPresets].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const selects: FilterMenu[] = [
    {
      type: BasicFilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projectOptions ?? [],
      onChange: setSelectedProjects
    }
  ]

  if (integrationStates.azure)
    selects.push({
      type: BasicFilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroupOptions ?? [],
      onChange: setSelectedResourceGroups
    })

  selects.push(
    {
      type: BasicFilterType.STANDARDS,
      value: selectedStandards,
      options: filterOptions?.standardOptions ?? [],
      onChange: setSelectedStandards
    },
    {
      type: BasicFilterType.REGIONS,
      value: selectedRegions,
      options: filterOptions?.regionOptions ?? [],
      onChange: setSelectedRegions
    },
    {
      type: ActionsFilterType.PRESETS,
      value: selectedPreset ? [selectedPreset] : [],
      options: savedPresets.map(preset => ({
        label: preset.name,
        value: preset.name
      })),
      onChange: (preset: FilterOption) => setSelectedPreset(preset),
      saveAction: saveFilter,
      deleteAction: deleteFilter
    }
  )

  return (
    <Filters
      selectedVendors={selectedVendors}
      setSelectedVendors={setSelectedVendors}
      filterMenuOptions={selects}
      loading={loading}
      toggleFilter={
        userSettings.visibleVendors.includes(Vendor.AWS)
          ? {
              type: BasicFilterType.CUSTOM_TOGGLE,
              header: t('compliance.includeSuppressed'),
              options: [
                {
                  clickHandler: () => setIncludeSuppressed(true),
                  checked: includeSuppressed,
                  value: t('common.yes')
                },
                {
                  clickHandler: () => setIncludeSuppressed(false),
                  checked: !includeSuppressed,
                  value: t('common.no')
                }
              ]
            }
          : undefined
      }
    />
  )
}
