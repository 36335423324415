import React, { useEffect, useState } from 'react'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { CountUp } from '../shared/CountUp'
import { roundNumber } from '../../utils/formats'
import { Trans, useTranslation } from 'react-i18next'
import { HomeNoDataSummaryContainer, HomeSummaryContainer, SummaryAmount, SummaryText } from './HomeSharedComponents'
import { getHomeCurrentMonthEmissions } from '../../api/home'

export const SustainabilitySummary = () => {
  const [totalEmissions, setTotalEmissions] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getHomeCurrentMonthEmissions(cancelToken.token)
      .then(setTotalEmissions)
      .catch(handleError)
      .finally(() => setLoading(false))
    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTotalEmissions(null)
    }
  }, [createCancelToken, handleError])

  const dateNow = new Date().getDate()

  if (loading || (totalEmissions === null && dateNow !== 1)) {
    return <HomeNoDataSummaryContainer title={t('home.sustainabilitySummary.title')} loading={loading} />
  }

  return (
    <HomeSummaryContainer title={t('home.sustainabilitySummary.title')} isClickable={true}>
      <SummaryAmount textSize={'larger'} fontWeight={'bold'}>
        <CountUp id={'qa-home-current-emissions'} countTo={roundNumber(totalEmissions ?? 0, 0)} />
      </SummaryAmount>
      <SummaryText largerText={true} lowercase={true}>
        <Trans>{t('home.sustainabilitySummary.description')}</Trans>
      </SummaryText>
    </HomeSummaryContainer>
  )
}
